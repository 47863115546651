import { PERIOD_SHIFT_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { UnitTypeTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common';
import * as yup from 'yup';

export const UNIT_TYPE_TABLE_WIDGET_CONFIG_SCHEMA = yup.object().shape({
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
  hide_total_row: yup.boolean().optional().nullable(),
  hide_floorplan_rows: yup.boolean().optional().nullable(),
});

export type UnitTypeTableWidgetConfigForm = yup.InferType<
  typeof UNIT_TYPE_TABLE_WIDGET_CONFIG_SCHEMA
>;

export const transformUnitTypeTableWidgetConfigToForm = (
  config: UnitTypeTableWidgetConfigDto,
): UnitTypeTableWidgetConfigForm => {
  return {
    period_shift: {
      key: 'weeks',
      value: 0,
      ...transformPeriodShiftDtoToForm(
        config.default_options?.date?.period_shift,
      ),
    },
    hide_total_row: config.hide_total_row ?? false,
    hide_floorplan_rows: config.hide_floorplan_rows ?? false,
  };
};
