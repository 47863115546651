import { useLocalStorageValue } from '@/shared/lib/hooks/useLocalStorageValue';
import {
  LocalStorageSchema,
  typedLocalStorage,
} from '@/shared/lib/localStorage';
import { useCallback } from 'react';

// All type casting here should be removed when we migrate to newer versions of TS (5.2) https://devblogs.microsoft.com/typescript/announcing-typescript-5-2-beta/#easier-method-usage-for-unions-of-arrays

type FavoritesLocalStorageKey = keyof Pick<
  LocalStorageSchema,
  | 'operationalScoreboardFavoriteAssets'
  | 'reportOperationalFavoriteAssets'
  | 'reportFinancialsObjectIdAndType'
  | 'reconcileDevelopmentProjectFavoriteIds'
  | 'coreAssetsFavoriteIds'
  | 'coreFundsFavoriteIds'
  | 'reportBuilderTemplateIds'
>;

export const favoriteIncludesItemId = <Key extends FavoritesLocalStorageKey>(
  itemId: string | number,
  localStorageKey: Key,
): boolean => {
  const parsed =
    typedLocalStorage.getItem(localStorageKey) ??
    ([] as Values<LocalStorageSchema>);

  if (Array.isArray(parsed)) {
    return (parsed as (string | number)[]).includes(itemId);
  }

  console.error('item from local storage is not an array');
  return false;
};

type SetFavoriteItems = (val: (string | number)[]) => void;

export function useFavoriteItemIds<Key extends FavoritesLocalStorageKey>(
  localStorageKey: Key,
) {
  const [favouriteItems, setFavouriteItems] = useLocalStorageValue<Key>(
    localStorageKey,
    [],
  );

  const favouriteItemsWithCorrectType = favouriteItems as (string | number)[];

  const isItemFavorite = useCallback(
    (itemId: string | number) => favouriteItemsWithCorrectType.includes(itemId),
    [favouriteItems],
  );

  const isItemListFavorite = useCallback(
    (itemListIds: (string | number)[]) =>
      itemListIds.every((itemId) =>
        favouriteItemsWithCorrectType.includes(itemId),
      ),
    [favouriteItems],
  );

  const toggleItemFavorite = useCallback(
    (itemId: string | number) => {
      (setFavouriteItems as SetFavoriteItems)(
        favouriteItemsWithCorrectType.includes(itemId)
          ? favouriteItemsWithCorrectType.filter((itemX) => itemX !== itemId)
          : [...favouriteItems, itemId],
      );
    },
    [favouriteItems],
  );

  const toggleBulkItemsFavorite = useCallback(
    (itemListIds: (string | number)[]) => {
      (setFavouriteItems as SetFavoriteItems)(
        isItemListFavorite(itemListIds)
          ? favouriteItemsWithCorrectType.filter(
              (itemX) => !itemListIds.includes(itemX),
            )
          : [...favouriteItems, ...itemListIds],
      );
    },
    [favouriteItems],
  );

  return {
    toggleItemFavorite,
    isItemFavorite,
    favouriteItems,
    toggleBulkItemsFavorite,
  };
}
