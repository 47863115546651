import { currentUserIsSreAdmin } from '@/lib/permissions';
import { SpecifiedThreshold, Threshold } from './model';

export const isThresholdSpecified = (
  threshold: Threshold,
): threshold is SpecifiedThreshold => threshold.globalValue != null;

export const canUserConfigureThresholds = () => {
  return currentUserIsSreAdmin();
};
