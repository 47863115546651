import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IconButton, Modal } from '@/stories';
import { ResizeHandle } from '@/stories/Modals/Modal/ResizableSidePanel';
import { RouteComponentProps } from '@reach/router';
import {
  Formula,
  closeAllFormulas,
  closeFormula,
  resetFormulasState,
  selectCurrentFormulaIndex,
  selectFormulasSettingsIsOpened,
  selectOpenedFormulas,
  selectOpenedFormulasCount,
  updateCurrentFormulaIndex,
  updateFormulaByIndex,
  useReportFormulasQueryById,
} from 'bundles/Shared/entities/formula';
import {
  EditFormulaForm,
  isFormulaFormEquals,
} from 'bundles/Shared/features/formula/editFormula';
import { useResetFormulasSettingsOnUnmount } from 'bundles/Shared/widgets/formula/panel';
import { EditFormula } from 'bundles/Shared/widgets/formula/panel/ui/EditFormula';
import { FormulaAndVariablesPanel } from 'bundles/Shared/widgets/formula/panel/ui/FormulaAndVariablesPanel';
import { FormulaTabs } from 'bundles/Shared/widgets/formula/panel/ui/FormulaTabs';
import { debounce } from 'lodash-es';
import React, { ReactNode, useCallback, useState } from 'react';
import { ResizableBox, ResizableProps } from 'react-resizable';
import { ClassNameProps } from 'types/Props';

type FormulaFormInTab = EditFormulaForm &
  Pick<Formula, 'referencedInEntities' | 'invalidReferences'>;

type Props = Partial<DialogProps> & {
  initialOpen?: boolean;
  editMode?: boolean;
  initialFormula?: Formula;
  open?: boolean;
};

const FormulaTab = ({
  formula,
  index,
}: {
  formula: EditFormulaForm;
  index: number;
}) => {
  const dispatch = useAppDispatch();
  const { formula: originalFormula } = useReportFormulasQueryById(
    formula.id ?? null,
  );
  const isDirty =
    originalFormula != null && isFormulaFormEquals(originalFormula, formula);
  const currentFormulaIndex = useAppSelector(selectCurrentFormulaIndex);

  return (
    <FormulaTabs.Tab
      onClick={() => dispatch(updateCurrentFormulaIndex(index))}
      onClose={() => dispatch(closeFormula(index))}
      isActive={index === currentFormulaIndex}
      key={formula.reference}
    >
      <FormulaTabs.Tab.Label className="flex items-center gap-tw-2">
        {formula.label}
        {(!isDirty || formula.id == null) && (
          <span className="h-[8px] w-[8px] rounded-full bg-attention-055" />
        )}
      </FormulaTabs.Tab.Label>
    </FormulaTabs.Tab>
  );
};

const FormulasTabs = ({ className }: ClassNameProps) => {
  const dispatch = useAppDispatch();
  const openedFormulas = useAppSelector(selectOpenedFormulas);
  const currentFormulaIndex = useAppSelector(selectCurrentFormulaIndex);
  const update = useCallback(
    debounce((index: number, updated: FormulaFormInTab) => {
      dispatch(
        updateFormulaByIndex({
          formula: updated,
          index,
        }),
      );
    }, 300),
    [],
  );

  return (
    <div className={cn('flex grow flex-col p-tw-6', className)}>
      <FormulaTabs>
        {openedFormulas.map((formula, index) => (
          <FormulaTab key={formula.id} formula={formula} index={index} />
        ))}
      </FormulaTabs>
      {openedFormulas.map((formula, index) => (
        <EditFormula
          index={index}
          update={update}
          key={formula.id}
          formula={formula}
          visible={index === currentFormulaIndex}
        />
      ))}
    </div>
  );
};

const BlackIconButton = ({
  ...props
}: React.ComponentProps<typeof IconButton>) => (
  <IconButton
    variant="secondary"
    offHoverStyles
    style={{
      transform: 'translateY(-50%)',
    }}
    className="border-0 bg-neutral-800  text-neutral-000"
    {...props}
  />
);
const TabsWrapper = ({
  children,
  resizable,
  onFullScreenToggle,
}: React.PropsWithChildren<{
  resizable?: boolean;
  onFullScreenToggle?: () => void;
}>) => {
  const dispatch = useAppDispatch();
  const resizeHandle = useCallback<
    Exclude<NonNullable<ResizableProps['handle']>, ReactNode>
  >((_, ref) => {
    return (
      <ResizeHandle
        className="opacity-0 transition-opacity group-hover:opacity-100"
        position="top"
        ref={ref}
      />
    );
  }, []);
  if (!resizable) {
    return (
      <div className="flex flex-col">
        <Modal.DefaultHeader className="flex h-[65px] items-center border-b border-solid border-neutral-200 bg-neutral-100 px-tw-6 py-tw-4">
          Editor
          <div className="grow" />
          {onFullScreenToggle && (
            <IconButton onClick={onFullScreenToggle} iconName="collapse" />
          )}
        </Modal.DefaultHeader>
        {children}
      </div>
    );
  }
  return (
    <ResizableBox
      resizeHandles={['n']}
      height={600}
      axis="y"
      handle={resizeHandle}
      className={cn(
        'group col-start-1 row-start-2  mt-0 flex p-tw-2',
        'rounded-2xl border-4 border-solid border-neutral-800',
      )}
    >
      <div className="absolute right-tw-8 top-0 z-10 flex gap-tw-2 opacity-0 transition-opacity group-hover:opacity-100">
        <BlackIconButton iconName="expand" onClick={onFullScreenToggle} />
        <BlackIconButton
          onClick={() => dispatch(closeAllFormulas())}
          iconName="closeSmall"
        />
      </div>
      {children}
    </ResizableBox>
  );
};

export function FormulasAndVariablesWorkspace({
  onClose,
  editMode,
  initialOpen,
  children,
}: React.PropsWithChildren<Props> & RouteComponentProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useAppDispatch();
  const opened = useAppSelector(selectFormulasSettingsIsOpened) ?? initialOpen;
  const withChildren = children != null;

  useResetFormulasSettingsOnUnmount();

  const openedFormulasCount = useAppSelector(selectOpenedFormulasCount);
  const isNotEditMode = openedFormulasCount === 0 && !editMode;
  const handleClose = () => {
    dispatch(resetFormulasState());
    setFullScreen(false);
    onClose?.();
  };

  return (
    <div
      className={cn(
        'grid h-screen transition-all',
        opened && 'grid-cols-[minmax(0,auto),600px]',
        !isNotEditMode && 'grid-rows-[auto,minmax(min-content,max-content)]',
      )}
    >
      {children && (
        <div className={cn('h-full overflow-auto', fullScreen && 'hidden')}>
          {children}
        </div>
      )}
      {!isNotEditMode && (
        <TabsWrapper
          onFullScreenToggle={
            editMode ? undefined : () => setFullScreen(!fullScreen)
          }
          resizable={withChildren && !fullScreen}
        >
          <FormulasTabs
            className={cn(
              withChildren && !fullScreen && 'h-full grow overflow-auto p-0',
            )}
          />
        </TabsWrapper>
      )}
      {opened && <FormulaAndVariablesPanel inWorkspace onClose={handleClose} />}
    </div>
  );
}
