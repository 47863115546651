import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Link } from '@reach/router';
import { useSettingsMenuItemPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import { currentUserIsSreAdmin } from 'lib/permissions';
import Icon from 'stories/Icon/Icon';
import { IconsId } from 'types/sre-icons';

interface Props {
  withHeader?: boolean;
  onAction?: () => void;
}

const useSettingItems = () => {
  const permissions = useSettingsMenuItemPermissions();

  return [
    {
      href: ROUTES_ROOT.userManagement.fullPath,
      color: 'default',
      label: 'User Management',
      canView: permissions.userManagementVisible,
      icon: 'users' as IconsId,
    },
    {
      href: ROUTES_ROOT.settings.portal.core.fullPath,
      color: 'yellow',
      label: 'coRE',
      canView: permissions.coreVisible,
      icon: 'settings' as IconsId,
    },
    {
      href: ROUTES_ROOT.settings.pipeline.fullPath,
      color: 'blue',
      label: 'Pipeline',
      canView: permissions.pipelineVisible,
      icon: 'analys' as IconsId,
    },
    {
      href: currentUserIsSreAdmin()
        ? ROUTES_ROOT.settings.report.connections.fullPath
        : ROUTES_ROOT.settings.report.financial.fullPath,
      color: 'green',
      label: 'Report',
      canView: permissions.reportVisible,
      icon: 'table' as IconsId,
    },
    {
      href: '/settings/emails/templates/invitations',
      color: 'red',
      label: 'Emails',
      canView: permissions.emailVisible,
      icon: 'mailBulk' as IconsId,
    },
  ];
};

const SettingsCard = ({ withHeader, onAction }: Props) => {
  const items = useSettingItems();

  return (
    <div className="list-card flex h-auto flex-col gap bg-light-10">
      {withHeader && (
        <h6 className="dark-60 bg-light py-s text-center">Settings</h6>
      )}
      <div className="flex flex-grow flex-wrap gap ">
        {items.map(
          ({ href, color, label, canView, icon }) =>
            canView && (
              <div
                className="left-sidebar__settings flex h-[50%] grow flex-col items-center justify-center"
                key={href + label}
              >
                <Link
                  onClick={() => onAction?.()}
                  to={href}
                  className="sre-link flex flex-col items-center py-l"
                >
                  <Icon
                    iconName={icon}
                    className={`left-sidebar__settings-icon justify-center !rounded-[1rem] left-sidebar__settings-icon_${color} text-[1.5rem]`}
                  />
                  <p className="inline-semibold text-center">{label}</p>
                </Link>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default SettingsCard;
