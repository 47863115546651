import { ThinTabGroup } from '@/stories';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { arrayMoveImmutable } from 'array-move';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import {
  EagleEyeBoard,
  findBoardByIdOrSlug,
} from 'bundles/Shared/entities/dashboard';
import { CreateBoardButton } from 'bundles/Shared/features/dashboard/boardCRUD';
import { useMoveBoards } from 'bundles/Shared/features/dashboard/moveBoards';
import { DashboardBoardActions } from 'bundles/Shared/widgets/dashboard/board/ui/DashboardBoardActions';
import React, { useEffect, useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';

type Board = Pick<EagleEyeBoard, 'id' | 'name' | 'slug'>;

interface Props extends ClassNameProps {
  boards: Board[];
  selectedBoardId: EagleEyeBoard['id'] | undefined;
  onBoardChange: (board: Board | undefined | null) => void;
  editable?: boolean;
  classes?: {
    content?: string;
  };
}

export function DashboardBoards({
  boards,
  selectedBoardId,
  onBoardChange,
  editable,
  classes,
  children,
  className,
}: React.PropsWithChildren<Props>) {
  const [orderedBoards, setOrderedBoards] = useState(boards);
  const moveBoard = useMoveBoards();
  const [selectedBoard, setSelectedBoard] = useState<EagleEyeBoard | null>(
    null,
  );

  useEffect(() => {
    // sync local boards for optimistically updating the UI
    if (orderedBoards !== boards) {
      setOrderedBoards(boards);
    }
  }, [boards]);

  if (!editable && boards.length === 1) {
    return (
      <div className={cn('flex flex-col gap-tw-6', className)}>{children}</div>
    );
  }

  if (!editable && boards.length > 1) {
    return (
      <div className={cn('flex flex-col gap-tw-6', className)}>
        <ThinTabGroup
          className="self-start"
          selectedItem={selectedBoardId}
          onSelectedItemChange={(o) =>
            onBoardChange(findBoardByIdOrSlug(boards, o.id))
          }
          items={boards.map((board) => ({
            id: board.id,
            label: board.name,
          }))}
        />
        {children}
      </div>
    );
  }
  const handleDragEnd: OnDragEndResponder = (e) => {
    const { destination, source } = e;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const newBoards = arrayMoveImmutable(
      boards,
      source.index,
      destination.index,
    );
    setOrderedBoards(newBoards);
    moveBoard({
      boards: newBoards,
    });
  };
  const renderBoardTab = (board: EagleEyeBoard, index: number) => {
    return (
      <>
        <Draggable key={board.id} draggableId={board.id} index={index}>
          {(provided) => (
            <ThinTabGroup.Item
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              id={board.id}
              key={board.id}
              selected={board.id === selectedBoardId}
              onContextMenu={
                editable
                  ? (e) => {
                      e.preventDefault();
                      setSelectedBoard(board);
                    }
                  : undefined
              }
              onClick={() => onBoardChange(board)}
            >
              {board.name}
            </ThinTabGroup.Item>
          )}
        </Draggable>
        {index !== boards.length - 1 && <VerticalSeparator />}
      </>
    );
  };
  return (
    <div
      className={cn(
        'flex grow flex-col gap-tw-1 !rounded-2xl border-8 border-solid border-neutral-200',
        className,
      )}
    >
      <div className="flex items-center bg-neutral-200 pb-tw-1">
        {editable && (
          <DashboardBoardActions
            visible={Boolean(selectedBoard)}
            onClickOutside={() => setSelectedBoard(null)}
            onHidden={() => setSelectedBoard(null)}
            board={selectedBoard}
            reference={document.getElementById(selectedBoard?.id ?? '')}
            onBoardDelete={() => onBoardChange(boards[0])}
            onBoardUpdate={(board) => onBoardChange(board)}
          />
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="boards" direction="horizontal">
            {({ innerRef, droppableProps, placeholder }) => (
              <ThinTabGroup.Container ref={innerRef} {...droppableProps}>
                {orderedBoards.map(renderBoardTab)}
                {placeholder}
              </ThinTabGroup.Container>
            )}
          </Droppable>
        </DragDropContext>
        {editable && (
          <CreateBoardButton
            onBoardCreate={(board) => {
              onBoardChange(board);
            }}
          />
        )}
      </div>
      <div className={cn('grow p-tw-7', classes?.content)}>{children}</div>
    </div>
  );
}
