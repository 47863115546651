/**
 * @deprecated use `ROUTES` instead
 */
export const OLD_ROUTES = {
  ASSETS_ROOT: '/assets',
  FUNDS_ROOT: '/funds',
  RECONCILE_ROOT: '/reconcile',
  RECONCILE_OPERATIONAL: '/reconcile/operational',
  RECONCILE_DEVELOPMENT: '/reconcile/development',
  RECONCILE_DEVELOPMENT_SETTINGS: '/reconcile/development_settings/:tab',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY: '/reconcile/development/:legalEntityCode',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT:
    '/reconcile/development/:legalEntityCode/snapshot',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SUMMARY:
    '/reconcile/development/:legalEntityCode/summary',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS:
    '/reconcile/development/:legalEntityCode/reallocations',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION:
    '/reconcile/development/:legalEntityCode/reallocation/:reallocationId',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAY_APPS:
    '/reconcile/development/:legalEntityCode/pay-apps',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS:
    '/reconcile/development/:legalEntityCode/change-order-logs',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER:
    '/reconcile/development/:legalEntityCode/change-order/:changeOrderId',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS:
    '/reconcile/development/:legalEntityCode/change-event-logs',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT:
    '/reconcile/development/:legalEntityCode/change-event/:id',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT_MOBILE:
    '/reconcile/development/:legalEntityCode/change-event/:id/mobile',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR:
    '/reconcile/development/:legalEntityCode/budgets/:budgetId/editor',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS:
    '/reconcile/development/:legalEntityCode/requisitions',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES:
    '/reconcile/development/:legalEntityCode/requisitions/:requisitionId/invoices',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES_ITEM:
    '/reconcile/development/:legalEntityCode/requisitions/:requisitionId/invoices/:invoiceId',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS:
    '/reconcile/development/:legalEntityCode/requisitions/:requisitionId/line-items',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS:
    '/reconcile/development/:legalEntityCode/settings/:tab',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES:
    '/reconcile/development/:legalEntityCode/invoices',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES:
    '/reconcile/development/:legalEntityCode/payable_invoices',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY:
    '/reconcile/development/:legalEntityCode/payable_invoices/:categoryId/category/:type/categoryType',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS:
    '/reconcile/development/:legalEntityCode/draws',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES:
    '/reconcile/development/:legalEntityCode/draws/:drawId/invoices',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES_ITEM:
    '/reconcile/development/:legalEntityCode/draws/:drawId/invoices/:invoiceId',
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY_ITEM:
    '/reconcile/development/:legalEntityCode/payable_invoices/:categoryId/category/:type/categoryType/:invoiceId',
} as const;
