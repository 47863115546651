import { RRULE_WEEKDAY_MAP } from '@/entities/emails/customTemplates/config';
import { useRRuleOptionsContext } from '@/entities/emails/customTemplates/lib/context';
import { Button, Dropdown, DropdownItem } from '@/stories';
import { capitalize } from 'lodash-es';
import type { ComponentProps } from 'react';
import type { Weekday } from 'rrule';

export const WeekdayDropdown = ({
  disabled,
  onClick,
}: {
  onClick: (value: Weekday) => void;
} & Pick<ComponentProps<typeof Dropdown>, 'disabled'>) => {
  const rruleOptions = useRRuleOptionsContext();
  return (
    <Dropdown
      appendTo={document.body}
      disabled={disabled}
      items={
        <>
          {Object.entries(RRULE_WEEKDAY_MAP).map(([key, value], idx) => (
            <DropdownItem
              active={idx === rruleOptions.byweekday?.[0]}
              key={key}
              onClick={() => onClick(value)}
            >
              {capitalize(key)}
            </DropdownItem>
          ))}
        </>
      }
    >
      <Button
        disabled={disabled}
        iconName="bottom"
        size="m"
        variant="secondary"
        iconPosition="right"
      >
        {capitalize(
          Object.entries(RRULE_WEEKDAY_MAP).find(
            (_, idx) => idx === rruleOptions.byweekday?.[0],
          )?.[0] ?? 'Select Weekday',
        )}
      </Button>
    </Dropdown>
  );
};
