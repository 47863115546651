import { IconButton } from '@/stories/IconButton/IconButton';

import Dropdown from '@/stories/Dropdown/Dropdown';
import {
  useDeleteThreshold,
  useUpdateThreshold,
} from '@/features/report/threshold/crud/lib';
import { Threshold } from '@/entities/report/thresholds/model';
import { canUserConfigureThresholds } from '@/entities/report/thresholds/lib';

export const ThresholdsActionsDropdown = ({
  threshold,
}: {
  threshold: Threshold;
}) => {
  const updateThreshold = useUpdateThreshold(threshold);
  const [deleteThreshold] = useDeleteThreshold(threshold);
  return (
    <Dropdown
      disabled={!canUserConfigureThresholds()}
      className="sre-dropdown-v2"
      classNameContainer="absolute right-tw-4 top-tw-2"
      placement="bottom-end"
      items={
        <>
          <Dropdown.Item iconName="trash" onClick={deleteThreshold}>
            Remove
          </Dropdown.Item>
          <Dropdown.Item iconName="edit" onClick={updateThreshold}>
            Edit
          </Dropdown.Item>
        </>
      }
    >
      <IconButton
        tooltipProps={{
          disabled: canUserConfigureThresholds(),
          titleText: 'To change this setting, please contact symmetRE',
        }}
        disabled={!canUserConfigureThresholds()}
        iconName="settings"
      />
    </Dropdown>
  );
};
