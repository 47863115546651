import { TRootState } from '@/app/stores';
import { getFinancialsDatePickerFullHistoryPeriodItems } from '@/bundles/REport/components/financials/widgets/fullHistory/lib';
import type { ObjectTypeAndId } from '@/bundles/REport/reducers/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { favoriteIncludesItemId } from '@/shared/lib/hooks/useFavoriteItemIds';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addStateProps,
  areReportFinancialsObjectsEqual as areObjectsEqual,
  createReportFinancialsObjIdAndType,
  resolveDefaultPeriodItemsByPeriodItemType,
  sortPeriodItemsByUnix,
} from 'bundles/REport/components/financials/utils';
import { currentUserIsExternal } from 'lib/permissions';
import { exists } from 'lib/typeHelpers';
import { isEmpty } from 'lodash-es';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';
import {
  createMonthPeriodItem,
  ReportTableConfigType,
} from 'stories/FlexibleFilterByPeriods/utils';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { reportFinancialsApi } from '../api/financialsApi';
import {
  LEAndStateProps,
  ObjAndStateProps,
} from '../components/financials/types';
import {
  changeObjSelected,
  stringifyObjTypeAndId,
  updateObjSelection,
} from './utils';

export type _ObjectableType = 'asset' | 'fund' | 'segment';

export const ALL_VIEW_PARAM_TYPE = 'all';
export type ViewParamType =
  | _ObjectableType
  | typeof ALL_VIEW_PARAM_TYPE
  | 'favorite';

export type FavoriteKey = 'favorite';

interface State {
  reportTableConfig: IReportTableConfig | null;
  filterByPeriodsType: IPeriodItem['type'];
  possiblePeriods: IPeriodItem['period'][];
  showUnpublished: boolean;
  viewParamType: ViewParamType;
  defaultObjSelected: boolean;
  objects: ObjAndStateProps[];
  multipleModeSelectedObjectTypeAndIdArray: ObjectTypeAndId[];
  periodItems: IPeriodItem[];
  dashboardExpanded: boolean;
  asOfDate: UnixTime | null;
  objectSelectionMode: 'single' | 'multiple';
  isFullHistoryActive: boolean;
}

const initialState: State = {
  reportTableConfig: null,
  possiblePeriods: [],
  showUnpublished: false,
  viewParamType: ALL_VIEW_PARAM_TYPE,
  defaultObjSelected: false,
  objects: [],
  filterByPeriodsType: 'mtd',
  periodItems: [],
  dashboardExpanded: false,
  asOfDate: null,
  objectSelectionMode: 'single',
  isFullHistoryActive: false,
};

const financialsSlice = createSlice({
  name: 'report/new-financials',
  initialState,
  reducers: {
    toggleObjectSelection(
      state,
      { payload: { object } }: PayloadAction<{ object: ObjAndStateProps }>,
    ) {
      state.objects = state.objects.map((i) => {
        if (!areObjectsEqual(object, i)) return i;
        const wasSelected = i._selected;

        return changeObjSelected(object, !wasSelected);
      });
    },
    updateObjectSelection(
      state,
      {
        payload: { object, _selected },
      }: PayloadAction<{ object: ObjAndStateProps; _selected: boolean }>,
    ) {
      state.objects = updateObjSelection(state.objects, object, _selected);
    },
    updateObjectListSelectionByType(
      state,
      {
        payload: { _type, _selected },
      }: PayloadAction<{ _type: _ObjectableType; _selected: boolean }>,
    ) {
      state.objects = state.objects.map((i) => {
        if (i._type !== _type) return i;
        return { ...i, _selected };
      });
    },
    toggleObjectLegalEntitySelection(
      state,
      {
        payload: { object, legalEntityId },
      }: PayloadAction<{
        object: ObjAndStateProps;
        legalEntityId: LEAndStateProps['id'];
      }>,
    ) {
      state.objects = state.objects.map((o) => {
        if (!areObjectsEqual(object, o)) return o;

        const legalEntities = o.legalEntities.map((le) => {
          const isSameLE = legalEntityId === le.id;
          if (!isSameLE) return le;
          return { ...le, _selected: !le._selected };
        });

        return {
          ...o,
          legalEntities,
        };
      });
    },
    updateObjects(state, action: PayloadAction<State['objects']>) {
      state.objects = action.payload;
    },
    updateViewParamType(state, action: PayloadAction<State['viewParamType']>) {
      state.viewParamType = action.payload;
    },
    toggleDashboardExpanded(state) {
      state.dashboardExpanded = !state.dashboardExpanded;
    },
    toggleShowUnpublished(state) {
      state.showUnpublished = !state.showUnpublished;
    },
    updatePeriodItems(
      state,
      { payload: periodItems }: PayloadAction<State['periodItems']>,
    ) {
      state.periodItems = sortPeriodItemsByUnix(periodItems);
      if (state.isFullHistoryActive) {
        state.isFullHistoryActive = false;
      }
    },
    updateFilterByPeriodsType(
      state,
      action: PayloadAction<State['filterByPeriodsType']>,
    ) {
      state.filterByPeriodsType = action.payload;

      if (!state.isFullHistoryActive) return;

      state.periodItems = getFinancialsDatePickerFullHistoryPeriodItems(
        state.possiblePeriods,
        action.payload,
      );
    },
    onFullHistoryActiveChange(state) {
      state.isFullHistoryActive = !state.isFullHistoryActive;

      if (!state.isFullHistoryActive) {
        state.periodItems = [];
        return;
      }

      state.periodItems = getFinancialsDatePickerFullHistoryPeriodItems(
        state.possiblePeriods,
        state.filterByPeriodsType,
      );
    },
    onSelectMore(state) {
      state.objectSelectionMode = 'multiple';
      state.multipleModeSelectedObjectTypeAndIdArray = state.objects
        .filter((o) => o._selected)
        .map(stringifyObjTypeAndId);
    },
    onSelectOne(state) {
      state.objectSelectionMode = 'single';

      state.objects = state.objects.map((o, index) => {
        return changeObjSelected(o, index === 0);
      });
    },
    syncObjectsWithMultipleModeSelectedObjectTypeAndIdArray(state) {
      const set = new Set(state.multipleModeSelectedObjectTypeAndIdArray);

      state.objects = state.objects.map((o) => {
        return changeObjSelected(o, set.has(stringifyObjTypeAndId(o)));
      });
    },
    onSelectOneObject(
      state,
      { payload: object }: PayloadAction<ObjAndStateProps>,
    ) {
      state.objects = state.objects.map((o) => {
        return changeObjSelected(o, areObjectsEqual(object, o));
      });
    },
    updateBulkObjectsWithMultipleModeSelectedObjectTypeAndIdArray(
      state,
      { payload: object }: PayloadAction<ObjAndStateProps>,
    ) {
      const objTypeAndId = stringifyObjTypeAndId(object);

      const objTypeAndIdFromState =
        state.multipleModeSelectedObjectTypeAndIdArray.find(
          (i) => i === objTypeAndId,
        );

      if (objTypeAndIdFromState == null) {
        state.multipleModeSelectedObjectTypeAndIdArray.push(objTypeAndId);
      } else {
        state.multipleModeSelectedObjectTypeAndIdArray =
          state.multipleModeSelectedObjectTypeAndIdArray.filter(
            (i) => i !== objTypeAndId,
          );
      }
    },
    resetSelectedState(state) {
      state.objectSelectionMode = 'single';
      state.multipleModeSelectedObjectTypeAndIdArray = [];
      state.objects = state.objects.map((obj) => changeObjSelected(obj, false));
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        reportFinancialsApi.endpoints.getTableViewParameters.matchFulfilled,
        (state, { payload, meta }) => {
          const objectTypeAndIdArr = meta.arg.originalArgs
            .objectTypeAndIdArr as ObjectTypeAndId[] | undefined;

          const objectsWithStateProps: ObjAndStateProps[] = [
            ...addStateProps(payload.assets, 'asset'),
            ...addStateProps(payload.funds, 'fund'),
            ...addStateProps(payload.segments, 'segment'),
          ];

          const selectedObjects = state.objects.filter((o) => o._selected);
          if (selectedObjects.length > 0) return;

          if (objectTypeAndIdArr != null && objectTypeAndIdArr.length > 0) {
            state.objects = objectsWithStateProps.map((obj) => {
              if (objectTypeAndIdArr.includes(stringifyObjTypeAndId(obj))) {
                return changeObjSelected(obj, true);
              }
              return obj;
            });

            if (selectedObjects.length > 1) {
              state.multipleModeSelectedObjectTypeAndIdArray =
                selectedObjects.map((o) => stringifyObjTypeAndId(o));
            }
            state.objectSelectionMode =
              selectedObjects.length > 1 ? 'multiple' : 'single';
            return;
          }

          const findFavorite = (obj: ObjAndStateProps) => {
            const favoriteId = createReportFinancialsObjIdAndType(obj);
            return favoriteIncludesItemId(
              favoriteId,
              'reportFinancialsObjectIdAndType',
            );
          };

          const defaultSelectedObj =
            objectsWithStateProps.find(findFavorite) ??
            objectsWithStateProps[0];

          state.objects = updateObjSelection(
            objectsWithStateProps,
            defaultSelectedObj,
            true,
          );
          state.defaultObjSelected = true;
        },
      )
      .addMatcher(
        reportFinancialsApi.endpoints.getTablePeriods.matchFulfilled,
        (state, { payload }) => {
          state.asOfDate = payload.meta.actualAt;
        },
      )
      .addMatcher(
        reportFinancialsApi.endpoints.getReportTableConfig.matchFulfilled,
        (state, { payload }) => {
          if (state.reportTableConfig === null) {
            state.showUnpublished = !currentUserIsExternal();
          }
          state.reportTableConfig = payload;

          const tableConfigType = new ReportTableConfigType(
            payload.periodsType,
          );

          const updatedFilterByPeriodsType = !tableConfigType.isCommonTable
            ? 'mtd'
            : state.filterByPeriodsType;

          if (!exists(state.reportTableConfig)) return;

          if (!tableConfigType.isCommonTable) {
            state.filterByPeriodsType = updatedFilterByPeriodsType;
          }

          if (state.possiblePeriods.length === 0) return;

          if (state.isFullHistoryActive) {
            if (tableConfigType.isSingleMonthSelection) {
              state.isFullHistoryActive = false;
            } else {
              state.periodItems = getFinancialsDatePickerFullHistoryPeriodItems(
                state.possiblePeriods,
                state.filterByPeriodsType,
              );
              return;
            }
          }

          const defaultPeriodItems = resolveDefaultPeriodItemsByPeriodItemType({
            periodItemType: updatedFilterByPeriodsType,
            possiblePeriods: state.possiblePeriods,
            reportTableConfigPeriodsType: state.reportTableConfig.periodsType,
            withOnlyBudgetColumns:
              state.reportTableConfig.withOnlyBudgetColumns,
          });

          state.periodItems = defaultPeriodItems.length
            ? defaultPeriodItems
            : [createMonthPeriodItem(formatToDateStringForRequest(new Date()))];
        },
      )
      .addMatcher(
        reportFinancialsApi.endpoints.getTablePeriods.matchFulfilled,
        (state, { payload }) => {
          const mergedPeriods = new Set([
            ...payload.items,
            ...state.periodItems.map((item) => item.period),
          ]);

          const newPossiblePeriods = [...mergedPeriods];

          state.possiblePeriods = newPossiblePeriods;

          if (!isEmpty(state.periodItems)) {
            if (state.isFullHistoryActive) {
              state.periodItems = getFinancialsDatePickerFullHistoryPeriodItems(
                newPossiblePeriods,
                state.filterByPeriodsType,
              );
            }
            return;
          }

          if (payload.items.length === 0) {
            state.periodItems = [];
            return;
          }

          if (state.reportTableConfig === null) return;

          state.periodItems = resolveDefaultPeriodItemsByPeriodItemType({
            periodItemType: state.filterByPeriodsType,
            possiblePeriods: payload.items,
            reportTableConfigPeriodsType: state.reportTableConfig.periodsType,
            withOnlyBudgetColumns:
              state.reportTableConfig.withOnlyBudgetColumns,
          });
        },
      );
  },
});

export const selectSelectedObjects = (state: TRootState) =>
  state.financials.objects.filter((i) => i._selected);

export const selectSelectedLegalEntities = (state: TRootState) => {
  const selectedObjects = state.financials.objects.filter((i) => i._selected);
  return selectedObjects.flatMap((i) =>
    i.legalEntities.filter((le) => le._selected),
  );
};

export const selectReportTableConfig = (state: TRootState) => {
  const { reportTableConfig } = state.financials;
  if (!exists(reportTableConfig))
    throw new Error('ReportTableConfig is not loaded yet');
  return reportTableConfig;
};

export const selectIsSingleMode = (state: TRootState) =>
  state.financials.objectSelectionMode === 'single';

export const selectIsMultipleMode = (state: TRootState) =>
  state.financials.objectSelectionMode === 'multiple';

export const {
  updateViewParamType,
  updateObjects,
  toggleObjectSelection,
  updateObjectSelection,
  updateObjectListSelectionByType,
  toggleObjectLegalEntitySelection,
  toggleDashboardExpanded,
  toggleShowUnpublished,
  onFullHistoryActiveChange,
  updatePeriodItems,
  updateFilterByPeriodsType,
  onSelectMore,
  onSelectOne,
  onSelectOneObject,
  syncObjectsWithMultipleModeSelectedObjectTypeAndIdArray,
  updateBulkObjectsWithMultipleModeSelectedObjectTypeAndIdArray,
} = financialsSlice.actions;
export const { actions: financialsSliceActions } = financialsSlice;

export default financialsSlice.reducer;
