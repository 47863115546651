import { selectSelectedObjects } from '@/bundles/REport/reducers/financialsSlice';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useEffect } from 'react';

export const useSubscribeFinancialsSelectedObjectsToSearchParams = () => {
  const selectedObjects = useAppSelector(selectSelectedObjects);
  const [currentSearchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchParams = new URLSearchParams();
    selectedObjects.forEach((obj) => {
      searchParams.append(obj._type, obj.id.toString());
    });
    searchParams.sort();

    if (currentSearchParams.toString() === searchParams.toString()) return;

    setSearchParams(searchParams, undefined, {
      arrayFormat: 'repeat',
    });
  }, [selectedObjects]);
};
