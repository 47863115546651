export const resolveReportBuilderGroupHeader = ({
  displayHeaderGlobal,
  displayHeaderGroup,
}: {
  /**
   * @param can be overrided by `displayHeaderGroup`.
   */
  displayHeaderGlobal: boolean;
  /**
   * @param Can override `displayHeaderGlobal`.
   */
  displayHeaderGroup: boolean | null;
}): boolean => {
  if (displayHeaderGroup != null) return displayHeaderGroup;

  return displayHeaderGlobal;
};
