import { SetFilterComponent } from '@/bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import {
  ASSET_NAME_ID,
  FILTERED_TRADEOUT_COL_ID,
  LEASE_TYPE_ID,
  UNIT_TYPE_ID,
} from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/config';
import { ValueFormatterParams } from 'ag-grid-community';

export const FILTERABLE_COLUMN_IDS = [
  LEASE_TYPE_ID,
  '4',
  UNIT_TYPE_ID,
  ASSET_NAME_ID,
  FILTERED_TRADEOUT_COL_ID,
] as const;

const FILTER_COLUMN_LABELS = {
  [FILTERED_TRADEOUT_COL_ID]: {
    Yes: 'Included',
    No: 'Excluded',
  },
};

type FilterOption = {
  column?: string;
  options?: string[];
};

export const getColDefFilter = ({
  columnSettings,
  column,
  filterOptions,
}: {
  columnSettings: TableVizConfigColumn;
  column:
    | {
        label: string;
      }
    | undefined;
  filterOptions: FilterOption[];
}) => {
  if (!FILTERABLE_COLUMN_IDS.includes(columnSettings.key)) {
    return {};
  }
  return {
    filter: SetFilterComponent,
    filterParams: column?.label && {
      values:
        filterOptions.find((o) => o.column === column.label)?.options ?? [],
      valueFormatter: (params: ValueFormatterParams) =>
        FILTER_COLUMN_LABELS[columnSettings.col_id]?.[params.value] ??
        params.value,
    },
  };
};
