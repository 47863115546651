import { emptySplitApi as api } from '@/app/stores/api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiRecapPages: build.query<
      GetApiRecapPagesApiResponse,
      GetApiRecapPagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recap/pages`,
        params: { query: queryArg.query },
      }),
    }),
    postApiRecapPages: build.mutation<
      PostApiRecapPagesApiResponse,
      PostApiRecapPagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recap/pages`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiRecapPagesById: build.query<
      GetApiRecapPagesByIdApiResponse,
      GetApiRecapPagesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/recap/pages/${queryArg.id}` }),
    }),
    putApiRecapPagesById: build.mutation<
      PutApiRecapPagesByIdApiResponse,
      PutApiRecapPagesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recap/pages/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiRecapPagesById: build.mutation<
      DeleteApiRecapPagesByIdApiResponse,
      DeleteApiRecapPagesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recap/pages/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiRecapPagesSync: build.mutation<
      PostApiRecapPagesSyncApiResponse,
      PostApiRecapPagesSyncApiArg
    >({
      query: () => ({ url: `/api/recap/pages/sync`, method: 'POST' }),
    }),
    getApiRecapPagesByIdSubjectables: build.query<
      GetApiRecapPagesByIdSubjectablesApiResponse,
      GetApiRecapPagesByIdSubjectablesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recap/pages/${queryArg.id}/subjectables`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as documentationApi };
export type GetApiRecapPagesApiResponse = /** status 200 success */ {
  items: {
    id: string;
    parentId: string | null;
    name: string;
    slug: string;
    isDraft: boolean;
    isSystem: boolean;
    position: number;
  }[];
  meta: {
    systemSync?: CustomerBridgeSyncDto | null;
  };
};
export type GetApiRecapPagesApiArg = {
  query?: string;
};
export type PostApiRecapPagesApiResponse = /** status 200 success */ {
  id: string;
  slug: string | null;
};
export type PostApiRecapPagesApiArg = {
  body: {
    name?: string;
    content?: object;
    is_draft?: boolean;
    position?: number;
    parent_id?: string | null;
  };
};
export type GetApiRecapPagesByIdApiResponse = /** status 200 success */ {
  id: string;
  name: string;
  content: object;
  isDraft: boolean;
  isSystem: boolean;
  slug: string | null;
  parentId: string | null;
  breadcrumbs: {
    id: string;
    name: string;
    slug: string | null;
  }[];
  permitted: {
    isPublic: boolean;
    directUsers: any[];
    indirectUsers: any[];
    directRoles: {
      id: string;
      name: string;
    }[];
    directTags: any[];
    directInvestmentEntities: any[];
  };
};
export type GetApiRecapPagesByIdApiArg = {
  id: string;
};
export type PutApiRecapPagesByIdApiResponse = /** status 200 success */ {
  id: string;
  slug: string | null;
};
export type PutApiRecapPagesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    content?: object;
    is_draft?: boolean;
    position?: number;
    parent_id?: string | null;
  };
};
export type DeleteApiRecapPagesByIdApiResponse = unknown;
export type DeleteApiRecapPagesByIdApiArg = {
  id: string;
};
export type PostApiRecapPagesSyncApiResponse = unknown;
export type PostApiRecapPagesSyncApiArg = void;
export type GetApiRecapPagesByIdSubjectablesApiResponse =
  /** status 200 success */ {
    isPublic: boolean;
    directUsers: any[];
    indirectUsers: any[];
    directTags: any[];
    directInvestmentEntities: any[];
    directRoles: {
      id: string;
      name: string;
    }[];
    allRoles: {
      id: string;
      name: string;
      group: string | null;
      category: string;
    }[];
    allUsers: any[];
    allTags: any[];
    allInvestmentEntities: any[];
  };
export type GetApiRecapPagesByIdSubjectablesApiArg = {
  id: string;
};
export type CustomerBridgeSyncDto = {
  id: string;
  status: 'in_progress' | 'completed' | 'failed';
  kind:
    | 'report_financial_category'
    | 'report_system_manual_variables'
    | 'reckoner_system_formulas';
  memos: string[];
  syncedAt: number | null;
  createdAt: number;
  initiator: {
    id: string;
    name: string;
  };
};
