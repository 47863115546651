import {
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { DashboardHistoricalReviewTableWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  ColDefBuilder,
  resolveBackgroundAndTextColor,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { sleepUntilAgGridSetsRowDataInTime } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/utils';
import { buildExcelStyleId } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';

type HistoricalTableWidgetDtoRow =
  DashboardHistoricalReviewTableWidgetDto['data'][0] & {
    key?: number;
  };

const isLabelColumn = (columnSettings: TableVizConfigColumn) => {
  return columnSettings.key == 'label';
};

export class RowColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> extends ColDefBuilder<Column> {
  rows: TableVizConfigRow[];
  constructor({
    mode,
    onPinColumn,
    rows,
  }: {
    mode: WidgetViewMode;
    rows: TableVizConfigRow[];
    onPinColumn?: (colId: string) => unknown;
  }) {
    super({
      mode,
      onPinColumn,
    });
    this.rows = rows ?? [];
  }

  findRowSettings(rowKey: string | undefined) {
    return this.rows.find((r) => r.key === rowKey);
  }

  build({
    column,
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): ColDef {
    const inheritedColDef = super.build({ column, columnSettings });
    return {
      ...inheritedColDef,
      sortable: false,
      comparator: undefined,
      headerName:
        columnSettings?.key === 'label' ? '' : inheritedColDef.headerName,
      // row excel styles works only with cellClassRules
      cellClassRules: Object.fromEntries(
        this.rows.map((row) => [
          buildExcelStyleId({
            id: row.key,
            type: 'row',
          }),
          (params: ICellRendererParams) => {
            const currentRow = params.data as HistoricalTableWidgetDtoRow;
            return (
              currentRow.key?.toString() === row.key &&
              columnSettings.key !== 'label'
            );
          },
        ]),
      ),

      cellRendererParams: (params: ICellRendererParams) => {
        const row = params.data as HistoricalTableWidgetDtoRow;
        const rowSettings = this.findRowSettings(row.key?.toString());

        const inheritedParams = super
          .build({ column, columnSettings })
          .cellRendererParams(params);
        return {
          ...inheritedParams,
          styles: {
            ...inheritedParams.styles,
            ...resolveBackgroundAndTextColor({
              params,
              direction: 'row',
              shouldPaintBackground: () => !isLabelColumn(columnSettings),
              shouldColorText: () => !isLabelColumn(columnSettings),
              background: rowSettings?.background,
              comparison: rowSettings?.comparison,
            }),
            fontWeight: rowSettings?.font_weight ?? 'normal',
          },
        };
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (isLabelColumn(columnSettings)) {
          const { cellRenderer } = getDefaultAgGridNumberColDef({
            type: 'string',
          });
          return cellRenderer(params);
        }
        const row = params.data as HistoricalTableWidgetDtoRow;
        const rowSettings = this.findRowSettings(row.key?.toString());
        const { cellRenderer } = getDefaultAgGridNumberColDef(
          rowSettings?.value_display_options ?? {},
        );

        return cellRenderer(params);
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (isLabelColumn(columnSettings)) {
          return params.value;
        }
        const row =
          params.data as DashboardHistoricalReviewTableWidgetDto['data'][0] & {
            key?: number;
          };

        const rowSettings = this.rows.find(
          (r) => r.key === row.key?.toString(),
        );
        if (!rowSettings?.value_display_options) {
          return params.value;
        }
        const { valueFormatter } = getDefaultAgGridNumberColDef(
          rowSettings.value_display_options ?? {},
        );
        if (typeof valueFormatter === 'function') {
          return valueFormatter?.(params);
        }
        return params.value;
      },
    };
  }
}

export const handleHistoricalWidgetFirstDataRendered = async (
  e: FirstDataRenderedEvent,
) => {
  await sleepUntilAgGridSetsRowDataInTime();
  e.columnApi.autoSizeColumn('label');
  e.api.sizeColumnsToFit();
};
