import { RRULE_WEEKDAY_MAP } from '@/entities/emails/customTemplates/config';
import { MonthDropdown } from '@/entities/emails/customTemplates/form/rrule/MonthDropdown';
import { WeekdayDropdown } from '@/entities/emails/customTemplates/form/rrule/WeekdayDropdown';
import { RRuleOptionsContext } from '@/entities/emails/customTemplates/lib/context';
import { rruleToText } from '@/entities/emails/customTemplates/lib/rruleToText';
import { capitalize, findKey } from 'lodash-es';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Frequency, RRule, type Weekday } from 'rrule';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  Input,
  LinkButton,
  Modal,
  ModalActions,
  RadioButton,
} from 'stories';

interface Props {
  onClose: () => void;
  onSubmit: (newRrule: RRule) => void;
  rrule: RRule;
  defaultDailyRules: RRule['options'];
  defaultWeeklyRules: RRule['options'];
  defaultMonthlyRules: RRule['options'];
  defaultYearlyRules: RRule['options'];
}

const mapFrequncyToLabel = {
  day: Frequency.DAILY,
  week: Frequency.WEEKLY,
  month: Frequency.MONTHLY,
  year: Frequency.YEARLY,
};

const commonWhitelistItems = [
  'dtstart',
  'interval',
  'byhour',
  'byminute',
  'until',
  'freq',
];
const whitelists = {
  [RRule.DAILY]: [...commonWhitelistItems],
  [RRule.WEEKLY]: [...commonWhitelistItems, 'byweekday'],
  [RRule.MONTHLY]: [
    ...commonWhitelistItems,
    'bymonthday',
    'bysetpos',
    'byweekday',
  ],
  [RRule.YEARLY]: [
    ...commonWhitelistItems,
    'bymonth',
    'bymonthday',
    'bysetpos',
    'byweekday',
  ],
};

const ON_MONTH_DAY_LABEL = 'On (month day)';
const ON_WEEK_DAY_LABEL = 'On (week day)';

const ReccurenceModal = ({
  onClose,
  onSubmit,
  rrule,
  defaultDailyRules,
  defaultWeeklyRules,
  defaultMonthlyRules,
  defaultYearlyRules,
}: Props) => {
  const [newRruleOptions, setNewRruleOptions] = useState({ ...rrule.options });
  // radio button for day/weekday for monthly/yearly
  const [monthYearRadioOnDay, setMonthYearRadioOnDay] = useState(
    rrule.options.byweekday == null,
  );

  const currentRule = (currentFreq: RRule['options']['freq']) => {
    switch (currentFreq) {
      case Frequency.DAILY:
        return defaultDailyRules;
      case Frequency.WEEKLY:
        return defaultWeeklyRules;
      case Frequency.MONTHLY:
        return defaultMonthlyRules;
      case Frequency.YEARLY:
        return defaultYearlyRules;
      default:
        return defaultDailyRules;
    }
  };
  const filterRruleObjByWhitelist = (rruleObj: RRule['options']) => {
    const whitelist = whitelists[rruleObj.freq];

    const filteredRruleObj = {};
    Object.keys(rruleObj).forEach((key) => {
      if (whitelist.includes(key)) {
        filteredRruleObj[key] = rruleObj[key];
      }
    });

    return filteredRruleObj;
  };

  const handleWeekDayChange = (value: Weekday) => {
    setNewRruleOptions({
      ...newRruleOptions,
      byweekday: [value.weekday],
    });
  };

  // BYSETPOS is not support on BE yet
  // const handleSetPosChange = (value: Values<typeof RRULE_SET_POS_OPTIONS>) => {
  //   setNewRruleOptions({
  //     ...newRruleOptions,
  //     bysetpos: [value],
  //   });
  // };

  return (
    <Modal
      toggle={onClose}
      header="Configuring Recurrence Rules"
      size="700"
      actions={
        <ModalActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              onSubmit(new RRule(filterRruleObjByWhitelist(newRruleOptions)))
            }
            variant="success"
          >
            Set Reccurence Rules
          </Button>
        </ModalActions>
      }
    >
      <RRuleOptionsContext.Provider value={newRruleOptions}>
        <div className="reccurence-grid">
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Start
          </div>
          <div>
            <ReactDatePicker
              portalId="datepicker-portal"
              placeholderText="Start date"
              selected={newRruleOptions.dtstart}
              onChange={(date) =>
                setNewRruleOptions({ ...newRruleOptions, dtstart: date })
              }
              wrapperClassName="w-8-75rem"
              customInput={<Input rightIcon="calendar" className="w-8-75rem" />}
            />
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Repeat every
          </div>
          <div className="flex items-center">
            <Input
              className="w-3-75rem mr-s"
              value={newRruleOptions.interval || ''}
              onChange={(e) =>
                setNewRruleOptions({
                  ...newRruleOptions,
                  interval: Number(e.target.value),
                })
              }
            />
            <Dropdown
              items={
                <>
                  {Object.entries(mapFrequncyToLabel).map(([key, value]) => (
                    <DropdownItem
                      key={key}
                      onClick={() => setNewRruleOptions(currentRule(value))}
                    >
                      {capitalize(key)}
                    </DropdownItem>
                  ))}
                </>
              }
            >
              <Button
                iconName="bottom"
                size="m"
                variant="secondary"
                iconPosition="right"
              >
                {capitalize(
                  findKey(
                    mapFrequncyToLabel,
                    (value) => value === newRruleOptions.freq,
                  ),
                )}
              </Button>
            </Dropdown>
          </div>
          <div />
          <div className="flex flex-col gap-tw-2">
            {newRruleOptions.freq === RRule.WEEKLY &&
              Object.entries(RRULE_WEEKDAY_MAP).map(([key, weekDayRRule]) => {
                const weekdayNumbers = newRruleOptions.byweekday || [];

                return (
                  <Checkbox
                    key={key}
                    labelClassName="mt-s"
                    checked={weekdayNumbers.includes(weekDayRRule.weekday)}
                    onChange={() => {
                      if (weekdayNumbers.includes(weekDayRRule.weekday)) {
                        setNewRruleOptions({
                          ...newRruleOptions,
                          byweekday: newRruleOptions.byweekday.filter(
                            (day) => day !== weekDayRRule.weekday,
                          ),
                        });
                      } else {
                        setNewRruleOptions({
                          ...newRruleOptions,
                          byweekday: [...weekdayNumbers, weekDayRRule.weekday],
                        });
                      }
                    }}
                  >
                    <span className="dark-60 font-weight-400 inline-regular">
                      {capitalize(key)}
                    </span>
                  </Checkbox>
                );
              })}
            {newRruleOptions.freq === RRule.MONTHLY && (
              <>
                <div className="flex items-center gap-tw-2">
                  <RadioButton
                    classes={{
                      label: '!m-0',
                    }}
                    checked={monthYearRadioOnDay}
                    onChange={(e) => {
                      setNewRruleOptions(currentRule(newRruleOptions.freq));
                      setMonthYearRadioOnDay(e.target.checked);
                    }}
                    labelProps={{
                      style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                    }}
                  >
                    {ON_MONTH_DAY_LABEL}
                  </RadioButton>
                  <Input
                    className="mr-s w-[4rem]"
                    value={newRruleOptions.bymonthday?.[0] || ''}
                    onChange={(e) =>
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonthday: [Number(e.target.value)],
                      })
                    }
                    disabled={!monthYearRadioOnDay}
                  />
                </div>
                <div className="flex items-center gap-tw-2">
                  <RadioButton
                    classes={{
                      label: '!m-0',
                    }}
                    checked={!monthYearRadioOnDay}
                    onChange={(e) => {
                      setNewRruleOptions(currentRule(newRruleOptions.freq));
                      setMonthYearRadioOnDay(!e.target.checked);
                    }}
                    labelProps={{
                      style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                    }}
                  >
                    {ON_WEEK_DAY_LABEL}
                  </RadioButton>
                  {/* BYSETPOS is not support on BE yet */}
                  {/* <SetPosDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleSetPosChange}
                  /> */}

                  <WeekdayDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleWeekDayChange}
                  />
                </div>
              </>
            )}
            {newRruleOptions.freq === RRule.YEARLY && (
              <>
                <div className="flex items-center gap-tw-2">
                  <RadioButton
                    classes={{
                      label: '!m-0',
                    }}
                    checked={monthYearRadioOnDay}
                    onChange={(e) => {
                      setNewRruleOptions(currentRule(newRruleOptions.freq));
                      setMonthYearRadioOnDay(e.target.checked);
                    }}
                    labelProps={{
                      style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                    }}
                  >
                    {ON_MONTH_DAY_LABEL}
                  </RadioButton>
                  <MonthDropdown
                    disabled={!monthYearRadioOnDay}
                    onClick={(index) => {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonth: [index],
                      });
                    }}
                  />
                  <Input
                    className="w-[4rem]"
                    value={newRruleOptions.bymonthday?.[0] || ''}
                    onChange={(e) =>
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonthday: [Number(e.target.value)],
                      })
                    }
                    disabled={!monthYearRadioOnDay}
                  />
                </div>
                <div className="flex items-center gap-tw-2">
                  <RadioButton
                    checked={!monthYearRadioOnDay}
                    onChange={(e) => {
                      setNewRruleOptions(currentRule(newRruleOptions.freq));
                      setMonthYearRadioOnDay(!e.target.checked);
                    }}
                    labelProps={{
                      style: { marginTop: '0.65rem', marginRight: '0.5rem' },
                    }}
                  >
                    {ON_WEEK_DAY_LABEL}
                  </RadioButton>
                  {/* BYSETPOS is not support on BE yet */}
                  {/* <SetPosDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleSetPosChange}
                  /> */}
                  <WeekdayDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleWeekDayChange}
                  />
                  <MonthDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={(index) => {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonth: [index],
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Time
          </div>
          <div>
            <ReactDatePicker
              portalId="datepicker-portal"
              placeholderText="Time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              selected={
                new Date(
                  null,
                  null,
                  null,
                  newRruleOptions.byhour || 0,
                  newRruleOptions.byminute || 0,
                  0,
                  0,
                )
              }
              onChange={(time) => {
                setNewRruleOptions({
                  ...newRruleOptions,
                  byhour: [time?.getHours() ?? 0],
                  byminute: [time?.getMinutes() ?? 0],
                });
              }}
              wrapperClassName="w-8-75rem"
              customInput={<Input rightIcon="clock" className="w-8-75rem" />}
            />
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            End
          </div>
          <div className="flex-inline items-center">
            <ReactDatePicker
              portalId="datepicker-portal"
              placeholderText="Never"
              selected={newRruleOptions.until}
              onChange={(date) =>
                setNewRruleOptions({
                  ...newRruleOptions,
                  until: date,
                })
              }
              customInput={<Input rightIcon="calendar" className="w-8-75rem" />}
              wrapperClassName="w-8-75rem"
            />
            {newRruleOptions.until && (
              <LinkButton
                className="blue ml-m"
                onClick={() =>
                  setNewRruleOptions({
                    ...newRruleOptions,
                    until: undefined,
                  })
                }
              >
                Remove
              </LinkButton>
            )}
          </div>
          <div />
          <p className="dark-60 font-weight-400 inline-regular mt-s">
            {rruleToText(newRruleOptions)}
          </p>
        </div>
      </RRuleOptionsContext.Provider>
    </Modal>
  );
};

export default ReccurenceModal;
