import type { SyncDto } from '@/entities/report/syncs/api/syncsGeneratedApi';
import { SYNC_STATUS_UI_CONFIG } from '@/entities/report/syncs/config';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';

export const SyncStatus = ({ status }: { status: SyncDto['status'] }) => {
  const config = SYNC_STATUS_UI_CONFIG[status];
  return (
    <div className="flex items-center justify-between gap-tw-2">
      <Icon
        iconName={config.iconName}
        className={cn(
          'flex h-[16px] w-[16px] items-center justify-center rounded-2xl text-tw-white',
          config.iconClassName,
        )}
      />
      <p className={cn('inline-regular', config.textClassName)}>
        {config.label}
      </p>
    </div>
  );
};
