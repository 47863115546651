import { RRule } from 'rrule';

export const MONTH_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

export const RRULE_WEEKDAY_MAP = {
  monday: RRule.MO,
  tuesday: RRule.TU,
  wednesday: RRule.WE,
  thursday: RRule.TH,
  friday: RRule.FR,
  saturday: RRule.SA,
  sunday: RRule.SU,
} as const;

export const RRULE_SET_POS_OPTIONS = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: -1,
} as const;

export const RESET_RRULE_OPTIONS = {
  bymonth: undefined,
  bymonthday: undefined,
  byweekday: undefined,
  bysetpos: undefined,
  bysecond: undefined,
} as const satisfies Partial<RRule['options']>;
