import { Icon } from '@/stories';
import type { ComponentProps } from 'react';

export const ExpandCollapseRowsIcon = ({
  includeSourceUnitTypes = true,
  onClick,
  ...iconProps
}: {
  includeSourceUnitTypes: boolean | undefined;
} & Partial<ComponentProps<typeof Icon>>) => (
  <Icon
    {...iconProps}
    tooltipProps={{
      mainText: includeSourceUnitTypes
        ? 'Rows are expanded'
        : 'Rows are collapsed',
    }}
    iconName={includeSourceUnitTypes ? 'expandRows' : 'collapseRows'}
  />
);
