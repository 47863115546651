import { isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { MutationThunkArg } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { Middleware, MiddlewareAPI } from 'redux';

export type RtkQueryErrorLoggerMiddlewareArgs = {
  disableToast?: boolean;
};

export type ErrorResponse = {
  errors: string[];
};

export const rtkQueryErrorLogger: Middleware =
  (_: MiddlewareAPI) =>
  (next) =>
  (
    action: PayloadAction<{
      data: ErrorResponse;
      arg: MutationThunkArg & {
        originalArgs: RtkQueryErrorLoggerMiddlewareArgs;
      };
    }>,
  ) => {
    if (
      isRejectedWithValue(action) &&
      action.payload?.data?.errors &&
      !action.meta?.arg?.originalArgs?.disableToast
    ) {
      if (Array.isArray(action.payload.data.errors)) {
        toastr.error(action.payload.data.errors.join('; '));
      }
    }

    return next(action);
  };
