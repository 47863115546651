import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const PAGES_WITHOUT_MAIN_MENU = [
  '/mobile',
  '/approve',
  ROUTES_ROOT.useDesktopVersion.fullPath,
] as const;

export const PAGES_EXCEPTION_WITH_MAIN_MENU = ['workflow/approvers'] as const;

export const PAGES_WITHOUT_FOOTER = [
  'change-event',
  'change-order',
  'reallocation',
  ROUTES_ROOT.reports.fullPath,
  ROUTES_ROOT.reconcile.operational.fullPath,
  ROUTES_ROOT.reconcile.development.fullPath,
  ROUTES_ROOT.reconcile.underwriting.fullPath,
  ROUTES_ROOT.return.object.createEntry.fullPath,
  ROUTES_ROOT.return.object.createTransfer.fullPath,
  ROUTES_ROOT.assets.fullPath,
  ROUTES_ROOT.funds.fullPath,
  ROUTES_ROOT.settings.report.fullPath,
  ROUTES_ROOT.useDesktopVersion.fullPath,
  'report/dashboards',
  'report/comparison-mode-dashboards',
  ROUTES_ROOT.scoreboards.fullPath,
  ROUTES_ROOT.documentation.fullPath,
  ROUTES_ROOT.reportBuilderTemplates.fullPath,
] as const;

export const PAGES_WITH_SMALL_SCREEN_SUPPORT = [
  'mobile',
  ROUTES_ROOT.useDesktopVersion.fullPath,
] as const;

/**
 * FE-3364
 * Rum Isolation from unused/stale css rules.
 */
export const RUM_ISOLATION_CLASSNAME = 'rum-isolation';
