import { stringifySearchParams } from '@/lib/stringify';
import { NavigateOptions, useLocation, useNavigate } from '@reach/router';
import type QueryString from 'qs';
import { useMemo } from 'react';

type SetURLSearchParams<S> = (
  nextInit?: URLSearchParams | ((prev: URLSearchParams) => URLSearchParams),
  navigateOpts?: NavigateOptions<S>,
  stringifyOptions?: QueryString.IStringifyOptions,
) => void;

/**
 * Works similar to [React Router's useSearchParams](https://reactrouter.com/en/main/hooks/use-search-params)
 */
export const useSearchParams = <S extends object>() => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const setSearchParams: SetURLSearchParams<S> = (
    nextInit,
    navigateOpts,
    stringifyOptions,
  ) => {
    if (nextInit == null) return;
    const newUrlSearchParams = new URLSearchParams(
      typeof nextInit === 'function' ? nextInit(searchParams) : nextInit,
    );

    navigate(
      `${location.pathname}${stringifySearchParams(
        newUrlSearchParams,
        stringifyOptions,
      )}`,
      {
        replace: true,
        ...navigateOpts,
      },
    );

    return nextInit;
  };

  return [searchParams, setSearchParams] as const;
};
