import { syncsGeneratedApi } from '@/entities/report/syncs/api/syncsGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Sync');

export const syncsEnhancedApi = syncsGeneratedApi.enhanceEndpoints({
  addTagTypes: ['Syncs'],
  endpoints: {
    getApiCustomerBridgeSyncs: {
      providesTags: ['Syncs'],
    },
    postApiCustomerBridgeSyncs: {
      invalidatesTags: ['Syncs'],
      transformResponse: (r) => {
        toastr.success(t.create().toString());
        return r;
      },
    },
  },
});
