import { IconButton } from '@/stories';
import type { ComponentProps } from 'react';

export const ExpandCollapseRowsIconButton = ({
  includeSourceUnitTypes = true,
  onClick,
  ...iconButtonProps
}: {
  includeSourceUnitTypes: boolean | undefined;
} & Partial<Omit<ComponentProps<typeof IconButton>, 'onClick'>> & {
    onClick: (newIncludeSourceUnitTypes: boolean) => void;
  }) => (
  <IconButton
    {...iconButtonProps}
    tooltipProps={{
      mainText: includeSourceUnitTypes ? 'Collapse rows' : 'Expand rows',
    }}
    iconName={includeSourceUnitTypes ? 'collapseRows' : 'expandRows'}
    onClick={() => {
      onClick(!includeSourceUnitTypes);
    }}
  />
);
