import {
  OBJECT_DASHBOARD_SECTION_TYPE,
  ReportObjectDashboardSection,
  UnitTypeTableWidgetConfigDto,
} from '@/bundles/Shared/entities/dashboard';

export type UnitTypeTableWidgetSection = ReportObjectDashboardSection & {
  widgetType: typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE;
  widgetConfig: UnitTypeTableWidgetConfigDto;
  defaultOptions: {
    date: DateString;
  };
};

export type UnitTypeTableWidgetConfigColumn =
  UnitTypeTableWidgetConfigDto['columns'][number];

export const isUnitTypeTableWidgetSection = (
  section: ReportObjectDashboardSection,
): section is UnitTypeTableWidgetSection =>
  section.widgetType === OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE;
