import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsReportBuilderGotenbergTemplatesById: build.query<
      GetApiSettingsReportBuilderGotenbergTemplatesByIdApiResponse,
      GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/gotenberg/templates/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    postApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluate:
      build.query<
        PostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateApiResponse,
        PostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/gotenberg/widget_sections/${queryArg.id}/evaluate`,
          method: 'POST',
          body: queryArg.body,
          headers: { Authorization: queryArg.authorization },
        }),
      }),
    getApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSections:
      build.query<
        GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiResponse,
        GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/copyable_widget_sections`,
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroup:
      build.mutation<
        PostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupApiResponse,
        PostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/copyable_widget_sections/copy_to_group`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSections:
      build.mutation<
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsApiResponse,
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
      build.query<
        GetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
        GetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections/${queryArg.id}`,
        }),
      }),
    putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
      build.mutation<
        PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
        PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
      build.mutation<
        DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse,
        DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreview:
      build.query<
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewApiResponse,
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections/preview`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluate:
      build.query<
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateApiResponse,
        PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.groupId}/widget_sections/${queryArg.id}/evaluate`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdGroups: build.mutation<
      PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsApiResponse,
      PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId: build.mutation<
      PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiResponse,
      PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId:
      build.mutation<
        DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiResponse,
        DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/groups/${queryArg.id}`,
          method: 'DELETE',
        }),
      }),
    getApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSections:
      build.query<
        GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiResponse,
        GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/object_dashboards/copyable_widget_sections`,
        }),
      }),
    postApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroup:
      build.mutation<
        PostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupApiResponse,
        PostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/report_builder/templates/${queryArg.templateId}/object_dashboards/copyable_widget_sections/copy_to_group`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    getApiSettingsReportBuilderTemplates: build.query<
      GetApiSettingsReportBuilderTemplatesApiResponse,
      GetApiSettingsReportBuilderTemplatesApiArg
    >({
      query: () => ({ url: `/api/settings/report_builder/templates` }),
    }),
    postApiSettingsReportBuilderTemplates: build.mutation<
      PostApiSettingsReportBuilderTemplatesApiResponse,
      PostApiSettingsReportBuilderTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportBuilderTemplatesById: build.query<
      GetApiSettingsReportBuilderTemplatesByIdApiResponse,
      GetApiSettingsReportBuilderTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.id}`,
      }),
    }),
    putApiSettingsReportBuilderTemplatesById: build.mutation<
      PutApiSettingsReportBuilderTemplatesByIdApiResponse,
      PutApiSettingsReportBuilderTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiSettingsReportBuilderTemplatesById: build.mutation<
      DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
      DeleteApiSettingsReportBuilderTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiSettingsReportBuilderTemplatesByIdDuplicate: build.mutation<
      PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse,
      PostApiSettingsReportBuilderTemplatesByIdDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.id}/duplicate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsReportBuilderTemplatesMeta: build.query<
      GetApiSettingsReportBuilderTemplatesMetaApiResponse,
      GetApiSettingsReportBuilderTemplatesMetaApiArg
    >({
      query: () => ({ url: `/api/settings/report_builder/templates/meta` }),
    }),
    getApiSettingsReportBuilderTemplatesByIdPreviewPdf: build.query<
      GetApiSettingsReportBuilderTemplatesByIdPreviewPdfApiResponse,
      GetApiSettingsReportBuilderTemplatesByIdPreviewPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/report_builder/templates/${queryArg.id}/preview_pdf`,
        params: { asset_id: queryArg.assetId, date: queryArg.date },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsReportBuilderTemplatesGeneratedApi };
export type GetApiSettingsReportBuilderGotenbergTemplatesByIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto;
export type GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg = {
  authorization: string;
  id: string;
};
export type PostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | ReportBuilderKpiCardWidgetDto
      | ReportBuilderTextAreaWidgetDto
      | ReportBuilderMediaWidgetDto
      | ReportBuilderUnitMixTableWidgetDto
      | ReportBuilderUnitMixSummaryTableWidgetDto
      | ReportBuilderUnitTypeTableWidgetDto
      | ReportBuilderFinancialTableSingleDateWidgetDto
      | ReportBuilderFinancialTableSinglePeriodWidgetDto
      | ReportBuilderXyChartWidgetDto
      | ReportBuilderRecentRentRollTableWidgetDto
      | ReportBuilderDelinquencyTrackerTableWidgetDto
      | ReportBuilderHistoricalReviewTableWidgetDto;
  };
export type PostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateApiArg =
  {
    authorization: string;
    id: string;
    body: {
      asset_id: number;
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
        period?: ReportScoreboardPeriodDto;
      };
    };
  };
export type GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupCopyableWidgetSectionDto[];
export type GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiArg =
  {
    templateId: string;
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupApiResponse =
  unknown;
export type PostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupApiArg =
  {
    templateId: string;
    body: {
      sections_to_copy_ids: string[];
      group_id: string;
    };
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupWidgetSectionDto;
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsApiArg =
  {
    templateId: string;
    groupId: string;
    body: {
      widget_type?:
        | 'text_area'
        | 'media'
        | 'kpi_card'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'unit_type_table'
        | 'financial_table_single_date'
        | 'financial_table_single_period'
        | 'xy_chart'
        | 'recent_rent_roll_table'
        | 'delinquency_tracker_table'
        | 'historical_review_table';
      position?: number;
      config?: object;
      width_percent: number;
      title?: string;
      font_size?: number;
    };
  };
export type GetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupWidgetSectionDto;
export type GetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg =
  {
    templateId: string;
    groupId: string;
    id: string;
  };
export type PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupWidgetSectionDto;
export type PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg =
  {
    templateId: string;
    groupId: string;
    id: string;
    body: {
      position?: number;
      config?: object;
      width_percent?: number;
      title?: string;
      font_size?: number;
      destination_group_id?: string;
    };
  };
export type DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiResponse =
  unknown;
export type DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdApiArg =
  {
    templateId: string;
    groupId: string;
    id: string;
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | ReportBuilderKpiCardWidgetDto
      | ReportBuilderTextAreaWidgetDto
      | ReportBuilderMediaWidgetDto
      | ReportBuilderUnitMixTableWidgetDto
      | ReportBuilderUnitMixSummaryTableWidgetDto
      | ReportBuilderUnitTypeTableWidgetDto
      | ReportBuilderFinancialTableSingleDateWidgetDto
      | ReportBuilderFinancialTableSinglePeriodWidgetDto
      | ReportBuilderXyChartWidgetDto
      | ReportBuilderRecentRentRollTableWidgetDto
      | ReportBuilderDelinquencyTrackerTableWidgetDto
      | ReportBuilderHistoricalReviewTableWidgetDto;
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewApiArg =
  {
    templateId: string;
    groupId: string;
    body: {
      asset_id: number;
      widget_type:
        | 'text_area'
        | 'media'
        | 'kpi_card'
        | 'unit_mix_table'
        | 'unit_mix_summary_table'
        | 'unit_type_table'
        | 'financial_table_single_date'
        | 'financial_table_single_period'
        | 'xy_chart'
        | 'recent_rent_roll_table'
        | 'delinquency_tracker_table'
        | 'historical_review_table';
      config:
        | ReportBuilderKpiCardWidgetConfigDto
        | ReportBuilderUnitMixSummaryTableWidgetConfigDto
        | ReportBuilderUnitTypeTableWidgetConfigDto
        | ReportBuilderFinancialTableSingleDateWidgetConfigDto
        | ReportBuilderFinancialTableSinglePeriodWidgetConfigDto
        | ReportBuilderHistoricalReviewTableWidgetConfigDto
        | ReportBuilderTextAreaWidgetConfigDto
        | ReportBuilderXyChartWidgetConfigDto;
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
        period?: ReportScoreboardPeriodDto;
      };
    };
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | ReportBuilderKpiCardWidgetDto
      | ReportBuilderTextAreaWidgetDto
      | ReportBuilderMediaWidgetDto
      | ReportBuilderUnitMixTableWidgetDto
      | ReportBuilderUnitMixSummaryTableWidgetDto
      | ReportBuilderUnitTypeTableWidgetDto
      | ReportBuilderFinancialTableSingleDateWidgetDto
      | ReportBuilderFinancialTableSinglePeriodWidgetDto
      | ReportBuilderXyChartWidgetDto
      | ReportBuilderRecentRentRollTableWidgetDto
      | ReportBuilderDelinquencyTrackerTableWidgetDto
      | ReportBuilderHistoricalReviewTableWidgetDto;
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdEvaluateApiArg =
  {
    templateId: string;
    groupId: string;
    id: string;
    body: {
      asset_id: number;
      context: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
        period?: ReportScoreboardPeriodDto;
      };
    };
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupDto;
export type PostApiSettingsReportBuilderTemplatesByTemplateIdGroupsApiArg = {
  templateId: string;
  body: {
    page_orientation: 'portrait' | 'landscape';
  };
};
export type PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateGroupDto;
export type PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg =
  {
    templateId: string;
    id: string;
    body: {
      page_orientation?: 'portrait' | 'landscape';
      position?: number;
      display_header?: boolean | null;
    };
  };
export type DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiResponse =
  unknown;
export type DeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg =
  {
    templateId: string;
    id: string;
  };
export type GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiResponse =
  /** status 200 success */ ReportBuilderObjectDashboardsCopyableWidgetSectionDto[];
export type GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiArg =
  {
    templateId: string;
  };
export type PostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupApiResponse =
  unknown;
export type PostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupApiArg =
  {
    templateId: string;
    body: {
      sections_to_copy_ids: string[];
      group_id: string;
    };
  };
export type GetApiSettingsReportBuilderTemplatesApiResponse =
  /** status 200 success */ {
    items: ReportBuilderTemplatePreviewDto[];
    meta: {
      tags: ReportBuilderTemplateTagDto[];
    };
  };
export type GetApiSettingsReportBuilderTemplatesApiArg = void;
export type PostApiSettingsReportBuilderTemplatesApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto;
export type PostApiSettingsReportBuilderTemplatesApiArg = {
  body: {
    name: string;
    tags?: string[];
  };
};
export type GetApiSettingsReportBuilderTemplatesByIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto;
export type GetApiSettingsReportBuilderTemplatesByIdApiArg = {
  id: string;
};
export type PutApiSettingsReportBuilderTemplatesByIdApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto;
export type PutApiSettingsReportBuilderTemplatesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    tags?: string[];
    displayDate?: boolean;
    displayPageNumber?: boolean;
    displayHeader?: boolean;
  };
};
export type DeleteApiSettingsReportBuilderTemplatesByIdApiResponse = unknown;
export type DeleteApiSettingsReportBuilderTemplatesByIdApiArg = {
  id: string;
};
export type PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse =
  /** status 200 success */ ReportBuilderTemplateDto;
export type PostApiSettingsReportBuilderTemplatesByIdDuplicateApiArg = {
  id: string;
  body: {
    name?: string;
  };
};
export type GetApiSettingsReportBuilderTemplatesMetaApiResponse =
  /** status 200 success */ {
    assets: {
      id: number;
      name: string;
    }[];
    tags: {
      id: string;
      name: string;
    }[];
  };
export type GetApiSettingsReportBuilderTemplatesMetaApiArg = void;
export type GetApiSettingsReportBuilderTemplatesByIdPreviewPdfApiResponse =
  /** status 200 success */ string;
export type GetApiSettingsReportBuilderTemplatesByIdPreviewPdfApiArg = {
  id: string;
  assetId: string;
  date: string;
};
export type ReportBuilderTemplateTagDto = {
  id: string;
  name: string;
};
export type TrailingPeriodTypeDto = {
  type: 'day' | 'month' | 'quarter';
  amount: number;
};
export type ReportBuilderKpiCardWidgetConfigDto = {
  kpis: {
    key: number;
    label: string;
    expression: string;
    value_display_options: {
      type?:
        | 'number'
        | 'percentage'
        | 'currency'
        | 'metric'
        | 'sqft'
        | 'bps'
        | 'date';
      precision?: 0 | 1 | 2;
      kilo_formatting?: boolean;
      hide_comma_separator?: boolean;
    };
    period_type:
      | (
          | 'day'
          | 'week'
          | 'month'
          | 'quarter'
          | 'qtd'
          | 'year'
          | 'ytd'
          | 't3'
          | 't6'
          | 't12'
        )
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
};
export type ReportBuilderUnitMixSummaryTableWidgetConfigDto = {
  header_background: string;
};
export type WidgetColumnComparisonDto = {
  to_col_id?: string;
  rule?:
    | 'negative_red_positive_green'
    | 'negative_green_positive_red'
    | 'negative_red';
  hide_negative_values?: boolean;
};
export type ReportBuilderUnitTypeTableWidgetConfigDto = {
  viz_type: string;
  viz_config: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options: {
        type?:
          | 'number'
          | 'percentage'
          | 'currency'
          | 'metric'
          | 'sqft'
          | 'bps'
          | 'date';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
        date_format?:
          | 'YYYY'
          | 'MM'
          | 'MMM'
          | 'MM/DD/YYYY'
          | 'MMMM D, YYYY'
          | 'MMM D, YYYY'
          | 'Relative';
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_floorplan_rows?: boolean;
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    period_type:
      | ('day' | 'week' | 'month' | 'mtd' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    unit_type_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
    total_calculation_strategy?: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type ValueDisplayOptionsConfigDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kilo_formatting: boolean;
  hide_comma_separator?: boolean;
  date_format?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type VizConfigBackgroundDto = {
  background?:
    | {
        type: 'gradient';
        ignore_zeros?: boolean;
        thresholds: {
          type: 'min' | 'max' | 'number' | 'percentage' | 'percentile';
          value?: number;
        }[];
      }
    | {
        type: 'color';
        color: string;
      };
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type BaseFinancialTableWidgetConfigBaseColumnDto = {
  key: number;
  label: string;
  sub_label?: string;
  hide_sub_label?: boolean;
  adjustment_expression_name?:
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_budget'
    | 'financial_t1a'
    | 'financial_t3a'
    | 'annualized'
    | 'op_budget_annualized'
    | 'uw_budget_annualized'
    | 'period_to_period_variance'
    | 'period_to_period_variance_percent'
    | 'year_to_year_variance'
    | 'year_to_year_variance_percent';
  adjustment_average_per?:
    | 'unit'
    | 'unit_occupied'
    | 'unit_vacant'
    | 'unit_available'
    | 'unit_preleased'
    | 'sqft';
  value_display_options_override?: ValueDisplayOptionsConfigDto;
  period_shift?: {
    months?: number;
    quarters?: number;
    years?: number;
  };
};
export type BaseFinancialTableWidgetConfigRowBaseRowDto = {
  path: string;
  label?: string;
  children?: BaseFinancialTableWidgetConfigRowDto[];
  display_options?: {
    font_weight?: string;
    color?: string;
    background?: string;
  };
  value_display_options?: ValueDisplayOptionsConfigDto;
};
export type BaseFinancialTableWidgetConfigRowDto =
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'blank';
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'formula';
      expression: string;
      negate_variance?: boolean;
      adjustment_expression_source_overrides?: {
        source: 'budget' | 'underwriting_budget';
        override_to: string;
      }[];
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'category';
      category_path: string;
      hide_general_ledgers?: boolean;
    });
export type ReportBuilderFinancialTableSingleDateWidgetConfigDto = {
  hide_header?: boolean;
  header_background?: string;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: (BaseFinancialTableWidgetConfigBaseColumnDto & {
    period_type:
      | ('month' | 'quarter' | 'year' | 'mtd' | 'qtd' | 'ytd' | 'itd')
      | TrailingPeriodTypeDto;
  })[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type ReportBuilderFinancialTableSinglePeriodWidgetConfigDto = {
  period_types: ('t3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd')[];
  hide_header?: boolean;
  header_background?: string;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: BaseFinancialTableWidgetConfigBaseColumnDto[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
    period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd';
  };
};
export type AdjustmentExpressionDto = {
  name:
    | 'annualized'
    | 'op_budget_annualized'
    | 'uw_budget_annualized'
    | 'financial_t1a'
    | 'financial_t3a'
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_variance_flipped'
    | 'op_variance_flipped_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_variance_flipped'
    | 'uw_variance_flipped_percent'
    | 'uw_budget'
    | 'period_to_period_variance'
    | 'period_to_period_variance_percent'
    | 'period_to_period_variance_flipped'
    | 'period_to_period_variance_flipped_percent'
    | 'year_to_year_variance'
    | 'year_to_year_variance_percent'
    | 'year_to_year_variance_flipped'
    | 'year_to_year_variance_flipped_percent';
  source_overrides?: {
    source: 'budget' | 'underwriting_budget';
    override_to: string;
  }[];
};
export type ReportBuilderHistoricalReviewTableWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    rows: {
      key: string;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    columns: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      initial_sort?: string;
      hidden?: boolean;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string;
    }[];
    column_groups: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
  };
  columns: {
    key: number;
    label: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  rows: {
    key: number;
    label: string;
    expression: string;
    value_display_options?: ValueDisplayOptionsConfigDto;
    adjustment_expression?: AdjustmentExpressionDto;
  }[];
  default_options?: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type ReportBuilderTextAreaWidgetConfigDto = {
  text: string;
};
export type ReportBuilderXyChartWidgetConfigDto = {
  kpis: {
    key: number;
    expression: string;
  }[];
  granularities: ('day' | 'week' | 'month')[];
  default_options: {
    granularity: 'day' | 'week' | 'month';
    date_from: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
  };
  am_chart_config: object;
};
export type ReportBuilderTemplateGroupWidgetSectionDto = {
  id: string;
  widgetType:
    | 'kpi_card'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'unit_type_table'
    | 'financial_table_single_date'
    | 'financial_table_single_period'
    | 'xy_chart'
    | 'recent_rent_roll_table'
    | 'delinquency_tracker_table'
    | 'historical_review_table'
    | 'text_area'
    | 'media';
  position: {
    y: number;
    w: number;
  };
  title: string | null;
  fontSize: number | null;
  widgetConfig:
    | (
        | ReportBuilderKpiCardWidgetConfigDto
        | ReportBuilderUnitMixSummaryTableWidgetConfigDto
        | ReportBuilderUnitTypeTableWidgetConfigDto
        | ReportBuilderFinancialTableSingleDateWidgetConfigDto
        | ReportBuilderFinancialTableSinglePeriodWidgetConfigDto
        | ReportBuilderHistoricalReviewTableWidgetConfigDto
        | ReportBuilderTextAreaWidgetConfigDto
        | ReportBuilderXyChartWidgetConfigDto
      )
    | null;
  defaultOptions?: {
    [key: string]: any;
  } | null;
};
export type ReportBuilderTemplateGroupDto = {
  id: string;
  pageOrientation: 'portrait' | 'landscape';
  position: number;
  displayHeader: boolean | null;
  widgetSections: ReportBuilderTemplateGroupWidgetSectionDto[];
};
export type ReportBuilderAssetDto = {
  id: number;
  name: string;
  slug: string;
};
export type ReportBuilderTemplateDto = {
  id: string;
  name: string;
  displayDate: boolean;
  displayPageNumber: boolean;
  displayHeader: boolean;
  templateTags: ReportBuilderTemplateTagDto[];
  groups: ReportBuilderTemplateGroupDto[];
  assets?: ReportBuilderAssetDto[];
};
export type ValueDisplayOptionsDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
  dateFormat?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type ReportBuilderKpiCardWidgetDto = {
  widgetType: 'kpi_card';
  kpis: {
    key: number;
    label: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
    value: number | null;
  }[];
};
export type ReportBuilderTextAreaWidgetDto = {
  widgetType: 'text_area';
  text: string;
};
export type ReportBuilderMediaWidgetDto = {
  widgetType: 'media';
  items: {
    id: string;
    type: 'shared_file';
    dataUri: string;
  }[];
};
export type ReportBuilderUnitMixTableWidgetDto = {
  widgetType: 'unit_mix_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type ReportBuilderUnitMixSummaryTableWidgetDto = {
  widgetType: 'unit_mix_summary_table';
  headerBackground: string | null;
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  };
};
export type ReportBuilderUnitTypeTableWidgetDto = {
  type?: 'unit_type_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: {
    type: 'unit_type' | 'source_unit_type' | 'total';
    label: string;
    path: string;
    additionalProperties?: number;
  }[];
};
export type ReportBuilderFinancialTableSingleDateWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportBuilderFinancialTableSingleDateWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string;
    value: number | null;
  }[];
};
export type ReportBuilderFinancialTableSingleDateWidgetDto = {
  widgetType: 'financial_table_single_date';
  hideHeader: boolean;
  headerBackground: string | null;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (ReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'total';
      })
    | (ReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (ReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (ReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'blank';
      })
    | (ReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'formula';
      })
    | (ReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'category';
      })
    | (ReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type ReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type ReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string;
    value: number | null;
  }[];
};
export type ReportBuilderFinancialTableSinglePeriodWidgetDto = {
  widgetType: 'financial_table_single_period';
  hideHeader: boolean;
  headerBackground: string | null;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (ReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'total';
      })
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'blank';
      })
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'formula';
      })
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'category';
      })
    | (ReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type ReportBuilderXyChartWidgetDto = {
  widgetType: 'xy_chart';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type ReportBuilderRecentRentRollTableWidgetDto = {
  widgetType: 'recent_rent_roll_table';
  columns: {
    key: number;
    label: string;
    shortLabel?: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    values: {
      key: number;
      value: number | null;
      withFallback: boolean;
    }[];
    label: string;
    path: string;
  }[];
  total: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  psf: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  latestParsings?: {
    legalEntityId: string;
    date: string;
  }[];
};
export type ReportBuilderDelinquencyTrackerTableWidgetDto = {
  widgetType: 'delinquency_tracker_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type ReportBuilderHistoricalReviewTableWidgetDto = {
  widgetType: 'historical_review_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: object[];
};
export type ReportScoreboardPeriodDto =
  | {
      type: 'day';
      date: string;
    }
  | {
      type: 'week';
      date: string;
    }
  | {
      type: 'month';
      date: string;
    }
  | {
      type: 'quarter';
      date: string;
    }
  | {
      type: 'year';
      date: string;
    }
  | {
      type: 'dtd';
      from_date: string;
      to_date: string;
    }
  | {
      type: 'mtm';
      from_date: string;
      to_date: string;
    }
  | {
      type: 'mtd';
      last_date: string;
    }
  | {
      type: 'qtd';
      last_date: string;
    }
  | {
      type: 'ytd';
      last_date: string;
    }
  | {
      type: 'attd';
      last_date: string;
    }
  | {
      type: 'itd';
      last_date: string;
    }
  | {
      type: 'trailing_days';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_months';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_quarters';
      date: string;
      count: number;
    }
  | {
      type: 'trailing_years';
      date: string;
      count: number;
    }
  | {
      type: 't3';
      date: string;
    }
  | {
      type: 't6';
      date: string;
    }
  | {
      type: 't12';
      date: string;
    };
export type ReportBuilderTemplateGroupCopyableWidgetSectionDto = {
  id: string;
  title: string | null;
  widgetType:
    | 'kpi_card'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'financial_table_single_date'
    | 'financial_table_single_period'
    | 'xy_chart'
    | 'recent_rent_roll_table'
    | 'delinquency_tracker_table'
    | 'historical_review_table'
    | 'text_area'
    | 'media';
  position: number;
  groupPosition: number;
  template: {
    id: string;
    name: string;
  };
};
export type ReportScoreboardWidgetSectionPositionDto = {
  x: number;
  y: number;
  w: number;
  h: number;
  maxW: number;
  maxH: number;
  minW: number;
  minH: number;
};
export type ReportBuilderObjectDashboardsCopyableWidgetSectionDto = {
  id: string;
  title: string | null;
  widgetType:
    | 'kpi_card'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'financial_table_single_date'
    | 'financial_table_single_period'
    | 'xy_chart'
    | 'recent_rent_roll_table'
    | 'delinquency_tracker_table'
    | 'historical_review_table'
    | 'text_area'
    | 'media';
  position: ReportScoreboardWidgetSectionPositionDto;
  board: {
    id: string;
    name: string;
    order: number;
    dashboard: {
      id: string;
      name: string;
      type: 'object_level';
    };
  };
};
export type ReportBuilderTemplatePreviewDto = {
  id: string;
  name: string;
  templateTags: ReportBuilderTemplateTagDto[];
  assets: ReportBuilderAssetDto[];
};
