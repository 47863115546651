import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import {
  selectReportObjectDashboardMetadataById,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import { ReportDashboardDateFilterBlock } from 'bundles/Shared/features/dashboard/object/filter/byDate';

export const SettingsReportComparisonDashboardDateFilter = () => {
  const { dashboardId } = useDashboardContext();
  const dashboardState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
};

export function SettingsReportObjectDashboardDateFilter() {
  const { dashboardId } = useDashboardContext();
  const dashboardState = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(state, dashboardId ?? ''),
  );
  const { date } = dashboardState ?? {};

  return (
    <ReportDashboardDateFilterBlock
      dashboardId={dashboardId}
      date={date ?? null}
    />
  );
}
