import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import {
  DashboardEmptyPermissions,
  ReportDashboardType,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';
import { useUpdateDashboardPermissions } from 'bundles/Shared/features/dashboard/updatePermissions';
import { TProductNames } from 'lib/permissions';
import React from 'react';

type Props = Pick<
  React.ComponentProps<typeof PermissionListLine>,
  'permissions' | 'className' | 'emptyFallback'
> & {
  dashboardId: ReportEagleEyeDashboard['id'];
  dashboardType: ReportDashboardType;
  withEdit?: boolean;
};

export function DashboardPermissionListLine({
  dashboardId,
  dashboardType,
  withEdit,
  emptyFallback,
  ...props
}: Props) {
  const updatePermissions = useUpdateDashboardPermissions(dashboardType);
  const handleEditPermissions = () => updatePermissions(dashboardId);

  return (
    <PermissionListLine
      productName={TProductNames.REPORT}
      hidePublicIcon
      onEdit={withEdit ? handleEditPermissions : undefined}
      onClick={handleEditPermissions}
      popoverProps={{
        placement: 'bottom',
      }}
      emptyFallback={
        emptyFallback ?? (
          <DashboardEmptyPermissions onClick={handleEditPermissions} />
        )
      }
      {...props}
    />
  );
}
