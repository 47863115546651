import { cn } from '@/shared/lib/css/cn';
import { Link, LinkGetProps, LinkProps } from '@reach/router';
import React from 'react';
import { EmptyObject } from 'type-fest';
import { ClassNameProps } from 'types/Props';
import { isActiveOnHomepage } from '@/pages/HomePage';

interface Props extends ClassNameProps {
  label: string;
  path: string;
  onLinkClick?: LinkProps<EmptyObject>['onClick'];
  isActive?: LinkProps<EmptyObject>['getProps'];
  rightIcon?: React.ReactNode;
  linkProps?: Omit<
    LinkProps<EmptyObject>,
    'to' | 'onClick' | 'getProps' | 'ref'
  >;
}

const SecondaryMenuItem = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      label,
      path,
      onLinkClick,
      isActive: isActiveExternal,
      className,
      rightIcon,
      linkProps,
    }: Props,
    ref,
  ) => {
    const getProps = (linkGetProps: LinkGetProps) => ({
      className: cn(
        'left-sidebar__second-menu-item',
        {
          'left-sidebar__second-menu-item_active':
            (isActiveExternal
              ? isActiveExternal(linkGetProps)
              : linkGetProps.isCurrent) ||
            (linkGetProps.location.pathname === '/' &&
              isActiveOnHomepage(path)),
        },
        className,
      ),
    });

    return (
      <Link
        ref={ref}
        to={path}
        onClick={onLinkClick}
        getProps={getProps}
        {...linkProps}
      >
        <div className="flex items-center justify-between">
          <div className="flex w-full items-center px-tw-2 py-tw-1">
            <div className="left-sidebar__icon-container">
              <div className="left-sidebar__second-menu-icon" />
            </div>
            <span className="left-sidebar__second-menu-label inline-regular ml-tw-2">
              {label}
            </span>
          </div>
          <div className="left-sidebar__menu-item-expand">{rightIcon}</div>
        </div>
      </Link>
    );
  },
);

export default SecondaryMenuItem;
