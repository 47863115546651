import { ExportSettingsField } from '@/bundles/Shared/features/dashboard/exportSettings/ui/ExportSettingsField';
import { replaceTemplateWithReportLabel } from '@/bundles/Shared/features/dashboard/exportSettings/utils';
import type {
  ReportObjectDashboardDto,
  ReportObjectDashboardMetaDto,
} from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { AvailableTemplatesModal } from '@/bundles/Shared/widgets/report/widgets';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation } from '@/entities/report/reportBuilder';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Button } from '@/stories';
import { SectionField } from '@/stories/Field/FieldsWrappers';
import { useLocation, useNavigate } from '@reach/router';

export const useDashboardExportSettingsFeature = ({
  dashboardId,
  dashboardUpdateMutation,
  dashboardReportBuilderTemplate,
  reportBuilderTemplates,
}: {
  dashboardId: string;
  dashboardReportBuilderTemplate: {
    id: string;
    name: string;
  } | null;
  dashboardUpdateMutation: readonly [
    (args: {
      id: string;
      body: {
        report_builder_template_report_scoreboard_attributes?: {
          template_id?: string;
          _destroy?: boolean;
        };
      };
    }) => void,
    { isLoading: boolean },
  ];
  reportBuilderTemplates?: ReportObjectDashboardMetaDto['reportBuilderTemplates'];
}) => {
  const { openModal, confirm } = useModal();
  const [dashboardUpdate, { isLoading }] = dashboardUpdateMutation;

  const handleRemoveReportBuilderTempalte = async () => {
    if (dashboardReportBuilderTemplate == null) return;

    const answer = await confirm({
      subtitle:
        'Are you sure to want to remove linked Report Builder Template from this dashboard',
    });

    if (!answer) return;

    dashboardUpdate({
      id: dashboardId,
      body: {
        report_builder_template_report_scoreboard_attributes: {
          template_id: dashboardReportBuilderTemplate.id,
          _destroy: true,
        },
      },
    });
  };

  const handleReportBuilderTemplateChange = async () => {
    if (reportBuilderTemplates == null) return;

    const selectedTemplate = await openModal(AvailableTemplatesModal, {
      isFetching: false,
      reportBuilderTemplates,
    });

    if (selectedTemplate == null) return;

    dashboardUpdate({
      id: dashboardId,
      body: {
        report_builder_template_report_scoreboard_attributes: {
          template_id: selectedTemplate.id,
        },
      },
    });
  };

  return {
    ExportSettingsField: () =>
      reportBuilderTemplates ? (
        <SectionField
          labelText="Export settings"
          tooltipProps={{
            mainText:
              'You can link this dashboard to a Pdf template to quickly export the dashboard  ',
          }}
        >
          {dashboardReportBuilderTemplate == null ? (
            <Button
              size="xs"
              className="w-max"
              iconName="link"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleReportBuilderTemplateChange}
            >
              Link PDF template
            </Button>
          ) : (
            <ExportSettingsField
              isLoading={isLoading}
              onChange={handleReportBuilderTemplateChange}
              onRemove={handleRemoveReportBuilderTempalte}
              label={dashboardReportBuilderTemplate.name}
            />
          )}
        </SectionField>
      ) : null,
  };
};

export const useDashboardExportPDFFeature = ({
  assetId,
  date,
  reportBuilderTemplate,
}: {
  assetId: string | number | null;
  reportBuilderTemplate: ReportObjectDashboardDto['reportBuilderTemplate'];
  date: DateString | undefined;
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [createReport, { isLoading }] =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation();

  const handleNavigateToReportBuilder = async () => {
    if (reportBuilderTemplate == null || assetId == null || date == null)
      return;

    const response = await createReport({
      assetId: String(assetId),
      body: {
        date,
        name: replaceTemplateWithReportLabel(reportBuilderTemplate.name),
        template_id: reportBuilderTemplate.id,
      },
    });

    if ('error' in response) {
      return toastr.error(JSON.stringify(response.error));
    }
    navigate(
      generateUrl(ROUTES_ROOT.reports.report.fullPath, {
        pathParams: {
          id: response.data.id,
        },
        queryParams: {
          assetId,
        },
      }),
      {
        state: { from: `${pathname}${search}` },
      },
    );
  };

  return {
    Button: () =>
      reportBuilderTemplate == null ||
      assetId == null ||
      date == null ? null : (
        <Button
          onClick={handleNavigateToReportBuilder}
          size="s"
          iconName="docPdf"
          disabled={isLoading}
          loading={isLoading}
        >
          Export PDF
        </Button>
      ),
  };
};
