import { FormulaInUse } from '@/bundles/Shared/entities/formula';
import { TemplateAssetsInUse } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateAssetsInUse';
import type React from 'react';

export const AssetsInUsePopover = (
  props: React.ComponentProps<typeof TemplateAssetsInUse>,
) => {
  return (
    <div className="secondary-regular flex items-center gap-tw-1">
      <FormulaInUse.UsedSvg used className="h-tw-4 w-tw-4 min-w-tw-4" />
      <p className="text-neutral-650">In use:</p>
      <div className="font-semibold">
        <TemplateAssetsInUse {...props} />
      </div>
    </div>
  );
};
