import ErrorBoundary from '@/app/ui/ErrorBoundary';
import { SettingsReportWidgetConfigEditor } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/Widget/SettingsReportDashboardWidgetConfigEditor';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { WidgetFlyPanel } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import {
  selectReportBuilderTemplateMetadataById,
  updateReportBuilderTemplateWidgetState,
} from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { ReportDashboardAssetFilter } from '@/bundles/Shared/features/dashboard/object/filter/byAsset/ui/ReportDashboardAssetFilter';
import { ReportDashboardDateFilterBlock } from '@/bundles/Shared/features/dashboard/object/filter/byDate';
import { useUpdateWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { ReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  JsonEditorUI,
  useJsonEditorButtonFeature,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/jsonEditorButtonFeature';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { WidgetErrorBoundary } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { FormulasAndVariablesWorkspace } from '@/bundles/Shared/widgets/formula/panel';
import {
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  useGetApiSettingsReportBuilderTemplatesMetaQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderTemplatesMetaApiResponse,
  ReportBuilderTemplateDto,
  ReportBuilderTemplateGroupWidgetSectionDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { joinWithDash } from '@/shared/lib/string';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AnimationLoader } from '@/stories';
import { type RouteComponentProps } from '@reach/router';
import { ReactComponent as WidgetSvg } from 'bundles/Shared/components/layouts/dashboard/WidgetIcon.svg';
import { useMemo, type FC } from 'react';
import type { UnknownRecord } from 'type-fest';

export const SettingsReportBuilderTemplateWidget = ({
  template,
  widget,
  meta,
}: {
  template: ReportBuilderTemplateDto;
  widget: ReportBuilderTemplateGroupWidgetSectionDto & {
    groupId: string;
  };
  meta: GetApiSettingsReportBuilderTemplatesMetaApiResponse;
}) => {
  const jsonEditoButtonFeature = useJsonEditorButtonFeature();
  const assetId = useAssetIdSearchParam();

  const dispatch = useAppDispatch();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery(
    {
      groupId: widget.groupId,
      id: widget.id,
      templateId: template.id,
    },
  );

  const templateGroupState = useAppSelector((state) =>
    selectReportBuilderTemplateMetadataById(
      state,
      joinWithDash([template.id, widget.groupId]),
    ),
  );
  const templateStateDate = useAppSelector(
    (state) => state.reportBuilderTemplate.entities[template.id]?.date,
  );
  const widgetSectionState = templateGroupState?.widgetsState[widget.id] ?? {};

  const {
    isLoading: isWidgetDataLoading,
    isFetching: isWidgetDataFetching,
    isError: isWidgetDataError,
    data: widgetData,
  } = usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery(
    {
      body: {
        asset_id: Number(assetId),
        config: widget.widgetConfig,
        context: {
          date: formatToDateStringForRequest(
            widgetSectionState.date ?? new Date(),
          ),
          date_from: widgetSectionState.dateFrom,
          date_to: widgetSectionState.dateTo,
          granularity: widgetSectionState.granularity,
        },
        widget_type: widget.widgetType,
      },
      groupId: widget.groupId,
      templateId: template.id,
    },
  );

  const { ConfigComponent, Component, title } =
    WIDGETS_CONFIG_MAP[widget.widgetType];

  const handleStateChange = (newState: UnknownRecord) => {
    dispatch(
      updateReportBuilderTemplateWidgetState({
        id: widget.id,
        groupId: widget.groupId,
        templateId: template.id,
        widgetState: newState,
      }),
    );
  };

  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateContext.Provider
        value={{
          widgetId: widget.id,
          groupId: widget.groupId,
          templateId: template.id,
        }}
      >
        <FormulasAndVariablesWorkspace>
          <DashboardLayout className="h-full">
            <DashboardLayout.Header className="h-auto bg-neutral-000">
              <DashboardLayout.Header.Title
                classes={{
                  subtitle: 'flex items-center gap-tw-2 text-neutral-550',
                }}
                subtitle={template.name}
                title={widget.title}
              />
            </DashboardLayout.Header>
            <DashboardLayout.Body className="relative">
              <DashboardLayout.Grid>
                <jsonEditoButtonFeature.ButtonWrapper>
                  <ReportDashboardAssetFilter
                    assetId={assetId}
                    assets={meta.assets}
                    path={
                      ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate
                        .widgets.widget.fullPath
                    }
                    pathParams={{
                      templateId: template.id,
                      widgetId: widget.id,
                    }}
                  />

                  <ReportDashboardDateFilterBlock
                    date={templateStateDate}
                    dashboardId=""
                  />
                  <jsonEditoButtonFeature.Button />
                </jsonEditoButtonFeature.ButtonWrapper>
                <JsonEditorUI
                  editor={<SettingsReportWidgetConfigEditor />}
                  isCodeEditorOpen={jsonEditoButtonFeature.isCodeEditorOpen}
                >
                  <GrowDiv>
                    <ErrorBoundary
                      fallback={<WidgetErrorBoundary title={widget.title} />}
                    >
                      <Component
                        widgetId={widget.id}
                        className="h-[500px]"
                        widgetSection={widget}
                        context={{}}
                        mode="edit"
                        state={widgetSectionState}
                        onStateChange={handleStateChange}
                        settings={widget.widgetConfig}
                        onSettingsChange={(config) => updateConfig({ config })}
                        data={widgetData?.widget}
                        isLoading={isWidgetDataLoading}
                        isError={isWidgetDataError}
                        isFetching={isWidgetDataFetching}
                      />
                    </ErrorBoundary>
                  </GrowDiv>
                </JsonEditorUI>
              </DashboardLayout.Grid>

              <WidgetFlyPanel className="top-tw-6">
                <WidgetFlyPanel.Header
                  label="Widget: "
                  title={title}
                  icon={<WidgetSvg />}
                />
                {ConfigComponent && <ConfigComponent />}
              </WidgetFlyPanel>
            </DashboardLayout.Body>
          </DashboardLayout>
        </FormulasAndVariablesWorkspace>
      </ReportBuilderTemplateContext.Provider>
    </DashboardContext.Provider>
  );
};
export const SettingsReportBuilderTemplateWidgetPage: FC<
  RouteComponentProps
> = () => {
  const params = useReportBuilderTemplateScreenParams();
  const { data: templateData, isLoading: isTemplateLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery({
      id: params.templateId,
    });
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderTemplatesMetaQuery();
  const isLoading = isMetaDataLoading || isTemplateLoading;

  const widget = useMemo(() => {
    if (templateData == null) return null;

    const allWidgets = templateData.groups.flatMap((g) =>
      g.widgetSections.map((ws) => ({ ...ws, groupId: g.id })),
    );

    return allWidgets.find((w) => w.id === params.widgetId);
  }, [templateData]);

  if (isLoading) {
    return <AnimationLoader className="static min-h-screen" />;
  }

  if (templateData == null || widget == null || metaData == null) {
    return <NoDataOverlay title="Not found" />;
  }
  return (
    <SettingsReportBuilderTemplateWidget
      template={templateData}
      widget={widget}
      meta={metaData}
    />
  );
};
