import { useInView } from 'react-intersection-observer';

export const useWidgetInView = () => {
  return useInView({
    threshold: 0.1,
    delay: 500,
    root: null,
    triggerOnce: true,
  });
};
