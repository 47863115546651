import type { TRootState } from '@/app/stores';
import {
  getInitialStateForReportChartWidget,
  getUpdatedWidgetStateForReportChartWidget,
} from '@/bundles/Shared/entities/dashboard/lib';
import type { LayoutsState } from '@/bundles/Shared/entities/dashboard/model/slices/layouts';
import type { ReportBuilderTemplateWidgetSectionTypes } from '@/bundles/Shared/entities/dashboard/model/types';
import type { ReportBuilderTemplateWidgetStateMap } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { REPORT_BUILDER_WIDGET_TYPES } from '@/entities/report/reportBuilder';
import { settingsReportBuilderTemplatesEnhancedApi as api } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftDateBackward, yesterdayDate } from '@/shared/lib/date';
import { joinWithDash } from '@/shared/lib/string';
import {
  createAction,
  createEntityAdapter,
  createSlice,
  isAnyOf,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit';
import type { Dayjs } from 'dayjs';
import type { UnknownRecord } from 'type-fest';

type WidgetsState = Record<
  string,
  {
    type: ReportBuilderTemplateWidgetSectionTypes;
  } & ReportBuilderTemplateWidgetStateMap[keyof ReportBuilderTemplateWidgetStateMap]
>;

export type ReportBuilderTemplateState = LayoutsState<{
  id: string;
  date: string;
  widgetsState: WidgetsState;
}>;

const resolveReportBuilderTemplateDefaultOptionsDate = (
  defaultOptionsDate?: string | null,
): DateString => {
  return formatToDateStringForRequest(
    defaultOptionsDate
      ? shiftDateBackward(new Date(defaultOptionsDate), 1, 'day')
      : yesterdayDate(),
  );
};

export const getInitialReportBuilderTemplateWidgetState = (
  widgetSection: ReportBuilderTemplateGroupWidgetSectionDto,
  dashboardDate: string | Dayjs,
) => {
  switch (widgetSection.widgetType) {
    case 'text_area':
    case 'media': {
      return {};
    }
    case 'xy_chart': {
      return getInitialStateForReportChartWidget(widgetSection, dashboardDate);
    }
    default: {
      return {
        date: resolveReportBuilderTemplateDefaultOptionsDate(
          widgetSection?.defaultOptions?.date,
        ),
      };
    }
  }
};

const adapter = createEntityAdapter<ReportBuilderTemplateState>();

const initialStateAdapter = adapter.getInitialState();

export const updateReportBuilderTemplateWidgetState = createAction<{
  /**
   * @param id - widget section id
   */
  id: string;
  /**
   * @param groupId - widget's group id
   */
  groupId: string;
  templateId: string;
  widgetState: UnknownRecord;
}>('updateReportBuilderTemplateWidgetState');

const updateWidgetStateReducer = (
  state: EntityState<ReportBuilderTemplateState>,
  action: ReturnType<typeof updateReportBuilderTemplateWidgetState>,
) => {
  const {
    templateId,
    id: widgetSectionId,
    groupId,
    widgetState: newWidgetState,
  } = action.payload;

  const templateAndGroupId = joinWithDash([templateId, groupId]);

  const templateAndGroup = state.entities[templateAndGroupId];

  if (templateAndGroup == null) return;

  if (templateAndGroup.widgetsState?.[widgetSectionId] == null) return;

  const widgetSectionState = templateAndGroup.widgetsState[widgetSectionId];

  templateAndGroup.widgetsState[widgetSectionId] = {
    ...widgetSectionState,
    ...newWidgetState,
  };
};

interface State {
  pendingRequestIds: string[];
}

const {
  getApiSettingsReportBuilderGotenbergTemplatesById: getReportBuilderTemplate,
  postApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluate:
    postEvaluateWidget,
} = api.endpoints;
/**
 * @property pendingRequestIds - If there are any "evaluate" or "report builder template" requests are pending then "preview" request waits until they are all resolved
 */
const initialState: State = {
  pendingRequestIds: [],
};

export const reportBuilderTemplateSlice = createSlice({
  name: 'reportBuilderTemplate',
  initialState: {
    ...initialState,
    ...initialStateAdapter,
  },
  reducers: {
    updateReportBuilderTemplateDate: (
      state,
      action: PayloadAction<{
        date: string;
        templateId: string;
      }>,
    ) => {
      const { templateId, date } = action.payload;
      if (state.entities[templateId] == null) return;

      const templateGroupStateIds = state.ids
        .filter((id) => id.toString().includes(templateId))
        .map((id) => id.toString());

      if (templateGroupStateIds.length > 0) {
        for (const templateGroupStateId of templateGroupStateIds) {
          const templateGroupState = state.entities[templateGroupStateId];

          if (templateGroupState) {
            templateGroupState.date = date;

            for (const widgetId in templateGroupState.widgetsState) {
              const widgetState = templateGroupState.widgetsState[widgetId];
              widgetState.date = date;

              if (widgetState.type === REPORT_BUILDER_WIDGET_TYPES.XY_CHART) {
                const chartWidgetState =
                  getUpdatedWidgetStateForReportChartWidget(widgetState, date);

                widgetState.dateFrom = chartWidgetState.dateFrom;
                widgetState.dateTo = chartWidgetState.dateTo;
              }
            }
          }
        }
      }

      state.entities[templateId].date = date;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      updateReportBuilderTemplateWidgetState,
      updateWidgetStateReducer,
    );

    builder.addMatcher(
      getReportBuilderTemplate.matchFulfilled,
      (state, action) => {
        const { id: templateId } = action.meta.arg.originalArgs;
        const template = action.payload;

        const templateState = state.entities[templateId];

        const initialDate =
          resolveReportBuilderTemplateDefaultOptionsDate(null);

        if (templateState == null) {
          adapter.upsertOne(state, {
            id: templateId,
            date: initialDate,
            widgetsState: {},
          });
        }

        for (const templateGroup of template.groups) {
          const templateAndGroupStateId = joinWithDash([
            templateId,
            templateGroup.id,
          ]);

          const templateAndGroupState = state.entities[templateAndGroupStateId];

          if (templateAndGroupState == null) {
            const widgetsState = {} as WidgetsState;

            for (const widgetSection of templateGroup.widgetSections) {
              widgetsState[widgetSection.id] = {
                type: widgetSection.widgetType,
                ...getInitialReportBuilderTemplateWidgetState(
                  widgetSection,
                  initialDate,
                ),
              };
            }

            adapter.upsertOne(state, {
              id: templateAndGroupStateId,
              date: initialDate,
              widgetsState,
            });
          } else {
            const widgetsState = {} as WidgetsState;

            for (const widgetSection of templateGroup.widgetSections) {
              if (templateAndGroupState.widgetsState[widgetSection.id] != null)
                continue;

              widgetsState[widgetSection.id] = {
                type: widgetSection.widgetType,
                ...getInitialReportBuilderTemplateWidgetState(
                  widgetSection,
                  initialDate,
                ),
              };
            }

            adapter.updateOne(state, {
              id: templateAndGroupStateId,
              changes: {
                widgetsState: {
                  ...templateAndGroupState.widgetsState,
                  ...widgetsState,
                },
              },
            });
          }
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        getReportBuilderTemplate.matchPending,
        postEvaluateWidget.matchPending,
      ),
      (state, { meta }) => {
        state.pendingRequestIds.push(meta.requestId);
      },
    );
    builder.addMatcher(
      isAnyOf(
        getReportBuilderTemplate.matchFulfilled,
        getReportBuilderTemplate.matchRejected,

        postEvaluateWidget.matchFulfilled,
        postEvaluateWidget.matchRejected,
      ),
      (state, { meta }) => {
        state.pendingRequestIds = state.pendingRequestIds.filter(
          (id) => id !== meta.requestId,
        );
      },
    );
  },
});

export const { updateReportBuilderTemplateDate } =
  reportBuilderTemplateSlice.actions;

export const { selectById: selectReportBuilderTemplateMetadataById } =
  adapter.getSelectors((state: TRootState) => state.reportBuilderTemplate);
