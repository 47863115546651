import { groupBy, sortBy, takeRight, uniqBy } from 'lodash-es';

import { _ObjectableType } from '@/bundles/REport/reducers/financialsSlice';
import { dateToUnix, dateToYear } from '@/shared/lib/formatting/dates';
import {
  MAX_MONTHS_IN_YEAR,
  MONTHLY_THIN_TAB,
  PeriodItemType,
  QUARTERLY_THIN_TAB,
  YEARLY_THIN_TAB,
} from '@/stories/FlexibleFilterByPeriods/consts';
import {
  createLatestTwoQuartersPeriodItems,
  createMonthPeriodItem,
  createPeriodItem,
  filterPeriodIsSameOrBeforeMonth,
} from '@/stories/FlexibleFilterByPeriods/utils';
import {
  IPeriodItem,
  PossiblePeriodsGroupedByYear,
} from 'stories/FlexibleFilterByPeriods/types';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { REPORT_FINANCIALS_OBJECT_ID_AND_TYPE_DELIMITER as DELIMITER } from './consts';
import type * as Type from './types';

export function resolveDefaultPeriodItems({
  groupedPeriods,
  periodsType,
  withOnlyBudgetColumns = true,
}: {
  groupedPeriods: PossiblePeriodsGroupedByYear;
  periodsType: IReportTableConfig['periodsType'];
  withOnlyBudgetColumns?: boolean;
}) {
  const allPeriods = Object.values(groupedPeriods).flat();
  const periods = withOnlyBudgetColumns
    ? allPeriods
    : allPeriods.filter((p) => filterPeriodIsSameOrBeforeMonth(new Date(p)));

  switch (periodsType) {
    case 'mtd_and_total':
    case 'total':
    case 'all_time_to_date':
    case 'mtd': {
      const latestYear = takeRight(periods, MAX_MONTHS_IN_YEAR);
      return latestYear.map(createMonthPeriodItem);
    }
    case 'variance_report':
    case 'single_mtd':
    case 'single_mtd_and_ytd':
    case 'single_mtd_qtd_and_ytd':
    case 'single_mtd_qtd_and_itd':
    case 'single_mtd_qtd_and_uw_itd':
    case 'single_qtd_and_ytd':
    case 'single_qtd_ytd_and_itd':
    case 'single_qtd_ytd_and_uw_itd':
    case 'single_t3_and_t12': {
      const latestMonth = takeRight(periods);
      return latestMonth.map(createMonthPeriodItem);
    }

    case 'year_mtd': {
      const currentYear = dateToYear(new Date());
      const currentYearPeriods =
        groupedPeriods[currentYear] ?? Object.values(groupedPeriods)[0] ?? [];

      return currentYearPeriods.map(createMonthPeriodItem);
    }

    default:
      return [];
  }
}

export const sortPeriodItemsByUnix = (periodItems: IPeriodItem[]) =>
  sortBy(periodItems, ({ period }) => {
    return dateToUnix(period);
  });

export function resolveDefaultPeriodItemsByPeriodItemType({
  periodItemType,
  reportTableConfigPeriodsType,
  possiblePeriods,
  withOnlyBudgetColumns = true,
}: {
  periodItemType: IPeriodItem['type'];
  reportTableConfigPeriodsType: IReportTableConfig['periodsType'];
  possiblePeriods: IPeriodItem['period'][];
  withOnlyBudgetColumns?: boolean;
}) {
  switch (periodItemType) {
    case QUARTERLY_THIN_TAB.id: {
      return createLatestTwoQuartersPeriodItems(possiblePeriods);
    }

    case MONTHLY_THIN_TAB.id: {
      const groupedPeriods = groupBy(possiblePeriods, (period) =>
        dateToYear(period),
      ) as PossiblePeriodsGroupedByYear;

      return resolveDefaultPeriodItems({
        periodsType: reportTableConfigPeriodsType,
        groupedPeriods,
        withOnlyBudgetColumns,
      });
    }

    case YEARLY_THIN_TAB.id: {
      const periods = uniqBy(possiblePeriods, (period) =>
        String(dateToYear(period)),
      );
      const latestTwoPeriods = takeRight(periods, 2);
      const latestTwoPeriodItems = latestTwoPeriods.map((period) =>
        createPeriodItem({
          period,
          type: PeriodItemType.Ytd,
        }),
      );
      return latestTwoPeriodItems;
    }

    default:
      return [];
  }
}

export function addStateProps<
  T extends Type.FinancialsObjectable,
  _TYPE extends _ObjectableType,
>(items: T[], _type: _TYPE): Type.AndLegalEntitiesStateProps<T, _TYPE>[] {
  return items.map((item) => {
    const legalEntities = item.legalEntities.map((le) => ({
      ...le,
      _selected: false,
      _type,
    }));

    return { ...item, _type, _selected: false, legalEntities };
  });
}

export const areReportFinancialsObjectsEqual = (
  obj1: Type.ObjAndStateProps,
  obj2: Type.ObjAndStateProps,
) => obj1.id === obj2.id && obj1._type === obj2._type;

const createReportFinancialsUniqId = (objId: number, objType: string) =>
  `${objId}${DELIMITER}${objType}` as const;

export const createReportFinancialsObjIdAndType = (
  obj: Type.ObjAndStateProps,
) => createReportFinancialsUniqId(obj.id, obj._type);

export function findObjFromQuery(
  objEntryFromQuery: [string, string],
): (obj: Type.ObjAndStateProps) => boolean {
  const [objTypeFromQuery, objIdFromQuery] = objEntryFromQuery;

  return (obj: Type.ObjAndStateProps) => {
    const objIdAndType = createReportFinancialsObjIdAndType(obj);
    const uniqId = createReportFinancialsUniqId(
      Number(objIdFromQuery),
      objTypeFromQuery,
    );
    return uniqId === objIdAndType;
  };
}
