import {
  REPORT_BUILDER_DATE_FORMAT_OPTIONS,
  REPORT_BUILDER_DATE_FORMAT_QUARTER,
  REPORT_BUILDER_FORM_SCHEMA,
  reportWidgetsSliceActions,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
  type ReportBuilderForm,
} from '@/entities/report/reportBuilder';

import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

import type { LegalEntity } from '@/entities/core/legalEntity';
import { GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { filterKnownWidgetTypes } from '@/entities/report/reportBuilder/lib/helpers';
import { useDebouncedPutApiCoreAssetsReportBuilderReportsById } from '@/entities/report/reportBuilder/lib/useDebouncedPutApiCoreAssetsReportBuilderReportsById';
import { useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery } from '@/entities/report/reportBuilder/lib/useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery';
import { ReportBuilderFormLegalEntities } from '@/entities/report/reportBuilder/ui/ReportBuilderFormLegalEntities';
import { WidgetConfigCard } from '@/entities/report/reportBuilder/ui/WidgetConfigCard';
import { useDeleteReport } from '@/features/report/report/deleteReport';
import { PublishReportButton } from '@/features/report/report/publishReport';
import { currentUserAllowedToSeeNavigateToReportViewPage } from '@/pages/reports/report/view/permissions';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { SaveStatus } from '@/shared/ui/SaveStatus';
import { Button, Input, Select, Tumbler } from '@/stories';
import Calendar from '@/stories/FlexibleFilterByPeriods/calendar/Calendar';
import type { ISelectOption } from '@/stories/FormControls/Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

const Builder = ({
  data,
  isFetching,
}: {
  data: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse;
  isFetching: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const savingUpdates = useAppSelector(
    (s) => s.reportBuilder.pendingRequestIds.length > 0,
  );

  const methods = useForm<ReportBuilderForm>({
    resolver: yupResolver(REPORT_BUILDER_FORM_SCHEMA),
    defaultValues: {
      name: data?.report?.name ?? '',
      subtitle: data?.report?.subtitle ?? '',
      date: data?.report?.date,
      legalEntityIds: mapListToIds(data?.report?.legalEntities ?? []),
      dateFormat: data?.report.dateFormat,
    },
  });
  const { register, watch, setValue: setFormValue } = methods;

  const dateFormatField = watch('dateFormat');
  const formLegalEntityIds = watch('legalEntityIds');

  const reportWidgetsState = useAppSelector((state) => state.reportWidgets);

  const allWidgets = useMemo(() => {
    return data?.report?.groups.flatMap((g) =>
      g.widgets.filter(filterKnownWidgetTypes),
    );
  }, [data]);

  const { dataObjectURL: pdfSrc, isLoading: isPDFLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery();

  const { onReportUpdate, updateOptions } =
    useDebouncedPutApiCoreAssetsReportBuilderReportsById({
      assetId: Number(assetId),
      reportData: data,
      methods,
    });

  const [handleDeleteReport, { isLoading: isDeletingReport }] = useDeleteReport(
    {
      assetId: Number(assetId),
    },
  );

  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.assets.fullPath,
  });

  const handleDeleteReportAndNavigateBack = async () => {
    if (data?.report != null) {
      const response = await handleDeleteReport(data.report.id);

      if ((response != null && 'error' in response) || response == null) {
        return;
      }

      navigateBack();
    }
  };

  const handleDateFormatTumblerChange = () => {
    setFormValue(
      'dateFormat',
      dateFormatField ? null : REPORT_BUILDER_DATE_FORMAT_QUARTER,
      {
        shouldDirty: true,
      },
    );
    onReportUpdate();
  };

  const handleDateFormatSelectChange = (
    option: ISelectOption<NonNullable<ReportBuilderForm['dateFormat']>>,
  ) => {
    setFormValue('dateFormat', option?.id ?? null, {
      shouldDirty: true,
    });
    onReportUpdate();
  };

  const handleLEChange = (ids: LegalEntity['id'][]) => {
    setFormValue('legalEntityIds', ids, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useResetAllModalsOnUnmount();

  const isDateFormatTumblerChecked = watch('dateFormat') != null;
  const isReportLockedToUpdate = data?.report.status === 'published';

  return (
    <BuilderUI>
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={updateOptions.isLoading}
            onClick={navigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo
            subtitle={data?.meta.asset.name}
            title={data?.report.name}
          />
          <BuilderUI.Settings.Header.Actions>
            <BuilderUI.Settings.Header.DeleteIconButton
              onClick={handleDeleteReportAndNavigateBack}
              disabled={isDeletingReport || updateOptions.isLoading}
            />
            {currentUserAllowedToSeeNavigateToReportViewPage() && (
              <BuilderUI.Settings.Header.ViewIconButton
                onClick={() => {
                  navigate(
                    generateUrl(ROUTES_ROOT.reports.report.view.fullPath, {
                      pathParams: {
                        id: data?.report?.id ?? '',
                      },
                      queryParams: {
                        assetId,
                      },
                    }),
                  );
                }}
              />
            )}
            <SaveStatus saving={savingUpdates} />
          </BuilderUI.Settings.Header.Actions>
        </BuilderUI.Settings.Header>

        <BuilderUI.Settings.Main>
          <BuilderUI.Settings.Main.Basic>
            <BuilderUI.Settings.Main.Basic.Field label="Report Title">
              <Input
                disabled={isReportLockedToUpdate}
                classes={{ backLayer: 'bg-transparent' }}
                {...register('name')}
                onBlur={onReportUpdate}
              />
            </BuilderUI.Settings.Main.Basic.Field>
            <BuilderUI.Settings.Main.Basic.Field label="Report Subtitle">
              <Input
                disabled={isReportLockedToUpdate}
                placeholder="Enter subtitle"
                classes={{ backLayer: 'bg-transparent' }}
                {...register('subtitle')}
                onBlur={onReportUpdate}
              />
            </BuilderUI.Settings.Main.Basic.Field>
            <BuilderUI.Settings.Main.Basic.Field>
              <div className="flex items-center gap-tw-2">
                <Tumbler
                  checked={isDateFormatTumblerChecked}
                  onChange={handleDateFormatTumblerChange}
                />
                <p className="inline-regular text-neutral-800">Display Date</p>
              </div>
            </BuilderUI.Settings.Main.Basic.Field>
            {isDateFormatTumblerChecked && (
              <BuilderUI.Settings.Main.Basic.Field>
                <Select
                  onSelectedChange={handleDateFormatSelectChange}
                  selected={REPORT_BUILDER_DATE_FORMAT_OPTIONS.find(
                    (o) => o.id === dateFormatField,
                  )}
                  options={REPORT_BUILDER_DATE_FORMAT_OPTIONS}
                />
              </BuilderUI.Settings.Main.Basic.Field>
            )}
            <BuilderUI.Settings.Main.Basic.Hr />
            <ReportBuilderFormLegalEntities
              options={data.meta?.asset.legalEntities ?? []}
              value={formLegalEntityIds}
              hidden={isReportLockedToUpdate}
              onChange={handleLEChange}
            />
          </BuilderUI.Settings.Main.Basic>

          <BuilderUI.Settings.WidgetLayout>
            <BuilderUI.Settings.WidgetLayout.Header>
              {!isReportLockedToUpdate && (
                <Calendar
                  closeOnDateUpdate
                  selectionMode="daily"
                  value={[dayjs(reportWidgetsState.date ?? new Date())]}
                  onChange={([item]) => {
                    dispatch(
                      reportWidgetsSliceActions.updateReportDate({
                        date: formatToDateStringForRequest(item),
                      }),
                    );
                    setFormValue('date', formatToDateStringForRequest(item), {
                      shouldTouch: true,
                      shouldDirty: true,
                    });
                  }}
                />
              )}
            </BuilderUI.Settings.WidgetLayout.Header>
            <div className="flex flex-col gap-tw-2">
              {allWidgets?.map((w, idx) => (
                <WidgetConfigCard
                  disabled={isReportLockedToUpdate}
                  key={w.id}
                  widgetSection={w}
                  index={idx}
                  isLoading={isFetching}
                />
              ))}
            </div>
          </BuilderUI.Settings.WidgetLayout>
        </BuilderUI.Settings.Main>
        <BuilderUI.Settings.Footer>
          <Button onClick={navigateBack}>Cancel</Button>
          <GrowDiv />
          {data && !isReportLockedToUpdate && (
            <PublishReportButton
              assetId={Number(assetId)}
              reportId={data.report.id}
            />
          )}
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>
      {isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.Loading />}
      {pdfSrc && (
        <BuilderUI.PdfPreview.Iframe src={pdfSrc}>
          {isPDFLoading && <BuilderUI.PdfPreview.IframeFetching />}
        </BuilderUI.PdfPreview.Iframe>
      )}
      {!isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.NoData />}
    </BuilderUI>
  );
};
export const ReportPage: React.FC<RouteComponentProps> = () => {
  const params = useParams();
  const assetId = useAssetIdSearchParam();

  const { data, isFetching, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery(
      {
        id: params.id,
        assetId: assetId!,
      },
      {
        skip: assetId == null,
      },
    );

  return (
    <>
      {isLoading && <BuilderUI.Loading />}
      {!isLoading && data != null && (
        <Builder data={data} isFetching={isFetching} />
      )}
    </>
  );
};
