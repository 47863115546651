import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader } from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import type { LayoutProps } from '@/types/Props';
import { PropsWithChildren } from 'react';

const EntityItemListSkeletons = () =>
  Array.from({ length: 5 }, (_, idx) => (
    <div key={idx} className="flex bg-neutral-000 p-tw-4">
      <SkeletonBlock className="h-[52px] w-full" />
    </div>
  ));

export function EntityItemList({
  children,
  isFetching,
  isLoading,
}: PropsWithChildren & {
  isLoading: boolean;
  isFetching: boolean;
}) {
  return (
    <div className="relative flex flex-col gap bg-neutral-100 py-[1px]">
      {isFetching && !isLoading && <AnimationLoader withBg />}
      {isLoading ? <EntityItemListSkeletons /> : children}
    </div>
  );
}

EntityItemList.Wrapper = (props: LayoutProps) => (
  <div
    className={cn(
      'flex max-h-screen min-h-screen flex-col border-r border-solid border-neutral-200 bg-neutral-000',
      props.className,
    )}
  >
    {props.children}
  </div>
);
EntityItemList.Header = (props: LayoutProps) => (
  <div
    className={cn(
      'flex flex-col gap-tw-4 px-tw-4 pb-tw-3 pt-tw-5',
      props.className,
    )}
  >
    {props.children}
  </div>
);

EntityItemList.Title = (props: LayoutProps) => (
  <p className={cn('header5-bold text-neutral-900', props.className)}>
    {props.children}
  </p>
);
