import { useAddDashboardWidgetSection } from '@/bundles/Shared/features/dashboard/addWidget/lib';
import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  REPORT_BUILDER_WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  BASIC_AVAILABLE_WIDGETS_TABS,
  DASHBOARD_WIDGETS_TAB_ID,
  NEW_WIDGETS_TAB,
  REPORT_BUILDER_TEMPLATE_AVAILABLE_WIDGETS_TABS,
  TEMPLATE_WIDGETS_TAB,
} from '@/bundles/Shared/widgets/dashboard/widgetsBar/config';
import { useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import {
  ALL_DASHBOARD_SECTION_TYPES,
  AllWidgetTypes,
  ObjectDashboardSectionTypes,
  ReportDashboardSectionPositionLayouts,
  ReportDashboardType,
  useDashboardContext,
  useDashboardCopyableWidgetSections,
} from 'bundles/Shared/entities/dashboard';
import { useCopyWidgetSectionToBoard } from 'bundles/Shared/features/dashboard/copyWidget';
import { WidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { groupBy, intersection, isEmpty, sortBy, uniqBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  Modal,
  SearchInput,
  ThinTabGroup,
} from 'stories';
import { useTabs } from 'stories/Tabs/useTabs';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';

type Props = DialogProps & {
  type: ReportDashboardType;
  layout: ReportDashboardSectionPositionLayouts;
  reportBuilderTemplateWidgetGroupId?: string;
};

type WidgetOption = {
  id: string;
  title: string;
  widgetType: AllWidgetTypes;
  board?: {
    id: string;
    dashboard: {
      id: string;
      name: string;
    };
  };
};

export function AvailableWidgetsModal({
  onClose,
  type,
  layout: layouts,
  reportBuilderTemplateWidgetGroupId,
}: Props) {
  const { dashboardId, boardId } = useDashboardContext();

  const { thinTabGroupProps, tab: selectedTab } = useTabs(() => {
    if (type === ReportDashboardType.REPORT_BUILDER_TEMPLATE) {
      return REPORT_BUILDER_TEMPLATE_AVAILABLE_WIDGETS_TABS;
    }
    return BASIC_AVAILABLE_WIDGETS_TABS;
  }, 0);
  const { templateId } = useReportBuilderTemplateScreenParams();
  const { data, boards, dashboardsMap } = useDashboardCopyableWidgetSections({
    dashboardId,
    boardId,
    templateId,
    dashboardType: type,
  });
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetOption[]>([]);
  const [selectedBoards, setSelectedBoards] = useState<{
    [key: string]: string | null;
  }>({});
  const [copyDashboardWidget] = useCopyWidgetSectionToBoard({
    dashboardId,
    dashboardType: type,
    boardId,
    templateId,
  });
  const canSelectMultipleWidgets =
    type !== ReportDashboardType.REPORT_BUILDER_TEMPLATE;

  const [addDashboardWidget, { isLoading: isAddingWidget }] =
    useAddDashboardWidgetSection();
  const isLoading = isAddingWidget;

  const widgetsMap = useMemo<Record<string, WidgetConfiguration>>(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.REPORT_BUILDER_TEMPLATE:
      default: {
        return REPORT_BUILDER_WIDGETS_CONFIG_MAP;
      }
    }
  }, [type]);

  const { data: templateCopyableWidgetsData } =
    useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery(
      {
        templateId,
      },
      {
        skip: type !== ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      },
    );

  const templatesMap = useMemo(() => {
    if (type !== ReportDashboardType.REPORT_BUILDER_TEMPLATE) return new Map();
    return new Map(
      uniqBy(
        templateCopyableWidgetsData?.map((s) => s.template) ?? [],
        'id',
      ).map((t) => [t.id, t]),
    );
  }, [templateCopyableWidgetsData]);

  const widgetOptions = Object.keys(widgetsMap).map((k) => ({
    id: k,
    title: widgetsMap[k].title,
    widgetType: k,
  })) as WidgetOption[];

  const getWidgetOptions = () => {
    switch (selectedTab?.id) {
      case NEW_WIDGETS_TAB.id: {
        return widgetOptions;
      }
      case DASHBOARD_WIDGETS_TAB_ID: {
        return (data as unknown as typeof widgetOptions) ?? [];
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return templateCopyableWidgetsData ?? [];
      }
      default: {
        return [];
      }
    }
  };

  const { inputProps, filteredItems } = useItemsFilterByText(
    getWidgetOptions(),
    (o, searchText) => {
      const filterByTitle = includesInLowerCase(o.title, searchText);
      const filterByGroup = includesInLowerCase(
        o.board?.dashboard.name ?? o.template?.name ?? '',
        searchText,
      );
      const selectedBoard = selectedBoards[o.board?.dashboard?.id ?? ''];
      const filterByBoard =
        o.board == null ||
        selectedBoard == null ||
        selectedBoard === o.board?.id;

      return (filterByTitle || filterByGroup) && filterByBoard;
    },
    {
      skipWhenTextIsEmpty: false,
    },
  );

  const groupedItems = useMemo(() => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return groupBy(
          sortBy(filteredItems, 'board.dashboard.name'),
          (o) => o.board?.dashboard.id,
        );
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return groupBy(
          sortBy(filteredItems, 'template.name'),
          (o) => o.template?.id,
        );
      }
      default: {
        return null;
      }
    }
  }, [filteredItems, selectedTab]);

  const handleAddWidget = async (newWidgets: WidgetOption[]) => {
    const layout = layouts.lg;

    if (
      isEmpty(
        intersection(
          Object.values(ALL_DASHBOARD_SECTION_TYPES),
          newWidgets.map((w) => w.id),
        ),
      )
    ) {
      const getSource = () => {
        switch (true) {
          case type !== ReportDashboardType.REPORT_BUILDER_TEMPLATE: {
            return undefined;
          }

          case selectedTab?.id === TEMPLATE_WIDGETS_TAB.id: {
            return ReportDashboardType.REPORT_BUILDER_TEMPLATE;
          }

          case selectedTab?.id === DASHBOARD_WIDGETS_TAB_ID: {
            return ReportDashboardType.OBJECT;
          }

          default: {
            return undefined;
          }
        }
      };

      await copyDashboardWidget({
        sections: newWidgets,
        groupId: reportBuilderTemplateWidgetGroupId,
        source: getSource(),
      });
      onClose();
      return;
    }
    const res = await addDashboardWidget({
      type: newWidgets.map(
        (w) => w.widgetType,
      ) as ObjectDashboardSectionTypes[],
      templateId,
      groupId: reportBuilderTemplateWidgetGroupId,
      boardId,
      layout,
    });
    if ('error' in res) {
      return;
    }

    onClose();
  };

  const handleSelectWidget = (
    widgetOption: WidgetOption[],
    checked: boolean,
  ) => {
    setSelectedWidgets((prev) =>
      checked
        ? [
            ...prev,
            ...widgetOption.filter((o) => !mapListToIds(prev).includes(o.id)),
          ]
        : prev.filter((o) => !mapListToIds(widgetOption).includes(o.id)),
    );
  };

  const renderWidgetCard = useCallback(
    (o: WidgetOption) => {
      const checked = selectedWidgets.find((ox) => ox.id === o.id) != null;

      return (
        <WidgetCard
          withCheckbox={canSelectMultipleWidgets}
          checked={checked}
          key={o.id}
          widgetType={o.widgetType}
          title={o.title}
          onClick={() => {
            handleSelectWidget([o], !checked);
          }}
        >
          <div className="grow" />
          <Button
            variant="secondary"
            size="xs"
            className="hidden group-hover/item:block"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              handleAddWidget([o]);
            }}
          >
            Add
          </Button>
        </WidgetCard>
      );
    },
    [selectedWidgets, isLoading, selectedTab],
  );

  const getSearchPlaceholder = () => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return 'Search by Widget Name or Dashboard name';
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return 'Search by Widget Name or Template name';
      }
      case NEW_WIDGETS_TAB.id:
      default: {
        return 'Search by Widget Name';
      }
    }
  };

  const renderDashboardGroup = (groupId: string, items: WidgetOption[]) => {
    const dashboardGroup = dashboardsMap.get(groupId);
    const templateGroup = templatesMap.get(groupId);
    const group = dashboardGroup ?? templateGroup;

    const dashboardBoards = boards.filter((b) => b.dashboard.id === groupId);
    const dashboardBoardsOptions = [
      {
        id: null,
        name: 'All',
      },
      ...dashboardBoards,
    ];
    return (
      <WidgetCard.Group key={groupId} title={group?.name}>
        <WidgetCard.List.Header
          withCheckbox={canSelectMultipleWidgets}
          onChange={(checked) => handleSelectWidget(items, checked)}
          checked={items.every((o) => selectedWidgets.includes(o))}
          widgetCount={items.length}
        >
          <div className="grow" />
          {canSelectMultipleWidgets && (
            <Dropdown
              items={dashboardBoardsOptions.map((b) => (
                <DropdownItem
                  key={b.id}
                  active={selectedBoards[groupId] === b.id}
                  onClick={() => {
                    setSelectedBoards((prev) => ({
                      ...prev,
                      [groupId]: b.id,
                    }));
                  }}
                >
                  {b.name}
                </DropdownItem>
              ))}
            >
              <Button variant="secondary" size="xs">
                {
                  dashboardBoardsOptions.find(
                    (b) => b.id == selectedBoards[groupId],
                  )?.name
                }
              </Button>
            </Dropdown>
          )}
        </WidgetCard.List.Header>
        <WidgetCard.List>{items.map(renderWidgetCard)}</WidgetCard.List>
      </WidgetCard.Group>
    );
  };

  return (
    <Modal
      header="Available Widgets"
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
      actions={
        canSelectMultipleWidgets ? (
          <Modal.Actions className="flex-end">
            <Button
              variant="success"
              onClick={() => handleAddWidget(selectedWidgets)}
              disabled={isLoading || selectedWidgets.length === 0}
            >
              Add {selectedWidgets.length > 0 && selectedWidgets.length}{' '}
              {pluralize('widget', selectedWidgets.length)}
            </Button>
          </Modal.Actions>
        ) : null
      }
    >
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <SearchInput {...inputProps} placeholder={getSearchPlaceholder()} />
      {groupedItems != null &&
        Object.entries(groupedItems).map(([groupId, items]) =>
          renderDashboardGroup(groupId, items),
        )}
      <WidgetCard.List>
        {groupedItems == null && filteredItems.map(renderWidgetCard)}
      </WidgetCard.List>
    </Modal>
  );
}
