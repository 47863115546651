import { useWidgetFullScreen } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import { AgGridReact } from 'ag-grid-react';
import {
  GlobalLeaseTableWidgetDto,
  ReportObjectDashboardSection,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateRangeWidgetStateWithGranularity,
  getStateFromFilterChangedEvent,
  PaginationWidgetState,
  postProcessPopup,
  QueryWidgetState,
  useWidgetTableDefaultColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  COL_DEF_OVERRIDES,
  GLOBAL_LEASE_VIZ_CONFIG,
  UNIT_COL_ID,
} from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/index';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { LeaseDetailsModal } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/ui/LeaseDetailsModal';
import { useMemo, useRef } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { WidgetStateTablePagination } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateTablePagination';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { WidgetStateSearchInput } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateSearchInput';
import { useGetApiSettingsReportThresholdsQuery } from '@/bundles/Shared/shared/api/settingsReportThresholdsEnhancedApi';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { Tooltip, Tumbler } from '@/stories';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  ColDefBuilder,
  ColGroupDefBuilder,
  ColumnDefsBuilder,
  ExcelStyleBuilder,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { getColDefFilter } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/lib';

export type UnitTableWidgetState = PaginationWidgetState &
  DateRangeWidgetStateWithGranularity &
  QueryWidgetState;

export function GlobalLeaseTableWidget(
  props: WidgetProps<GlobalLeaseTableWidgetDto, ReportObjectDashboardSection> &
    WidgetConfigProps<UnknownRecord> &
    WidgetStateProps<UnitTableWidgetState>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    isLoading,
    isFetching,
    isError,
    isUninitialized,
    mode,
  } = props;

  const { openModal } = useModal();
  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const exportFeature = useTableWidgetExportFeature({
    gridRef,
    mode,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
  });

  const { data: thresholdData, isLoading: isThresholdLoading } =
    useGetApiSettingsReportThresholdsQuery();

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const mappedData = useMemo(
    () =>
      data
        ? {
            ...data,
            rows: data.rows.map((row) => ({
              ...row,
              values: Object.fromEntries(
                row.values.map((v) => [v.key, v.value]),
              ),
            })),
          }
        : undefined,
    [data],
  );

  const excelStyles = useMemo(() => {
    const excelStyleBuilder = new ExcelStyleBuilder({
      vizConfig: GLOBAL_LEASE_VIZ_CONFIG,
      mode,
    });
    return excelStyleBuilder.buildExcelStyles();
  }, []);

  const columnDefs = useMemo(() => {
    const colDefBuilder = new ColDefBuilder({
      mode,
    });

    colDefBuilder.withOverride(({ columnSettings, column }) => ({
      field: `values.${columnSettings.key}`,
      ...getColDefFilter({
        columnSettings,
        column,
        filterOptions: data?.filterOptions ?? [],
      }),
      ...COL_DEF_OVERRIDES[columnSettings.key.toString()],
    }));
    const columnDefsBuilder = new ColumnDefsBuilder({
      mode,
      vizConfig: GLOBAL_LEASE_VIZ_CONFIG,
      colGroupDefBuilder: new ColGroupDefBuilder({
        mode,
      }),
      colDefBuilder,
    });
    return columnDefsBuilder.build({
      columns: data?.columns ?? [],
    });
  }, [
    JSON.stringify(data?.columns),
    widgetSection.widgetConfig.viz_config,
    JSON.stringify(data?.filterOptions),
  ]);

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });

  const getLtoValues = (k: string) => {
    if (!state.useLtoThresholds) return '-';

    const value =
      thresholdData?.items?.find(({ kind }) => kind === k)?.globalValue ?? '-';

    return ` ${value}  mo.`;
  };

  const ltoMinTerm = getLtoValues('lto_min_term');
  const ltoMaxVariance = getLtoValues('lto_max_variance');

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
          {!isThresholdLoading && (
            <div className="mt-8 flex items-center gap-s">
              <Tooltip
                mainText={`${
                  state.useLtoThresholds ? 'Disable' : 'Enable'
                } filtering by Lease Trade Out Threshold settings`}
                theme="light"
                placement="bottom-start"
                arrowPosition="start"
              >
                <Tumbler
                  checked={state.useLtoThresholds}
                  onChange={() => {
                    onStateChange({
                      ...state,
                      useLtoThresholds: state.useLtoThresholds
                        ? undefined
                        : true,
                    });
                  }}
                />
              </Tooltip>
              <span className="label-regular text-neutral-500">
                MIN Lease Terms:{' '}
                <span className="text-neutral-650">{ltoMinTerm}</span>
              </span>
              <VerticalSeparator />
              <span className="label-regular text-neutral-500">
                MAX Term Variance:{' '}
                <span className="text-neutral-650">{ltoMaxVariance}</span>
              </span>
            </div>
          )}
        </DashboardWidgetCard.Header.Title>
        <div className="grow" />
        <exportFeature.ExportButtonComponent />
        <WidgetStateCalendarRangeSelector
          state={state}
          onStateChange={onStateChange}
          disableFuture={false}
        />
        <widgetStateFullScreenFeature.IconButton />
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        <WidgetStateTablePagination
          isLoading={isLoading ?? isFetching ?? false}
          state={state}
          onStateChange={onStateChange}
          totalSize={data?.totalSize ?? 0}
          includeTotalSizeInPerPage
        />
        <GrowDiv />
        <WidgetStateSearchInput
          state={state}
          onStateChange={onStateChange}
          suggestions={['unit label', 'asset name', 'unit #']}
        />
      </DashboardWidgetCard.Panel>
      {!isLoading && isUninitialized && <WidgetTablePlaceholder />}

      {!isLoading && !isError && !isUninitialized && (
        <WidgetTable
          ref={gridRef}
          treeData={false}
          onCellClicked={(e) => {
            if (e.colDef.colId !== UNIT_COL_ID) return;
            openModal(LeaseDetailsModal, {
              unitId: e.data.unit_id,
              unitLabel: e.value,
            });
          }}
          mode={mode}
          autoGroupColumnDef={undefined}
          rowData={mappedData?.rows}
          columnDefs={columnDefs}
          excelStyles={excelStyles}
          defaultColDef={defaultColDef}
          onFilterChanged={(e) => {
            onStateChange({
              ...state,
              ...getStateFromFilterChangedEvent(e, data?.columns ?? []),
            });
          }}
          postProcessPopup={postProcessPopup}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
