import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiPortfolioActivityLogItems: build.query<
      GetApiPortfolioActivityLogItemsApiResponse,
      GetApiPortfolioActivityLogItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/activity_log_items`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          type: queryArg['type'],
        },
      }),
    }),
    getApiPortfolioContributionsById: build.query<
      GetApiPortfolioContributionsByIdApiResponse,
      GetApiPortfolioContributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/contributions/${queryArg.id}`,
      }),
    }),
    getApiPortfolioDistributionsById: build.query<
      GetApiPortfolioDistributionsByIdApiResponse,
      GetApiPortfolioDistributionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/distributions/${queryArg.id}`,
      }),
    }),
    getApiPortfolioDocumentsRecent: build.query<
      GetApiPortfolioDocumentsRecentApiResponse,
      GetApiPortfolioDocumentsRecentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/documents/recent`,
        params: { page: queryArg.page, per_page: queryArg.perPage },
      }),
    }),
    getApiPortfolioFeedMessages: build.query<
      GetApiPortfolioFeedMessagesApiResponse,
      GetApiPortfolioFeedMessagesApiArg
    >({
      query: () => ({ url: `/api/portfolio/feed_messages` }),
    }),
    getApiPortfolioInvestmentEntities: build.query<
      GetApiPortfolioInvestmentEntitiesApiResponse,
      GetApiPortfolioInvestmentEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/investment_entities`,
        params: { published: queryArg.published },
      }),
    }),
    getApiPortfolio: build.query<
      GetApiPortfolioApiResponse,
      GetApiPortfolioApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio`,
        params: { investment_entity_ids: queryArg.investmentEntityIds },
      }),
    }),
    getApiPortfolioDetails: build.query<
      GetApiPortfolioDetailsApiResponse,
      GetApiPortfolioDetailsApiArg
    >({
      query: () => ({ url: `/api/portfolio/details` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as portfolioApi };
export type GetApiPortfolioActivityLogItemsApiResponse =
  /** status 200 success */ {
    items: {
      createdAt: number;
      contribution?: {
        id: number;
        amountCents: number;
        date: string;
        objectName: string;
        investmentClass: 'a' | 'b';
      };
      distribution?: {
        id: number;
        amountCents: number;
        date: string;
        objectName: string;
        period: string;
        periodType: string;
        kinds?: string[];
      };
      feedMessage?: {
        id: number;
        createdAt: number;
        createdBy: {
          fullName: string;
        };
        feedType: {
          title: string | null;
          color: string | null;
        };
        objectName: string;
      };
    }[];
    meta: {
      totalSize: number;
    };
  };
export type GetApiPortfolioActivityLogItemsApiArg = {
  page?: number;
  perPage?: number;
  type?: 'transactions' | 'feed_messages';
};
export type GetApiPortfolioContributionsByIdApiResponse =
  /** status 200 success */ ContributionDto;
export type GetApiPortfolioContributionsByIdApiArg = {
  id: number;
};
export type GetApiPortfolioDistributionsByIdApiResponse =
  /** status 200 success */ DistributionDto;
export type GetApiPortfolioDistributionsByIdApiArg = {
  id: number;
};
export type GetApiPortfolioDocumentsRecentApiResponse =
  /** status 200 success */ {
    items: {
      id: string;
      title: string;
      size: number;
      extension: string;
      confidential: boolean;
      url?: string;
    }[];
    meta: {
      totalCount: number;
    };
  };
export type GetApiPortfolioDocumentsRecentApiArg = {
  page?: number;
  perPage?: number;
};
export type GetApiPortfolioFeedMessagesApiResponse = /** status 200 success */ {
  items: {
    id: number;
    createdAt: number;
    feedType: {
      id: number;
      active: boolean;
      color: string;
      internal?: boolean | null;
      title: string;
    };
    object: {
      id: number;
      name: string;
      objectType: string;
      slug: string;
    };
    user: {
      id: string;
      fullName: string;
    };
  }[];
  meta: {
    totalCount: number;
  };
};
export type GetApiPortfolioFeedMessagesApiArg = void;
export type GetApiPortfolioInvestmentEntitiesApiResponse =
  /** status 200 success */ {
    id: number;
    name: string;
    assets: {
      id: number;
      name: string;
      slug: string;
    }[];
    funds: {
      id: string;
      name: string;
      slug: string;
    }[];
  }[];
export type GetApiPortfolioInvestmentEntitiesApiArg = {
  published?: boolean;
};
export type GetApiPortfolioApiResponse = /** status 200 success */ {
  snapshots: {
    contributed: {
      total: number;
      activeInvestmentsCount: number;
      contributedPercent: number;
    };
    distributed: {
      total: number;
      totalDistributionsCount: number;
      cashOnCashPercent: number;
    };
  };
  contributionOverview: {
    name: string;
    value: number;
  }[];
  capitalBreakdown: {
    name: string;
    contributed: number;
    distributed: number;
  }[];
  capitalHistory: {
    date: string;
    distributed: number;
    contributed: number;
    contributionAmount: number;
    distributionAmount: number;
    capitalInvestmentId: number;
  }[];
  capitalDetails: {
    asOfDate: number;
    rows: {
      investmentObjectName: string;
      entityName: string;
      yearInvested: number;
      capitalInvested: number;
      capitalInvestedCents: number;
      capitalBalanceCents: number;
      capitalDistributed: number;
      investmentClasses: ('a' | 'b')[];
      contributions: {
        id: number;
        amount: number;
        date: string;
      }[];
      distributions: {
        id: number;
        amount: number;
        date: string;
        kind:
          | 'preferred'
          | 'excess_cash'
          | 'refinance'
          | 'sale'
          | 'liquidation'
          | 'split_items'
          | 'return_of_capital';
        splitItems: {
          id: string;
          amountCents: number;
          kind:
            | 'preferred'
            | 'excess_cash'
            | 'refinance'
            | 'sale'
            | 'liquidation'
            | 'split_items'
            | 'return_of_capital';
        }[];
      }[];
    }[];
  };
};
export type GetApiPortfolioApiArg = {
  investmentEntityIds?: number[];
};
export type GetApiPortfolioDetailsApiResponse = /** status 200 success */ {
  id?: number;
  aasmState?: string;
  assets: {
    id: number;
    aasmState: string;
    address: string;
    info: any;
    name: string;
    objectType: string;
    offering: boolean;
    slug: string;
    smallPictureUrl: string | null;
    stage: string | null;
  }[];
  funds: {
    id: number;
    aasmState: string;
    assets: {
      id: number;
      aasmState: string;
      address: string;
      info: any;
      name: string;
      objectType: string;
      offering: boolean;
      slug: string;
      smallPictureUrl: string | null;
      stage: string | null;
    }[];
    info: any;
    name: string;
    objectType: string;
    offering: boolean;
    slug: string;
    smallPictureUrl: string | null;
  }[];
  notifications: {
    id: number;
    actor: {
      id: string;
      email: string;
      fullName: string;
    };
    createdAt: number;
    notifiableId: number;
    notifiableLink: string;
    notifiableType: string;
    notificationType: string;
    text: string;
    viewed: boolean;
  }[];
  dataExists: boolean;
};
export type GetApiPortfolioDetailsApiArg = void;
export type ContributionDto = {
  id: number;
  amountCents: number;
  date: string;
  investmentEntityName: string;
  investmentObjectName: string;
  investmentObjectType: string;
  investmentClass: 'a' | 'b';
};
export type DistributionDto = {
  id: number;
  amountCents: number;
  date: string;
  period: string;
  periodType: 'monthly' | 'quarterly';
  investmentEntityName: string;
  investmentObjectName: string;
  investmentObjectType: string;
  kind:
    | 'preferred'
    | 'excess_cash'
    | 'refinance'
    | 'sale'
    | 'liquidation'
    | 'split_items'
    | 'return_of_capital';
  splitItems: {
    id: number;
    amountCents: number;
    kind:
      | 'preferred'
      | 'excess_cash'
      | 'refinance'
      | 'sale'
      | 'liquidation'
      | 'split_items'
      | 'return_of_capital';
  }[];
};
