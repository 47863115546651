import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { FormulaCard } from '@/bundles/Shared/entities/formula';
import { SYNC_CATEGORIES } from '@/entities/report/syncs/config';
import { SyncKindContext } from '@/entities/report/syncs/lib/syncKindContext';
import { SyncingKindPage } from '@/pages/settings/syncing/[syncKind]/page';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ResizablePanel } from '@/shared/ui/Resizable';
import { EntityItemList } from '@/widgets/report/manualVariable/settings';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { useMemo, type FC } from 'react';

export const SettingsSyncingPage: FC<RouteComponentProps> = () => {
  const navigate = useNavigate();

  const { idFromURL: syncingKindIdFromUrl } = useNavigateToFirst({
    items: SYNC_CATEGORIES,
    matchParamKey: 'syncingKindId',
    path: ROUTES_ROOT.settings.report.syncing.syncingKind.fullPath,
  });

  const navigateToThreshold = (syncingKindId: string) => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.syncing.syncingKind.fullPath, {
        pathParams: { syncingKindId },
      }),
    );
  };

  const selectedSyncingKind = useMemo(() => {
    return SYNC_CATEGORIES.find(({ id }) => id === syncingKindIdFromUrl);
  }, [syncingKindIdFromUrl]);

  return (
    <ResizablePanel.Group
      className="max-h-screen"
      autoSaveId="syncing-panel"
      direction="horizontal"
    >
      <ResizablePanel defaultSize={0} className="min-w-[320px] max-w-[800px]">
        <EntityItemList.Wrapper>
          <EntityItemList.Header>
            <EntityItemList.Title>Syncing</EntityItemList.Title>
          </EntityItemList.Header>
          <NavigationCardList>
            <EntityItemList isFetching={false} isLoading={false}>
              {SYNC_CATEGORIES.map((sc) => (
                <FormulaCard
                  className="!cursor-pointer"
                  label={sc.label}
                  key={sc.id}
                  selected={sc.id === syncingKindIdFromUrl}
                  buttonProps={{
                    onClick: () => {
                      navigateToThreshold(sc.id);
                    },
                  }}
                />
              ))}
            </EntityItemList>
          </NavigationCardList>
        </EntityItemList.Wrapper>
      </ResizablePanel>
      <ResizablePanel.Handle withHandle />
      <ResizablePanel className="overflow-auto">
        {selectedSyncingKind && (
          <SyncKindContext.Provider value={selectedSyncingKind.id}>
            <SyncingKindPage
              syncingKind={selectedSyncingKind}
              path={ROUTES_ROOT.settings.report.syncing.syncingKind.fullPath}
            />
          </SyncKindContext.Provider>
        )}
      </ResizablePanel>
    </ResizablePanel.Group>
  );
};
