import type { ObjAndStateProps } from '@/bundles/REport/components/financials/types';
import FilterBar from '@/bundles/Shared/components/Filters/common/filterBar/FilterBar';
import FilterItem from '@/bundles/Shared/components/Filters/common/filterItem/FilterItem';
import type { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import type { ListOption } from '@/stories/Checkbox/CheckList';

export function FinancialsObjectLegalEntities({
  object,
  onCheck,
}: {
  object: ObjAndStateProps;
  onCheck: (option: ListOption<LegalEntity['id']>) => void;
}) {
  return (
    <FilterBar
      isCollapsible
      className={cn('inline-flex')}
      title="Legal Entities"
    >
      {object.legalEntities.map((le) => (
        <FilterItem
          key={le.id}
          label={le.name}
          hidden={!le._selected}
          onHide={() => onCheck({ label: le.name, value: String(le.id) })}
        />
      ))}
    </FilterBar>
  );
}
