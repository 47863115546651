import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard';
import { maxIdGenerator } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib';
import {
  TableBaseConfigColumn,
  TableVizConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { buildColumnGroupsMap } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { arrayMoveImmutable } from 'array-move';
import { produce } from 'immer';
import { orderBy } from 'lodash-es';

type WidgetSectionVizConfig = {
  viz_config: TableVizConfig;
  columns: TableBaseConfigColumn[];
};

const moveGroup = <T extends WidgetSectionVizConfig>(
  { fromIndex, toIndex }: { fromIndex: number; toIndex: number },
  config: T,
): T => {
  return produce(config, (draft) => {
    draft.viz_config.column_groups = arrayMoveImmutable(
      draft.viz_config.column_groups,
      fromIndex,
      toIndex,
    ).map((group, index) => ({
      ...group,
      order: index,
    }));
  });
};

const removeGroup = <T extends WidgetSectionVizConfig>(
  { groupId }: { groupId: string },
  config: T,
): T => {
  return produce(config, (draft) => {
    draft.viz_config.column_groups = draft.viz_config.column_groups.filter(
      (cg) => cg.group_id !== groupId,
    );
    draft.viz_config.columns.forEach((draftCol) => {
      if (draftCol.group_id === groupId) {
        draftCol.group_id = null;
      }
    });
  });
};

const removeColumn = <T extends WidgetSectionVizConfig>(
  { columnKey }: { columnKey: string },
  config: T,
): T => {
  return produce(config, (draft) => {
    draft.columns = draft.columns.filter((c) => c.key.toString() !== columnKey);
    draft.viz_config.columns = draft.viz_config.columns.filter(
      (c) => c.key !== columnKey,
    );
  });
};

const moveColumn = <T extends WidgetSectionVizConfig>(
  {
    fromIndex,
    toIndex,
    sourceGroupId,
    targetGroupId,
  }: {
    fromIndex: number;
    toIndex: number;
    sourceGroupId: string;
    targetGroupId: string;
  },
  config: T,
): T => {
  return produce(config, (draft) => {
    const columnGroups = buildColumnGroupsMap(draft.viz_config.columns);

    if (sourceGroupId === targetGroupId) {
      columnGroups[sourceGroupId] = arrayMoveImmutable(
        orderBy(columnGroups[sourceGroupId], 'order'),
        fromIndex,
        toIndex,
      );
    } else {
      const columnDef = columnGroups[sourceGroupId][fromIndex];
      columnDef.group_id =
        targetGroupId === UNGROUPED_GROUP_KEY ? null : targetGroupId;
      columnGroups[sourceGroupId].splice(fromIndex, 1);
      if (columnGroups[targetGroupId] == null) {
        columnGroups[targetGroupId] = [];
      }
      columnGroups[targetGroupId].splice(toIndex, 0, columnDef);
    }

    Object.entries(columnGroups).forEach(([_, columns]) => {
      columns.forEach((draftCol, index) => {
        draftCol.order = index;
      });
    });
  });
};

export const cloneColumn = <T extends WidgetSectionVizConfig>(
  {
    columnKey,
    groupId,
  }: {
    columnKey: number;
    groupId?: string;
  },
  config: T,
): T => {
  const columnIndex = config.columns.findIndex(
    (c) => String(c.key) === String(columnKey),
  );
  return produce(config, (draft) => {
    const column = draft.columns[columnIndex];

    const newColumn = {
      ...column,
      key: maxIdGenerator(draft.columns, 'key'),
    };
    draft.columns.push(newColumn);
    const columnSettings = draft.viz_config.columns.find(
      (c) => c.key.toString() === column.key.toString(),
    );
    const newColumnId = newColumn.key.toString();
    draft.viz_config.columns.push({
      ...columnSettings,
      order: draft.viz_config.columns.length,
      key: newColumnId,
      col_id: newColumnId,
      group_id: groupId ?? null,
      initial_sort: undefined,
    });
  });
};

const toggleColumnHidden = <T extends WidgetSectionVizConfig>(
  {
    columnId,
  }: {
    columnId: string;
  },
  config: T,
): T => {
  return produce(config, (draft) => {
    const columnIndex = draft.columns.findIndex(
      (c) => c.key.toString() === String(columnId),
    );
    if (columnIndex === -1) return;

    const vizConfigColumnIndex = draft.viz_config.columns.findIndex(
      (c) => c.key.toString() === columnId,
    );
    if (vizConfigColumnIndex === -1) return;

    draft.viz_config.columns[vizConfigColumnIndex].hidden =
      !draft.viz_config.columns[vizConfigColumnIndex].hidden;
  });
};

const toggleGroupHidden = <T extends WidgetSectionVizConfig>(
  {
    groupId,
  }: {
    groupId: string;
  },
  config: T,
): T => {
  return produce(config, (draft) => {
    if (groupId === UNGROUPED_GROUP_KEY) {
      const hidden = draft.viz_config.columns
        .filter((c) => c.group_id == null)
        .every((c) => c.hidden);

      draft.viz_config.columns.forEach((draftCol) => {
        if (draftCol.group_id == null) {
          draftCol.hidden = !hidden;
        }
      });
      return;
    }

    const index =
      draft.viz_config?.column_groups?.findIndex(
        (cd) => cd.group_id.toString() === groupId,
      ) ?? -1;

    if (index === -1) {
      return;
    }

    const hidden = !draft.viz_config.column_groups[index].hidden;

    draft.viz_config.column_groups[index].hidden = hidden;

    draft.viz_config.columns.forEach((draftCol) => {
      if (draftCol.group_id === groupId) {
        draftCol.hidden = hidden;
      }
    });
  });
};

const toggleAllColumnsHidden = <T extends WidgetSectionVizConfig>(
  newValue: boolean,
  config: T,
): T => {
  return produce(config, (draft) => {
    draft.viz_config?.column_groups?.forEach((draftGroup) => {
      draftGroup.hidden = !newValue;
    });
    draft.viz_config?.columns?.forEach((draftColumn) => {
      draftColumn.hidden = !newValue;
    });
  });
};

export const commonTableWidgetUpdaters = {
  moveGroup,
  removeGroup,
  toggleGroupHidden,

  moveColumn,
  removeColumn,
  cloneColumn,
  toggleColumnHidden,
  toggleAllColumnsHidden,
};
