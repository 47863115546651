import { NotFound } from '@/bundles/Errors';
import {
  REPORT_BUILDER_DATE_FORMAT_MAP,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
} from '@/entities/report/reportBuilder';
import { resolveReportBuilderGroupHeader } from '@/entities/report/reportBuilder/lib/resolveReportBuilderGroupHeader';
import { ReportBuilderWidget } from '@/entities/report/reportBuilder/ui/ReportBuilderWidget';
import { ReportPDFHeader } from '@/entities/report/reportBuilder/ui/ReportPDFHeader';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { PdfUI } from '@/shared/ui/PdfUI';
import { AnimationLoader } from '@/stories';
import { RouteComponentProps, useParams } from '@reach/router';
import { FC } from 'react';

export const ReportViewPage: FC<RouteComponentProps> = () => {
  const params = useParams();

  const [searchParams] = useSearchParams();
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));

  const assetId = useAssetIdSearchParam();
  const { data, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery(
      {
        id: params.id,
        assetId: assetId!,
      },
      {
        skip: assetId == null,
      },
    );
  if (isLoading) return <AnimationLoader />;

  if (data?.report == null) {
    return <NotFound />;
  }

  return (
    <PdfUI>
      {data.report.groups.map((g, gIdx) => (
        <PdfUI.WidgetGroup
          hidePlaceholders={hidePlaceholders}
          {...g}
          key={g.id}
        >
          {resolveReportBuilderGroupHeader({
            displayHeaderGlobal: data.report.displayHeader,
            displayHeaderGroup: g.displayHeader,
          }) && (
            <ReportPDFHeader
              objectAddress={data.meta.asset?.address ?? ''}
              objectName={data.meta.asset?.name ?? ''}
              reportTitle={data.report.name}
              reportSubtitle={data.report.subtitle}
              reportDate={
                data.report.dateFormat != null
                  ? REPORT_BUILDER_DATE_FORMAT_MAP[
                      data.report.dateFormat
                    ]?.dateFormatter(data.report.date)
                  : undefined
              }
            />
          )}
          {g.widgets.map((w, index) => (
            <ReportBuilderWidget
              reportData={data.report}
              key={w.id}
              widgetSection={w}
              pageOrientation={g.pageOrientation}
              isLastInGroup={
                gIdx === data.report.groups.length - 1 &&
                index === g.widgets.length - 1
              }
            />
          ))}
        </PdfUI.WidgetGroup>
      ))}
    </PdfUI>
  );
};
