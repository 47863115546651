import FilterItem from '@/bundles/Shared/components/Filters/common/filterItem/FilterItem';
import type { LegalEntity } from '@/entities/core/legalEntity';
import { addOrRemoveItemInList } from '@/shared/lib/listHelpers/addOrRemoveItemInList';

export const ReportBuilderFormLegalEntities = ({
  value: formLegalEntityIds,
  onChange,
  hidden,
  options,
}: {
  value: LegalEntity['id'][];
  onChange: (ids: LegalEntity['id'][]) => void;
  options: Pick<LegalEntity, 'id' | 'code' | 'name'>[];
  hidden?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-tw-2 px-tw-1">
      <p className="inline-semibold p-tw-1 text-neutral-850">Legal Entities</p>
      <div className="flex flex-wrap gap-tw-2 p-tw-1">
        {options.map((l) => {
          const isOnlyOneLeft = formLegalEntityIds.length === 1;
          const hideBtnDisabled =
            (isOnlyOneLeft && formLegalEntityIds.includes(l.id)) || hidden;

          return (
            <FilterItem
              key={l.id}
              label={l.name}
              hidden={!formLegalEntityIds.includes(l.id)}
              hideBtnDisabled={hideBtnDisabled}
              tooltipProps={
                hideBtnDisabled
                  ? {
                      mainText: 'At least one Legal Entity should be active',
                    }
                  : undefined
              }
              onHide={() => {
                const newLEIds = addOrRemoveItemInList(
                  l.id,
                  formLegalEntityIds,
                );

                onChange(newLEIds);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
