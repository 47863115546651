import { OBJECT_DASHBOARD_SECTION_TYPE } from '@/bundles/Shared/entities/dashboard';
import {
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { DefaultColumnsTab } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable/config/component';
import { UnitTypeTableWidgetConfigColumnPanel } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/column';
import ThinTabGroup, {
  IThinTabItem,
} from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useForm } from 'react-hook-form';
import { useTabs } from '@/stories/Tabs/useTabs';
import { PeriodShiftField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { UpdateWidgetTitle } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/UpdateWidgetTitle';
import { Field } from '@/stories';
import { unitTypeTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/updaters';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';
import { FieldsContainer } from '@/stories/Field/Field';
import { HideTotalField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import {
  UnitTypeTableWidgetConfigForm,
  transformUnitTypeTableWidgetConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/component.form';
import { BulkGroupConfigModal } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group';
import { TumblerController } from '@/stories/Tumbler/Tumbler';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
] as const satisfies readonly IThinTabItem[];

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const methods = useForm<UnitTypeTableWidgetConfigForm>({
    defaultValues: {
      ...transformUnitTypeTableWidgetConfigToForm(widget.widgetConfig),
    },
  });

  const { control } = methods;

  useUpdateWidgetConfigEffect<
    UnitTypeTableWidgetConfigForm,
    typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE
  >(methods, unitTypeTableUpdaters.updateDefaultOptions);

  return (
    <FieldsContainer>
      <UpdateWidgetTitle />
      <Field labelText="Period Shift">
        <PeriodShiftField control={control} name="period_shift" />
      </Field>
      <Field>
        <TumblerController control={control} name="hide_floorplan_rows">
          Disable Floorplan(s)
        </TumblerController>
      </Field>
      <HideTotalField fieldType="row" control={control} name="hide_total_row" />
      <RecapPageField />
    </FieldsContainer>
  );
};

export const UnitTypeWidgetConfigComponent = () => {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={UnitTypeTableWidgetConfigColumnPanel}
            BulkGroupModal={BulkGroupConfigModal}
            widgetType={OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE}
          />
        )}
      </div>
    </div>
  );
};
