import { App } from '@/app';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { LicenseManager } from 'ag-grid-enterprise';
import { createRoot } from 'react-dom/client';
import toastr from 'toastr';

import { RUM_ISOLATION_CLASSNAME } from '@/app/config';
import { IS_RUM_SUBDOMAIN } from '@/lib/http';
import 'progress-tracker/src/styles/progress-tracker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import store from './app/stores';
import './shared/stylesheets/application.scss';
import './shared/stylesheets/tailwindcss/index.css';

window.toastr = toastr;
// Toastr configuration
window.toastr.options = {
  progressBar: true,
  closeButton: true,
  timeOut: 4000,
  closeHtml: '<button></button>',
  positionClass: 'toast-top-center',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  closeMethod: 'fadeOut',
};

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_KEY);

if (import.meta.env.MODE === 'development') {
  LicenseManager.prototype.outputMissingLicenseKey = () => {};
}
if (IS_RUM_SUBDOMAIN) {
  document.body.classList.add(RUM_ISOLATION_CLASSNAME);
}

const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
