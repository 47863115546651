import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { GlobalLeaseTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/widget';
import Icon from 'stories/Icon/Icon';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { cn } from '@/shared/lib/css/cn';
import { BasicAutoGroupCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import React from 'react';
import { startCase } from 'lodash-es';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { GlobalLeaseTableWidgetDto } from '@/bundles/Shared/entities/dashboard';

export const GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Global Lease Table',
  icon: 'table',
  Component: GlobalLeaseTableWidget,
} as const satisfies WidgetConfiguration;

export const DEFAULT_GLOBAL_LEASE_TABLE_CONFIG = {} as const;

export const UNIT_COL_ID = '7';
export const ASSET_NAME_ID = '6';
export const LEASE_TYPE_ID = '3';
export const UNIT_TYPE_ID = '5';
export const FILTERED_TRADEOUT_COL_ID = '19';

export const GLOBAL_LEASE_VIZ_CONFIG = {
  column_groups: [
    {
      group_id: 'general',
      header_name: 'General',
      order: 0,
      background: 'rgba(11, 23, 48, 1)',
      border_color: 'rgba(38, 51, 79, 1)',
    },
    {
      group_id: 'unitDetails',
      header_name: 'Unit Details',
      order: 1,
      background: 'rgba(11, 98, 79, 1)',
      border_color: 'rgba(19, 137, 117, 1)',
    },
    {
      group_id: 'leaseDetails',
      header_name: 'Lease Details',
      order: 2,
      background: 'rgba(3, 40, 98, 1)',
      border_color: 'rgba(56, 83, 169, 1)',
    },
    {
      group_id: 'previousLeaseDetails',
      header_name: 'Previous Lease Details',
      order: 3,
      background: 'rgba(3, 40, 98, 1)',
      border_color: 'rgba(56, 83, 169, 1)',
    },
  ],
  columns: [
    {
      col_id: '0',
      key: '0',
      order: 0,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '1',
      key: '1',
      order: 1,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '2',
      key: '2',
      order: 2,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '3',
      key: '3',
      order: 3,
      group_id: 'general',
    },
    {
      col_id: '4',
      key: '4',
      order: 4,
      group_id: 'general',
    },
    {
      col_id: UNIT_TYPE_ID,
      key: '5',
      order: 5,
      group_id: 'unitDetails',
    },
    {
      col_id: ASSET_NAME_ID,
      key: '6',
      order: 6,
      group_id: 'unitDetails',
    },
    {
      col_id: UNIT_COL_ID,
      key: '7',
      order: 7,
      group_id: 'unitDetails',
    },
    {
      col_id: '8',
      key: '8',
      order: 8,
      group_id: 'unitDetails',
      value_display_options: {
        type: 'sqft',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '9',
      key: '9',
      order: 9,
      group_id: 'leaseDetails',
    },
    {
      col_id: '10',
      key: '10',
      order: 10,
      group_id: 'leaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      col_id: '11',
      key: '11',
      order: 11,
      group_id: 'leaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      col_id: '12',
      key: '12',
      order: 12,
      group_id: 'leaseDetails',
    },
    {
      col_id: '13',
      key: '13',
      order: 13,
      group_id: 'leaseDetails',
    },
    {
      col_id: '14',
      key: '14',
      order: 14,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: '15',
      key: '15',
      order: 15,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: '16',
      key: '16',
      order: 16,
      group_id: 'previousLeaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      col_id: '17',
      key: '17',
      order: 17,
      group_id: 'previousLeaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      col_id: '18',
      key: '18',
      order: 18,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: FILTERED_TRADEOUT_COL_ID,
      key: '19',
      order: 19,
    },
  ],
  rows: [],
  grid_options: {
    group_default_expanded: 1,
  },
} as const satisfies TableVizConfig;

export const COL_DEF_OVERRIDES: Record<string, Partial<ColDef>> = {
  [UNIT_COL_ID]: {
    cellRendererParams: {
      linkable: true,
      classes: {
        inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic),
      },
    } satisfies BasicAutoGroupCellRendererProps,
  },
  '3': {
    valueFormatter: (params) => startCase(params.value),
  },
  '15': {
    valueFormatter: (params) => startCase(params.value),
  },
  '19': {
    cellRendererParams: {
      classes: {
        inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic, '!justify-center'),
      },
    },
    tooltipValueGetter: (params) => {
      const row: GlobalLeaseTableWidgetDto['rows'][number] = params.data;

      return row.filtered_reasons?.join(',');
    },
    cellRenderer: (params: ICellRendererParams) => (
      <BasicCellRenderer {...params}>
        <Icon
          className={params.value ? 'text-success-055' : 'text-danger-055'}
          iconName={params.value ? 'check' : 'closeSmall'}
        />
      </BasicCellRenderer>
    ),
  },
};
