import type {
  PostApiCustomerBridgeSyncsApiArg,
  SyncDto,
} from '@/entities/report/syncs/api/syncsGeneratedApi';
import type { IconsId } from '@/types/sre-icons';

export const SYNC_CATEGORIES = [
  {
    id: 'report_financial_category',
    label: 'Financial Categories',
  },
  {
    id: 'report_system_manual_variables',
    label: 'Manual Variables',
  },
  {
    id: 'reckoner_system_formulas',
    label: 'Financial Formulas',
  },
  {
    id: 'recap_pages',
    label: 'FAQ Pages',
  },
] as const satisfies {
  id: PostApiCustomerBridgeSyncsApiArg['body']['kind'];
  label: string;
}[];

export const SYNC_STATUS_UI_CONFIG = {
  completed: {
    label: 'Completed',
    iconClassName: 'text-neutral-000 bg-success-055',
    textClassName: 'text-success-055',
    iconName: 'checkSmall',
  },
  in_progress: {
    label: 'In Progress',
    iconClassName: 'text-info-055',
    textClassName: 'text-info-055',
    iconName: 'sync',
  },
  failed: {
    label: 'Failed',
    iconClassName: 'text-neutral-000 bg-danger-055',
    textClassName: 'text-danger-070',
    iconName: 'closeSmall',
  },
} satisfies Record<
  SyncDto['status'],
  {
    label: string;
    iconName: IconsId;
    iconClassName: string;
    textClassName: string;
  }
>;

export const DEFAULT_SYNC_PAGE_POLLING_INTERVAL_MS = 1000 * 60 * 15; // 15 minutes
