import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { CssVar } from '@/shared/config/cssVar';
import { CssCustomProperty } from '@/shared/lib/css/cssCustomProperty';
import { getOptionsValues } from '@/shared/lib/listHelpers/';
import {
  RangePeriod,
  ReckonerPeriod,
  TrailingPeriod,
} from '@/shared/lib/reckoner/model';
import {
  PeriodShiftType,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard/model/types';
import { capitalize, startCase, upperCase } from 'lodash-es';
import { ListOption } from 'stories/Checkbox/CheckList';
import { CurrencyFormatterClasses } from 'stories/ValueFormatters/CurrencyFormatter';
import * as yup from 'yup';
import { ReadonlyDeep } from 'type-fest';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

export const TOTAL_TEXT_CLASS = '!font-semibold !text-neutral-850';
export const TOTAL_BG_CLASS = '!bg-neutral-100';
export const TOTAL_BG_COLOR = CssVar.neutral100;
export const TOTAL_TEXT_COLOR = CssVar.neutral850;
export const TOTAL_BG_CSS_CUSTOM_PROPERTY_REF =
  CssCustomProperty.getRef('neutral-100');

export const TOTAL_NUMBER_FORMATTER_CLASSES = {
  allParts: 'font-semibold text-neutral-850',
} as const satisfies CurrencyFormatterClasses;

export const TIME_PERIOD_TYPES = [
  'day',
  'week',
  'month',
  'quarter',
  'qtd',
  'year',
  'ytd',
  'mtd',
] as const;

export type TimePeriodType = (typeof TIME_PERIOD_TYPES)[number];

export const PERIOD_TYPE_MAP = Object.fromEntries(
  TIME_PERIOD_TYPES.map((item) => [
    item,
    {
      id: item,
      label: capitalize(item),
      value: item,
    },
  ]),
) as Record<
  (typeof TIME_PERIOD_TYPES)[number],
  {
    id: string;
    label: string;
    value: (typeof TIME_PERIOD_TYPES)[number];
  }
>;

export const PERIOD_TYPE_OPTIONS = [
  {
    label: 'Time',
    value: 'time',
  },
  {
    label: 'Trailing',
    value: 'trailing',
  },
] as const satisfies readonly ListOption[];
export const TIME_PERIOD_TYPE_OPTIONS = Object.values(PERIOD_TYPE_MAP);

export const TRAILING_PERIOD_TYPES = [
  'month',
  'quarter',
  'day',
  'year',
] as const;

export type TrailingPeriodType = (typeof TRAILING_PERIOD_TYPES)[number];

export const AVAILABLE_PERIOD_TYPES = {
  T3: 't3',
  T6: 't6',
  T12: 't12',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
  ITD: 'itd',
  MTD: 'mtd',
  YTD: 'ytd',
  MTM: 'mtm',
} as const satisfies Partial<
  Record<Uppercase<ReckonerPeriod['type']>, ReckonerPeriod['type']>
>;

/**
 * The list of `ReckonerPeriod` types that can not be selected as a default period type
 * since there are more than one param involved. Example: `from_date` & `to_date`, `count`.
 */
export const PERIOD_TYPE_UNAVAILABLE_FOR_DEFAULT_PERIOD_TYPE = [
  'mtm',
  'dtd',
  'trailing_days',
  'trailing_months',
  'trailing_quarters',
  'trailing_years',
] as const satisfies (RangePeriod['type'] | TrailingPeriod['type'])[];

export type AvailablePeriodType = Values<typeof AVAILABLE_PERIOD_TYPES>;

export const AVAILABLE_PERIOD_TYPES_OPTIONS = (
  [
    {
      value: AVAILABLE_PERIOD_TYPES.T3,
      label: 'T3',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.T6,
      label: 'T6',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.T12,
      label: 'T12',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.MONTH,
      label: 'Month',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.QUARTER,
      label: 'Quarter',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.YEAR,
      label: 'Year',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.MTD,
      label: 'MTD',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.YTD,
      label: 'YTD',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.ITD,
      label: 'ITD',
    },
    {
      value: AVAILABLE_PERIOD_TYPES.MTM,
      label: 'Custom Range', // actually "Month to month", but it was confusing a bit
    },
  ] as const satisfies readonly ListOption[]
).map((o) => ({
  ...o,
  id: o.value,
}));

const TRAILING_PERIOD_SCHEMA = yup.object().shape({
  type: yup.string().oneOf(TRAILING_PERIOD_TYPES).required(),
  amount: yup.number().required(),
});
type TrailingPeriodForm = yup.InferType<typeof TRAILING_PERIOD_SCHEMA>;

export type PeriodTypeForm = {
  periodType: 'time' | 'trailing';
  value: (typeof TIME_PERIOD_TYPES)[number] | TrailingPeriodForm;
};
export const PERIOD_TYPE_SCHEMA = yup.object<PeriodTypeForm>().shape({
  periodType: yup
    .string()
    .oneOf(getOptionsValues(PERIOD_TYPE_OPTIONS))
    .required(),
  value: yup.lazy((_, { parent }) => {
    return parent.periodType === 'time'
      ? yup.string().oneOf(TIME_PERIOD_TYPES).required()
      : TRAILING_PERIOD_SCHEMA;
  }),
});

export const ADJUSTMENTS = {
  OP_VARIANCE: 'op_variance',
  OP_VARIANCE_PERCENT: 'op_variance_percent',
  OP_VARIANCE_FLIPPED: 'op_variance_flipped',
  OP_VARIANCE_FLIPPED_PERCENT: 'op_variance_flipped_percent',
  OP_BUDGET: 'op_budget',
  UW_VARIANCE: 'uw_variance',
  UW_VARIANCE_PERCENT: 'uw_variance_percent',
  UW_VARIANCE_FLIPPED: 'uw_variance_flipped',
  UW_VARIANCE_FLIPPED_PERCENT: 'uw_variance_flipped_percent',
  UW_BUDGET: 'uw_budget',
  ANNUALIZED: 'annualized',
  OP_BUDGET_ANNUALIZED: 'op_budget_annualized',
  UW_BUDGET_ANNUALIZED: 'uw_budget_annualized',
  FINANCIAL_T1A: 'financial_t1a',
  FINANCIAL_T3A: 'financial_t3a',
  FINANCIAL_BEGINNING_BALANCE: 'financial_beginning_balance',
  FINANCIAL_ENDING_BALANCE: 'financial_ending_balance',
  FINANCIAL_DEBIT: 'financial_debit',
  FINANCIAL_CREDIT: 'financial_credit',
  PERIOD_TO_PERIOD_VARIANCE: 'period_to_period_variance',
  PERIOD_TO_PERIOD_VARIANCE_PERCENT: 'period_to_period_variance_percent',
  YEAR_TO_YEAR_VARIANCE: 'year_to_year_variance',
  YEAR_TO_YEAR_VARIANCE_PERCENT: 'year_to_year_variance_percent',
  YEAR_TO_YEAR_VARIANCE_FLIPPED: 'year_to_year_variance_flipped',
  YEAR_TO_YEAR_VARIANCE_FLIPPED_PERCENT:
    'year_to_year_variance_flipped_percent',
  PERIOD_TO_PERIOD_VARIANCE_FLIPPED: 'period_to_period_variance_flipped',
  PERIOD_TO_PERIOD_VARIANCE_FLIPPED_PERCENT:
    'period_to_period_variance_flipped_percent',
} as const;

export const ADJUSTMENT_TYPES = {
  SQFT: 'sqft',
  UNIT: 'unit',
  UNIT_AVAILABLE: 'unit_available',
  UNIT_OCCUPIED: 'unit_occupied',
  UNIT_PRELEASED: 'unit_preleased',
  UNIT_VACANT: 'unit_vacant',
} as const satisfies Record<
  string,
  BaseFinancialTableWidgetConfigBaseColumnDto['adjustment_average_per']
>;

export const ADJUSTMENT_TYPE_VALUE_TOTAL = 'total';

export const ADJUSTMENT_TYPE_OPTIONS = [
  {
    label: 'Total',
    value: ADJUSTMENT_TYPE_VALUE_TOTAL,
  },
  {
    label: 'Per SQFT',
    value: 'sqft',
  },
  {
    label: 'Per Unit (Total)',
    value: 'unit',
  },
  {
    label: 'Per Unit (Occupied)',
    value: 'unit_occupied',
  },
  {
    label: 'Per Unit (Vacant)',
    value: 'unit_vacant',
  },
  {
    label: 'Per Unit (Available)',
    value: 'unit_available',
  },
  {
    label: 'Per Unit (Pre-leased)',
    value: 'unit_preleased',
  },
] satisfies ListOption<Values<typeof ADJUSTMENT_TYPES>>[];

export const OVERRIDE_SOURCES = [
  'actual',
  'budget',
  'underwriting_budget',
] as const;
export type OverrideSource = (typeof OVERRIDE_SOURCES)[number];

export type AdjustmentExpressionName =
  (typeof ADJUSTMENTS)[keyof typeof ADJUSTMENTS];

export type AdjustmentExpression = {
  name: AdjustmentExpressionName;
  source_overrides?: {
    source: OverrideSource;
    override_to: string;
  }[];
};
export const ADJUSTMENT_EXPRESSION_VALUE_ACTUAL = 'actual';
export const ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT = 'no_adjustment';

export const ADJUSTMENT_EXPRESSION_OPTIONS_MAP = {
  [ADJUSTMENT_EXPRESSION_VALUE_ACTUAL]: {
    label: 'Actual',
    value: ADJUSTMENT_EXPRESSION_VALUE_ACTUAL,
  },
  [ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT]: {
    label: 'No Adjustment',
    value: null,
  },
  [ADJUSTMENTS.OP_VARIANCE]: {
    label: 'Operational Budget Variance',
    value: 'op_variance',
  },
  [ADJUSTMENTS.OP_VARIANCE_PERCENT]: {
    label: 'Operational Budget Variance Percent',
    value: 'op_variance_percent',
  },
  [ADJUSTMENTS.OP_VARIANCE_FLIPPED]: {
    value: 'op_variance_flipped',
    label: 'Operational Budget Variance Flipped',
  },
  [ADJUSTMENTS.OP_VARIANCE_FLIPPED_PERCENT]: {
    value: 'op_variance_flipped_percent',
    label: 'Operational Budget Variance Flipped Percent',
  },
  [ADJUSTMENTS.OP_BUDGET]: {
    label: 'Operational Budget',
    value: 'op_budget',
  },
  [ADJUSTMENTS.UW_VARIANCE]: {
    label: 'Underwriting Budget Variance',
    value: 'uw_variance',
  },
  [ADJUSTMENTS.UW_VARIANCE_PERCENT]: {
    label: 'Underwriting Budget Variance Percent',
    value: 'uw_variance_percent',
  },
  [ADJUSTMENTS.UW_VARIANCE_FLIPPED]: {
    value: 'uw_variance_flipped',
    label: 'Underwriting Budget Variance Flipped',
  },
  [ADJUSTMENTS.UW_VARIANCE_FLIPPED_PERCENT]: {
    value: 'uw_variance_flipped_percent',
    label: 'Underwriting Budget Variance Flipped Percent',
  },
  [ADJUSTMENTS.UW_BUDGET]: {
    value: 'uw_budget',
    label: 'Underwriting Budget',
  },
  [ADJUSTMENTS.ANNUALIZED]: {
    value: 'annualized',
    label: 'Annualized',
  },
  [ADJUSTMENTS.OP_BUDGET_ANNUALIZED]: {
    value: 'op_budget_annualized',
    label: 'Operational Budget Annualized',
  },
  [ADJUSTMENTS.UW_BUDGET_ANNUALIZED]: {
    value: 'uw_budget_annualized',
    label: 'Underwriting Budget Annualized',
  },
  [ADJUSTMENTS.FINANCIAL_T1A]: {
    value: 'financial_t1a',
    label: 'Financial T1A',
  },
  [ADJUSTMENTS.FINANCIAL_T3A]: {
    value: 'financial_t3a',
    label: 'Financial T3A',
  },
  [ADJUSTMENTS.FINANCIAL_BEGINNING_BALANCE]: {
    value: 'financial_beginning_balance',
    label: 'Financial Beginning Balance',
  },
  [ADJUSTMENTS.FINANCIAL_ENDING_BALANCE]: {
    value: 'financial_ending_balance',
    label: 'Financial Ending Balance',
  },
  [ADJUSTMENTS.FINANCIAL_DEBIT]: {
    value: 'financial_debit',
    label: 'Financial Debit',
  },
  [ADJUSTMENTS.FINANCIAL_CREDIT]: {
    value: 'financial_credit',
    label: 'Financial Credit',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE]: {
    value: 'period_to_period_variance',
    label: 'Period To Period Variance',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_PERCENT]: {
    value: 'period_to_period_variance_percent',
    label: 'Period To Period Variance Percent',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_FLIPPED]: {
    value: 'period_to_period_variance_flipped',
    label: 'Period To Period Variance Flipped',
  },
  [ADJUSTMENTS.PERIOD_TO_PERIOD_VARIANCE_FLIPPED_PERCENT]: {
    value: 'period_to_period_variance_flipped_percent',
    label: 'Period To Period Variance Flipped Percent',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE]: {
    value: 'year_to_year_variance',
    label: 'Year To Year Variance',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_PERCENT]: {
    value: 'year_to_year_variance_percent',
    label: 'Year To Year Variance Percent',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_FLIPPED]: {
    value: 'year_to_year_variance_flipped',
    label: 'Year To Year Variance Flipped',
  },
  [ADJUSTMENTS.YEAR_TO_YEAR_VARIANCE_FLIPPED_PERCENT]: {
    value: 'year_to_year_variance_flipped_percent',
    label: 'Year To Year Variance Flipped Percent',
  },
} as const satisfies Record<
  | Values<typeof ADJUSTMENTS>
  | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
  | typeof ADJUSTMENT_EXPRESSION_VALUE_NO_ADJUSTMENT,
  {
    value:
      | Values<typeof ADJUSTMENTS>
      | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL
      | null;
    label: string;
  }
>;

type ADJUSTMENT_EXPRESSION_OPTIONS = ListOption<
  Values<typeof ADJUSTMENTS> | typeof ADJUSTMENT_EXPRESSION_VALUE_ACTUAL | null
>[];

export const ADJUSTMENT_EXPRESSION_OPTIONS = [
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.no_adjustment,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t1a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t3a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_beginning_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_ending_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_debit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_credit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_flipped_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_flipped,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_flipped_percent,
] satisfies ADJUSTMENT_EXPRESSION_OPTIONS;

export const FINANCIAL_TABLE_WIDGET_ADJUSTMENT_EXPRESSION_OPTIONS = [
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.actual,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.op_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.uw_budget_annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.annualized,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t1a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_t3a,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_beginning_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_ending_balance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_debit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.financial_credit,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.period_to_period_variance_percent,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance,
  ADJUSTMENT_EXPRESSION_OPTIONS_MAP.year_to_year_variance_percent,
] satisfies ADJUSTMENT_EXPRESSION_OPTIONS;

export const TOTAL_CALCULATION_STRATEGIES = {
  EVALUATE: 'evaluate',
  SUM: 'sum',
  AVG: 'avg',
  NONE: 'none',
} as const;

export type TotalCalculationStrategy =
  (typeof TOTAL_CALCULATION_STRATEGIES)[keyof typeof TOTAL_CALCULATION_STRATEGIES];

export const TOTAL_CALCULATION_STRATEGY_OPTIONS = [
  {
    label: 'No Adjustments',
    value: TOTAL_CALCULATION_STRATEGIES.EVALUATE,
  },
  {
    label: 'Sum',
    value: TOTAL_CALCULATION_STRATEGIES.SUM,
  },
  {
    label: 'Average',
    value: TOTAL_CALCULATION_STRATEGIES.AVG,
  },
  {
    label: 'Disable',
    value: TOTAL_CALCULATION_STRATEGIES.NONE,
  },
] as const satisfies readonly ListOption[];

export const COLUMN_CONFIGURATION_LABEL_INFO_TEXT =
  'Will appear as the primary label in the column cell';

const OVERRIDE_SOURCE_SCHEMA = yup.object().shape({
  source: yup.string().oneOf(OVERRIDE_SOURCES),
  override_to: yup.string(),
});

export const ADJUSTMENT_EXPRESSION_SCHEMA = yup.object().shape({
  name: yup.string().oneOf(Object.values(ADJUSTMENTS)).nullable(),
  source_overrides: yup
    .array()
    .of(OVERRIDE_SOURCE_SCHEMA)
    .optional()
    .nullable(),
});

export const ADJUSTMENT_EXPRESSION_ONE_SCHEMA = yup
  .string()
  .oneOf([...Object.values(ADJUSTMENTS), ADJUSTMENT_EXPRESSION_VALUE_ACTUAL]);

export const ADJUSTMENT_TYPE_ONE_SCHEMA = yup
  .string()
  .oneOf([...Object.values(ADJUSTMENT_TYPES), ADJUSTMENT_TYPE_VALUE_TOTAL]);

export type AdjustmentExpressionForm = yup.InferType<
  typeof ADJUSTMENT_EXPRESSION_SCHEMA
>;

export const PERIOD_SHIFT_OPTIONS = [
  'days',
  'weeks',
  'months',
  'quarters',
  'years',
] as const satisfies readonly PeriodShiftType[];

export const DEFAULT_DATE_RANGES = {
  t3: 't3',
  t6: 't6',
  t12: 't12',
  NONE: 'none',
} as const;

type DateRange = Values<typeof DEFAULT_DATE_RANGES>;

export const DEFAULT_DATE_RANGE_OPTIONS = Object.values(
  DEFAULT_DATE_RANGES,
).map((value) => ({
  value,
  label: capitalize(value),
}));
export const PERIOD_SHIFT_SCHEMA = yup.object().shape({
  key: yup.string().oneOf(PERIOD_SHIFT_OPTIONS),
  value: yup.number(),
});
export type PeriodShiftForm = yup.InferType<typeof PERIOD_SHIFT_SCHEMA>;
export const RANGE_TO_PERIOD_SHIFT_MAP = {
  t3: {
    key: 'months',
    value: 3,
  },
  t6: {
    key: 'months',
    value: 6,
  },
  t12: {
    key: 'months',
    value: 12,
  },
  none: {
    key: 'months',
    value: 0,
  },
} as const satisfies Record<DateRange, PeriodShiftForm>;

export const getRangeToDateShiftIncludeBoundaries = (
  range: Extract<AvailablePeriodType, 't3' | 't6' | 't12'>,
): number => {
  const { value } = RANGE_TO_PERIOD_SHIFT_MAP[range];
  return value - (range === 'none' ? 0 : 1);
};

export const GRANULARITIES = [
  'day',
  'week',
  'month',
] as const satisfies readonly WidgetDateGranularity[];
export const GRANULARITY_LABELS = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
} as const satisfies Record<WidgetDateGranularity, string>;
export const SORTING_VALUES = ['asc', 'desc'] as const;
export type ColumnConfigSort = (typeof SORTING_VALUES)[number];

export const FORMULA_SOURCES = {
  ACTUAL: 'actual',
  ACTUAL_BUDGET: 'actual - budget',
  ACTUAL_UNDERWRITING_BUDGET: 'actual - underwriting_budget',
  UNDERWRITING_BUDGET: 'underwriting_budget',
  OPERATIONAL_BUDGET: 'budget',
  OPERATIONAL_BUDGET_ACTUAL: 'budget - actual',
  UNDERWRITING_BUDGET_ACTUAL: 'underwriting_budget - actual',
} as const;
export type FormulaSource = Values<typeof FORMULA_SOURCES>;
export const FONT_STYLES = ['normal', 'bold'] as const;
export const FONT_STYLES_OPTIONS = FONT_STYLES.map((f) => ({
  label: startCase(f),
  value: f,
}));
export type StyleFont = (typeof FONT_STYLES)[number];
export const COLOR_RULES = [
  'negative_red',
  'negative_red_positive_green',
  'negative_green_positive_red',
] as const;
export type ColorRule = (typeof COLOR_RULES)[number];
export type StyleRule = {
  rule:
    | 'negative_red_positive_green'
    | 'negative_red'
    | 'negative_green_positive_red';
  compareTo?: string;
};
export const FORMULA_SOURCE_OPTIONS = [
  {
    label: 'No Adjustments',
    value: FORMULA_SOURCES.ACTUAL,
  },
  {
    label: 'Operational Variance',
    value: FORMULA_SOURCES.ACTUAL_BUDGET,
  },
  {
    label: 'Operational Variance Flipped',
    value: FORMULA_SOURCES.OPERATIONAL_BUDGET_ACTUAL,
  },
  {
    label: 'Variance Underwriting',
    value: FORMULA_SOURCES.ACTUAL_UNDERWRITING_BUDGET,
  },
  {
    label: 'Variance Underwriting Flipped',
    value: FORMULA_SOURCES.UNDERWRITING_BUDGET_ACTUAL,
  },
  {
    label: 'Operational Budget',
    value: FORMULA_SOURCES.OPERATIONAL_BUDGET,
  },
  {
    label: 'Underwriting Budget',
    value: FORMULA_SOURCES.UNDERWRITING_BUDGET,
  },
];
export const SORTING_OPTIONS = SORTING_VALUES.map((value) => ({
  label: upperCase(value),
  value,
}));

export const COLOR_RULE_OPTIONS = [
  {
    label: (
      <span>
        <span className="text-danger-055">Red</span> for negative values
      </span>
    ),
    value: 'negative_red',
  },
  {
    label: (
      <span>
        <span className="text-danger-055">Red</span> for negative values{' '}
        <span className="text-success-055">green</span> for positive values{' '}
      </span>
    ),
    value: 'negative_red_positive_green',
  },
  {
    label: (
      <span>
        <span className="text-danger-055">Red</span> for positive values{' '}
        <span className="text-success-055">green</span> for negative values{' '}
      </span>
    ),
    value: 'negative_green_positive_red',
  },
];

export const COMPARE_TO_COLOR_RULE_OPTIONS = [
  {
    label: (
      <span>
        <span className="text-danger-055">Red</span> for lower values{' '}
        <span className="text-success-055">green</span> for higher values{' '}
      </span>
    ),
    value: 'negative_red_positive_green',
  },
  {
    label: (
      <span>
        <span className="text-danger-055">Red</span> for higher values{' '}
        <span className="text-success-055">green</span> for lower values{' '}
      </span>
    ),
    value: 'negative_green_positive_red',
  },
];

export const DEFAULT_AM_CHART_CONFIG = {
  type: 'XYChart',
  refs: [],
} as const;

export const DEFAULT_TABLE_VIZ_CONFIG = {
  viz_config: {
    column_groups: [],
    rows: [],
    columns: [],
  },
  viz_type: 'table',
} as const satisfies ReadonlyDeep<{
  viz_config: TableVizConfig;
  viz_type: 'table';
}>;
