import { useCapitalDetailRows } from '../hooks/useCapitalDetailRows';
import { TReturnInvestmentLineItemCategory } from '@/bundles/REturn/types';
import { cn } from '@/shared/lib/css/cn';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import dayjs from 'dayjs';
import { ReactComponent as CornerArrowSvg } from 'images/corner-arrow.svg';
import { useModal } from '@/shared/lib/hooks/useModal';
import { percentNumberFormat } from '@/shared/lib/formatting/number';
import { get, sumBy } from 'lodash-es';
import { AsOfDate, CurrencyFormatter } from 'stories';
import { CssVar } from '@/shared/config/cssVar';
import styles from '../Dashboard.module.scss';
import { resolveEmptyValueJSX, TABLE_HEAD_COLUMNS } from '../constants';
import { ICapitalDetailsEntity, IRowsEntity } from '../types';
import { BreakdownModal } from './BreakdownModal';
import { GetApiPortfolioApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';
import { InvestmentEntityClassesBadges } from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';

const STRING_ROW_FIELDS = [
  'investmentObjectName',
  'stage',
] as const satisfies readonly (keyof Pick<
  IRowsEntity,
  'investmentObjectName' | 'investmentEntityName' | 'stage'
>)[];

const INVESTMENT_ENTITY_NAME_ROW_FIELD_NAME = 'investmentEntityName';

type RowField = keyof IRowsEntity;

function Th({ title, rowField }: { rowField: RowField; title: string }) {
  if (STRING_ROW_FIELDS.includes(rowField)) {
    return <th>{title}</th>;
  }

  if (rowField === INVESTMENT_ENTITY_NAME_ROW_FIELD_NAME) {
    return <th>{title}</th>;
  }

  return <th className="text-center">{title}</th>;
}

export function Amount({ amount }: { amount: number | null }) {
  if (!amount) return resolveEmptyValueJSX(false);

  return (
    <div className="flex h-full w-full justify-end">
      <CurrencyFormatter
        classes={{
          wrapper: 'secondary-regular',
          value: 'text-inherit',
        }}
        value={amount}
      />
    </div>
  );
}

const MONEY_ROW_FIELDS = [
  'capitalInvested',
  'capitalDistributed',
  'capitalBalance',
] as const satisfies readonly (keyof Pick<
  IRowsEntity,
  'capitalInvested' | 'capitalDistributed'
>)[];

function Td({
  getPath,
  row,
  rowField,
  onClick,
}: {
  row: IRowsEntity;
  rowField: string;
  getPath?: string;
  onClick?: () => void;
}) {
  if (STRING_ROW_FIELDS.includes(rowField)) {
    return <td>{row[rowField] || resolveEmptyValueJSX(false)}</td>;
  }

  if (rowField === INVESTMENT_ENTITY_NAME_ROW_FIELD_NAME) {
    return (
      <td>
        <div className="flex justify-between">
          {row[rowField]}
          <div className="flex flex-row items-center gap-xs">
            <InvestmentEntityClassesBadges
              capitalInvestmentClasses={row.investmentClasses}
            />
          </div>
        </div>
      </td>
    );
  }

  if (MONEY_ROW_FIELDS.includes(rowField) || getPath) {
    const amount = get(row, getPath) ?? row[rowField];

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <td
        className={cn(
          styles.valueColumn,
          onClick && 'relative cursor-pointer',
          styles[rowField],
        )}
        onClick={onClick}
      >
        {onClick ? (
          <>
            <CornerArrowSvg className="absolute right-[2px] top-[2.5px] z-10 text-info-055 group-hover:text-info-070" />
            <Amount amount={amount} />
          </>
        ) : (
          <Amount amount={amount} />
        )}
      </td>
    );
  }

  if (rowField === 'cashOnCashPercent') {
    return (
      <td className={styles.valueColumn}>
        {parseFloat(row.capitalInvested) > 0
          ? percentNumberFormat(Number(row[rowField]))
          : resolveEmptyValueJSX()}
      </td>
    );
  }

  return (
    <td className={styles.valueColumn}>
      {row[rowField] || resolveEmptyValueJSX(false)}
    </td>
  );
}

const SUM_ROW_FIELDS = [
  'capitalInvested',
  'capitalDistributed',
  'preferred',
  'return_of_capital',
  'excess_cash',
  'capitalBalance',
] as const satisfies readonly (
  | keyof Pick<IRowsEntity, 'capitalInvested' | 'capitalDistributed'>
  | `${TReturnInvestmentLineItemCategory.PREFERRED}`
  | `${TReturnInvestmentLineItemCategory.RETURN_OF_CAPITAL}`
  | `${TReturnInvestmentLineItemCategory.EXCESS_CASH}`
)[];

function TotalTD({
  rowField,
  isFirst,
  allRows,
  capitalDetails,
  getPath,
}: {
  rowField: string;
  isFirst: boolean;
  allRows: string[];
  capitalDetails: ICapitalDetailsEntity;
  getPath?: string;
}) {
  if (!SUM_ROW_FIELDS.includes(rowField)) {
    return <td>{isFirst ? 'Total' : ''}</td>;
  }

  let total: number | null =
    allRows?.reduce((prev, curr) => prev + parseFloat(curr) || 0, 0) || null;

  if (getPath) {
    total = sumBy(capitalDetails.rows, (item) => {
      return get(item, getPath);
    });
  }
  return (
    <td className={styles.valueColumn}>
      <Amount amount={total} />
    </td>
  );
}

interface Props {
  capitalDetails: GetApiPortfolioApiResponse['capitalDetails'];
  capitalHistory: GetApiPortfolioApiResponse['capitalHistory'];
}

export function CapitalDetailsTable({ capitalDetails }: Props) {
  const rows = useCapitalDetailRows(capitalDetails.rows);

  const { openModal } = useModal();

  const canCellClick = (rowField: string, row: IRowsEntity) =>
    (rowField === 'capitalDistributed' && row.capitalDistributed > 0) ||
    (rowField === 'capitalInvested' && row.capitalInvested > 0);

  return (
    <div className="rounded-[16px] bg-white p-m">
      <div className="mb-s flex items-center gap-m">
        <p className="dark-60 header6-bold">Capital Details</p>
        <AsOfDate
          block
          date={dayjs.unix(capitalDetails.asOfDate)}
          badgeBackgroundColor={CssVar.light10}
        />
      </div>
      {rows.length > 0 && (
        <table className={styles.table}>
          <thead>
            <tr>
              {TABLE_HEAD_COLUMNS.map((column) => (
                <Th key={column.rowField} {...column} />
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {TABLE_HEAD_COLUMNS.map(
                  ({ title, rowField, totalAmountPath, itemsPath }) => (
                    <Td
                      getPath={totalAmountPath}
                      key={title}
                      row={row}
                      rowField={rowField}
                      onClick={
                        canCellClick(rowField, row) ||
                        get(row, itemsPath)?.length > 0
                          ? () => {
                              openModal(BreakdownModal, {
                                row,
                                items: get(row, itemsPath),
                                type:
                                  rowField === 'capitalInvested'
                                    ? 'contribution'
                                    : 'distribution',
                              });
                            }
                          : null
                      }
                    />
                  ),
                )}
              </tr>
            ))}
            <tr>
              {TABLE_HEAD_COLUMNS.map(
                ({ title, rowField, totalAmountPath }, idx) => (
                  <TotalTD
                    key={title}
                    allRows={rows?.map((row) => row[rowField]) ?? []}
                    getPath={totalAmountPath}
                    capitalDetails={{ rows }}
                    rowField={rowField}
                    isFirst={idx === 0}
                  />
                ),
              )}
            </tr>
          </tbody>
        </table>
      )}
      {capitalDetails.rows.length === 0 && (
        <NoDataOverlay title="No Data Yet" />
      )}
    </div>
  );
}
