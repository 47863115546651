import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Field, InlineAlert, Input } from '@/stories';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  AdjustmentField,
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  PeriodTypeField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ColumnHeaderField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { ColumnSettingsFields } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { removeColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableSingleDateWidgetConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { upsertColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import {
  KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA,
  KpiTableSingleDateWidgetConfigColumnForm,
  transformConfigToColumnForm,
} from './column.form';

interface Props
  extends DialogProps<{
    createNew: boolean;
  }> {
  type: 'create' | 'edit';
  columnConfig?: KpiTableSingleDateWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function KpiTableSingleDateWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(
    KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA,
  );
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    >();

  const group = widget.widgetConfig.viz_config.column_groups.find(
    (cg) => cg.group_id === groupId,
  );

  const cantOverridePeriod = group?.child_can_override_period === false;

  const methods = useForm<
    KpiTableSingleDateWidgetConfigColumnForm & CreateNewForm
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...transformConfigToColumnForm({
        columnConfig,
        columnSettings,
        groupId,
        widgetConfig: widget.widgetConfig,
      }),
    },
  });
  const { control, register, handleSubmit } = methods;

  const [updateConfig] = useUpdateWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE,
  );

  const handleRemove = () => {
    updateConfig({
      config: removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
          groupId,
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(({ ...values }) => {
    updateConfig({
      config: upsertColumn(values, widget.widgetConfig, {
        groupId,
      }),
    });

    onSubmit?.({
      createNew: values.createNew,
    });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Column'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <FormulaField control={control} name="expression" required />
          <AdjustmentField control={control} name="adjustment" />
          <SectionFieldsContainer>
            <SectionField labelText="Specify Period">
              {cantOverridePeriod && (
                <InlineAlert
                  status="attention"
                  message="The period has been set for all columns in the group configuration"
                />
              )}
              <PeriodTypeField
                disabled={cantOverridePeriod}
                control={control}
                name="period_type"
              />
              <Field labelText="Period shift">
                <PeriodShiftField
                  disabled={cantOverridePeriod}
                  control={control}
                  name="period_shift"
                />
              </Field>
            </SectionField>
            <ColumnHeaderField name="header" control={control} />
          </SectionFieldsContainer>
          <TotalCalculationStrategyField
            name="total_calculation_strategy"
            control={control}
          />
          <ColumnSettingsFields
            columnsConfig={widget.widgetConfig.columns}
            control={control}
          />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
