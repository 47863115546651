import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';

import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { FormulaCard } from '@/bundles/Shared/entities/formula';
import { useGetApiSettingsReportThresholdsQuery } from '@/bundles/Shared/shared/api/settingsReportThresholdsEnhancedApi';
import { isThresholdSpecified } from '@/entities/report/thresholds/lib';
import { ThresholdGlobalValue } from '@/entities/report/thresholds/ui/ThresholdGlobalValue';
import SettingsReportThresholdsThreshold from '@/pages/settings/report/thresholds/[thresholdKind]/page';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { ResizablePanel } from '@/shared/ui/Resizable';
import { EntityItemList } from '@/widgets/report/manualVariable/settings/ui/ManualVariablesList';
import { useNavigate } from '@reach/router';
import { useMemo } from 'react';

export const ReportThresholdsPage = () => {
  const navigate = useNavigate();
  const { data, isFetching, isLoading } =
    useGetApiSettingsReportThresholdsQuery();

  const { idFromURL: thresholdKindFromURL } = useNavigateToFirst({
    items: data?.items,
    idKey: 'kind',
    matchParamKey: 'thresholdKind',
    path: ROUTES_ROOT.settings.report.thresholds.threshold.fullPath,
  });

  const selectedThreshold = useMemo(() => {
    return data?.items?.find(({ kind }) => kind === thresholdKindFromURL);
  }, [data?.items, thresholdKindFromURL]);

  const navigateToThreshold = (thresholdKind: string) => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.thresholds.threshold.fullPath, {
        pathParams: { thresholdKind },
      }),
    );
  };

  return (
    <div className="flex">
      <ResizablePanel.Group
        autoSaveId="report-thresholds-panel"
        direction="horizontal"
      >
        <ResizablePanel defaultSize={0} className="min-w-[320px] max-w-[800px]">
          <EntityItemList.Wrapper>
            <EntityItemList.Header>
              <EntityItemList.Title>Thresholds</EntityItemList.Title>
            </EntityItemList.Header>
            <NavigationCardList>
              <EntityItemList isFetching={isFetching} isLoading={isLoading}>
                {data?.items?.map((t) => (
                  // todo: replace with base card
                  <FormulaCard
                    className="!cursor-pointer"
                    label={t.title}
                    key={t.kind}
                    description={
                      isThresholdSpecified(t) ? (
                        <ThresholdGlobalValue
                          value={t.globalValue}
                          valueType={t.valueType}
                          classes={{
                            allParts: 'header5-regular',
                            value: 'text-neutral-850',
                          }}
                        />
                      ) : (
                        'Not Specified'
                      )
                    }
                    selected={t.kind === thresholdKindFromURL}
                    buttonProps={{
                      onClick: () => {
                        navigateToThreshold(t.kind);
                      },
                    }}
                  />
                ))}
              </EntityItemList>
            </NavigationCardList>
          </EntityItemList.Wrapper>
        </ResizablePanel>
        <ResizablePanel.Handle withHandle />
        <ResizablePanel>
          {selectedThreshold && (
            <SettingsReportThresholdsThreshold threshold={selectedThreshold} />
          )}
        </ResizablePanel>
      </ResizablePanel.Group>
    </div>
  );
};
