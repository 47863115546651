import type { ReportBuilderTemplateGroupDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { Button, Dropdown, DropdownItem } from '@/stories';
import { ReactComponent as LandscapeSvg } from 'fonts/sre-icons/Icons/landscape.svg';
import { ReactComponent as PortraitSvg } from 'fonts/sre-icons/Icons/portrait.svg';
import { startCase } from 'lodash-es';

export const TemplateGroupPageOrientationDropdown = ({
  isLoading,
  pageOrientation,
  onChange,
}: {
  isLoading: boolean;
  pageOrientation: ReportBuilderTemplateGroupDto['pageOrientation'];
  onChange: (
    pageOrientation: ReportBuilderTemplateGroupDto['pageOrientation'],
  ) => void;
}) => {
  return (
    <Dropdown
      disabled={isLoading}
      items={[
        <DropdownItem
          key="landscape"
          active={pageOrientation === 'landscape'}
          onClick={() => {
            onChange('landscape');
          }}
        >
          Landscape
        </DropdownItem>,
        <DropdownItem
          onClick={() => {
            onChange('portrait');
          }}
          key="portrait"
          active={pageOrientation === 'portrait'}
        >
          Portait
        </DropdownItem>,
      ]}
    >
      <Button iconName="arrowBottom" size="xs" iconPosition="right">
        {pageOrientation === 'portrait' && <PortraitSvg />}
        {pageOrientation === 'landscape' && <LandscapeSvg />}
        {startCase(pageOrientation)}
      </Button>
    </Dropdown>
  );
};
