import {
  TOTAL_BG_COLOR,
  TOTAL_TEXT_COLOR,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { ColDefBuilder } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { CssVar } from '@/shared/config/cssVar';
import { getExcelStyleNumberFormat } from '@/shared/lib/formatting/excel';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ColDef, ExcelStyle } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  DashboardDelinquencyTrackerTableWidgetDto,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  isWidgetSectionPositionWidthFullSize,
  useWidgetTableDefaultColDef,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  buildExcelStyleId,
  useObjectDashboardWidgetTableExport,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { useMemo, useRef } from 'react';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from '../../common/ui/table/WidgetTable';

export type DelinquencyTrackerWidgetState = DateWidgetState;

export function DelinquencyTrackerWidget(
  props: WidgetProps<DashboardDelinquencyTrackerTableWidgetDto> &
    WidgetStateProps<DelinquencyTrackerWidgetState> &
    WidgetContextProps<ObjectDashboardWidgetContext>,
) {
  const {
    widgetSection,
    data,
    mode,
    state,
    onStateChange,
    isError,
    isLoading,
    isFetching,
    isUninitialized,
    context,
    dashboardType,
  } = props;

  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const exportFeature = useObjectDashboardWidgetTableExport({
    gridRef,
    mode,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    context,
    state,
  });
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const rows = [
    ...(data?.rows ?? []),
    ...(data?.total ?? []).map((total) => ({ ...total, type: 'total' })),
  ];
  const rowData = rows.map((row) => ({
    ...row,
    ...Object.fromEntries(row.values.map((value) => [value.key, value.value])),
  }));

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });

  const finalExcelStyles = useMemo<ExcelStyle[]>(() => {
    return (data?.columns ?? [])
      .filter((column) => column.valueDisplayOptions != null)
      .map((column) => ({
        id: buildExcelStyleId({ id: column.key.toString() }),
        ...getExcelStyleNumberFormat(column.valueDisplayOptions),
      }))
      .concat(exportFeature.excelStyles ?? []);
  }, [exportFeature.excelStyles, data?.columns]);

  const columnDefs = useMemo<ColDef[]>(() => {
    const builder = new ColDefBuilder({
      mode,
    }).withTotalColors({
      background: TOTAL_BG_COLOR,
      color: TOTAL_TEXT_COLOR,
      borderColor: CssVar.neutral150,
    });

    return (data?.columns ?? []).map((column, index) => ({
      ...builder.build({
        column,
        columnSettings: {
          col_id: column.key.toString(),
          key: column.key.toString(),
          order: index,
          align: index === 0 ? 'left' : 'right',
          value_display_options: column.valueDisplayOptions ?? {
            type: 'string',
            precision: 0,
          },
        },
      }),
      cellClass: exportFeature.cellClass,
      cellClassRules: exportFeature.cellClassRules,
    }));
  }, [data]);

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      {mode === 'pdf' &&
        widgetSection.title &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <div className="flex flex-col">
            <DashboardWidgetCard.Header.Title>
              {widgetSection.title}
            </DashboardWidgetCard.Header.Title>
            <span className="body-semibold text-neutral-550">Five Largest</span>
          </div>
          <GrowDiv />
          <WidgetStateDate state={state} onStateChange={onStateChange} />
          <exportFeature.ExportButtonComponent />
          <widgetStateFullScreenFeature.IconButton />
        </DashboardWidgetCard.Header>
      )}
      {!isLoading && isUninitialized && <WidgetTablePlaceholder />}
      {!isLoading && !isError && !isUninitialized && (
        <WidgetTable
          domLayout={
            dashboardType === ReportDashboardType.OBJECT || mode === 'pdf'
              ? 'autoHeight'
              : 'normal'
          }
          isLastInGroup={props.isLastInGroup}
          defaultColDef={defaultColDef}
          treeData={false}
          mode={mode}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          excelStyles={finalExcelStyles}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
