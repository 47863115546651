import { LegalEntity } from '@/entities/core/legalEntity';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  IFetchFinancialsResponse,
  IFetchPeriodsPayload,
} from 'bundles/REport/types/financials';
import { API_URL } from 'lib/http';
import {
  DEFAULT_STRINGIFY_OPTIONS,
  DEFAULT_STRINGIFY_OPTIONS_WITH_EMPTY_ARRAY,
} from 'lib/stringify';
import { stringify } from 'qs';
import { IAsset } from 'types/Asset';
import { IFund } from 'types/Fund';
import { IReportTableConfig } from 'types/ReportTableConfig';
import { IFinancialsTableSegment } from 'types/ReportTableSegment';
import { GetPeriodsResponse, IFinancialsReqBody } from '../actions';

export interface IReportTableViewParameters {
  funds: (IFund & { legalEntities: LegalEntity[] })[];
  assets: (IAsset & { legalEntities: LegalEntity[] })[];
  segments: IFinancialsTableSegment[];
}

const REPORT_TABLE_CONFIG_TAG = 'ReportTableConfig';
const TABLE_VIEW_PARAMETERS_TAG = 'TableViewParameters';
const TABLE_PERIODS_TAG = 'TablePeriods';
const TABLE_DATA_TAG = 'TableData';

export const reportFinancialsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/api/`,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    REPORT_TABLE_CONFIG_TAG,
    TABLE_VIEW_PARAMETERS_TAG,
    TABLE_PERIODS_TAG,
    TABLE_DATA_TAG,
  ],
  reducerPath: 'reportFinancials',
  endpoints: (build) => ({
    getReportTableConfig: build.query<
      IReportTableConfig,
      IReportTableConfig['slug']
    >({
      providesTags: [REPORT_TABLE_CONFIG_TAG],
      query: (reportTableConfigSlug) => ({
        url: `/financials/${reportTableConfigSlug}`,
      }),
    }),

    getTableViewParameters: build.query<
      IReportTableViewParameters,
      {
        reportTableConfigId: IReportTableConfig['id'];
        objectId?: string;
        objectType?: string;
      }
    >({
      providesTags: [TABLE_VIEW_PARAMETERS_TAG],
      query: (reportTableConfigId) => {
        const params = stringify(
          { report_table_config_ids: [reportTableConfigId] },
          DEFAULT_STRINGIFY_OPTIONS,
        );
        return {
          url: `/report_table_view_parameters${params}`,
        };
      },
    }),

    getTablePeriods: build.query<GetPeriodsResponse, IFetchPeriodsPayload>({
      providesTags: [TABLE_PERIODS_TAG],
      query: ({ reportTableConfigId, ...requestParams }) => {
        const params = stringify(
          requestParams,
          DEFAULT_STRINGIFY_OPTIONS_WITH_EMPTY_ARRAY,
        );
        return {
          url: `/report/table_configs/${reportTableConfigId}/periods${params}`,
        };
      },
    }),

    getTableData: build.query<
      IFetchFinancialsResponse,
      {
        reportTableConfigId: IReportTableConfig['id'];
        reqBody: IFinancialsReqBody;
      }
    >({
      providesTags: [TABLE_DATA_TAG],
      query: ({ reportTableConfigId, reqBody }) => ({
        url: `/report/table_configs/${reportTableConfigId}/data`,
        reqBody,
        method: 'POST',
        body: JSON.stringify(reqBody),
      }),
    }),
  }),
});

export const {
  useGetReportTableConfigQuery,
  useGetTableViewParametersQuery,
  useGetTablePeriodsQuery,
  useGetTableDataQuery,
} = reportFinancialsApi;
