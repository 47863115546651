import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import { ReportBuilderTemplateTags } from '@/bundles/Shared/features/tag/filter/ui/ReportBuilderTemplateTags';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/reportBuilderTemplatesGeneratedApi';
import {
  useGetApiSettingsReportBuilderTemplatesQuery,
  usePostApiSettingsReportBuilderTemplatesMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateTagDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { CreateReportBuilderTemplateModal } from '@/entities/report/reportBuilder/ui/CreateReportBuilderTemplateModal';
import { ReportBuilderTemplateCard } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateCard';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { useModal } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TileList } from '@/shared/ui/TileList';
import { Tumbler } from '@/stories';
import { intersection, isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

const areTagsIntersect = (
  templateTags: ReportBuilderTemplateTagDto[],
  tags: ReportBuilderTemplateDto['tags'],
) => intersection(mapListToIds(templateTags), tags).length > 0;

export const ReportTemplatesPage = () => {
  const [pageParams, setPageParams] = useState<{
    query: string;
    inUse: boolean;
    tags: ReportBuilderTemplateDto['tags'];
  }>({
    query: '',
    inUse: false,
    tags: [],
  });
  const { data, isLoading } = useGetApiSettingsReportBuilderTemplatesQuery();
  const { openModal } = useModal();
  const [createTemplate, options] =
    usePostApiSettingsReportBuilderTemplatesMutation();

  const { favouriteItems, isItemFavorite } = useFavoriteItemIds(
    'reportBuilderTemplateIds',
  );

  const handleInUseChange = (inUse: boolean) => {
    setPageParams((prev) => ({
      ...prev,
      inUse,
    }));
  };
  const handleTagsChange = (tags: ReportBuilderTemplateDto['tags']) => {
    setPageParams((prev) => ({
      ...prev,
      tags,
    }));
  };

  const sortedItems = useMemo(() => {
    return (data?.items ?? []).toSorted((a, b) => {
      const isAFavorite = isItemFavorite(a.id);
      const isBFavorite = isItemFavorite(b.id);

      if (isAFavorite && isBFavorite) {
        return 0;
      }
      if (isAFavorite) return -1;
      if (isBFavorite) return 1;

      return 0;
    });
  }, [data, favouriteItems]);

  const computedItems = useMemo(() => {
    return sortedItems.filter((template) => {
      if (pageParams.inUse && template.assets.length === 0) {
        return false;
      }
      if (
        pageParams.tags.length > 0 &&
        !areTagsIntersect(template.templateTags, pageParams.tags)
      ) {
        return false;
      }
      const matchesQuery = includesInLowerCase(
        template.name,
        pageParams.query ?? '',
      );
      return matchesQuery;
    });
  }, [pageParams, sortedItems]);

  const handleCreateTemplate = async () => {
    const res = await openModal(CreateReportBuilderTemplateModal, {});

    if (res == null) return;

    createTemplate({
      body: {
        name: res.name,
      },
    });
  };

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList>
        <TileList.Header>
          <ReportBuilderTemplateTags
            value={pageParams.tags}
            onChange={handleTagsChange}
          />
          <Tumbler
            checked={pageParams.inUse}
            onChange={(e) => handleInUseChange(e.target.checked)}
          >
            Only In Use
          </Tumbler>
          <GrowDiv />
          <TileList.CreateButton
            onClick={handleCreateTemplate}
            loading={options.isLoading}
            disabled={options.isLoading}
            entityName="Template"
          />
          <PageParamsSearch placeholder="Template Name" />
        </TileList.Header>
        <TileList.ItemCounter count={computedItems.length} />
        <TileList.Items
          isLoading={isLoading}
          isListEmpty={computedItems.length === 0}
          isQueryEmpty={isEmpty(pageParams.query)}
          noDataOverlayTitle="No Templates found"
        >
          {computedItems.map((template) => (
            <ReportBuilderTemplateCard template={template} key={template.id} />
          ))}
        </TileList.Items>
      </TileList>
    </PageParamsProvider>
  );
};
