import type { ReportBuilderAssetDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Icon, Popover, PseudoLink } from '@/stories';
import { Link } from '@reach/router';
import pluralize from 'pluralize';

export const TemplateAssetsInUse = ({
  assets,
}: {
  assets: Pick<ReportBuilderAssetDto, 'id' | 'name' | 'slug'>[];
}) => (
  <Popover
    template={
      <div className="flex max-h-[200px] flex-col gap-tw-4">
        {assets.map((a) => (
          <div key={a.id} className="flex items-center gap-tw-2">
            <Icon className="text-neutral-500" iconName="asset" />
            <p className="secondary-regular text-neutral-850 ">{a.name}</p>
            <Link
              to={generateUrl(ROUTES_ROOT.assets.asset.reports.fullPath, {
                pathParams: {
                  assetSlug: a.slug,
                },
              })}
              className="flex hover:text-info-055"
            >
              <Icon iconName="externalLink" />
            </Link>
          </div>
        ))}
      </div>
    }
    maxWidth="400px"
    appendToBody
    placement="bottom-start"
    hiddenArrow
    classes={{
      spanContainer: 'd-inline-flex mnw-0',
    }}
  >
    <PseudoLink className={'text-ellipsis text-neutral-850'}>
      {assets.length} {pluralize('asset', assets.length)}
    </PseudoLink>
  </Popover>
);
