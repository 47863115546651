import { useGetApiSettingsReportBuilderTemplatesMetaQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateTagDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { mapItemToOption } from '@/shared/lib/listHelpers';
import { AnimationLoader, Button, Icon, Popover, SearchInput } from '@/stories';
import { orderBy } from 'lodash-es';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';

interface Props {
  onChange: (tagIds: ReportBuilderTemplateTagDto['id'][]) => void;
  value: ReportBuilderTemplateTagDto['id'][];
}

export function ReportBuilderTemplateTags({ value, onChange }: Props) {
  const { data, isLoading } =
    useGetApiSettingsReportBuilderTemplatesMetaQuery();
  const tagOptions = data?.tags ?? [];
  const tagOptionsMap = new Map(tagOptions.map((t) => [t.id, t]));

  const { inputProps, filteredItems: filteredTags } = useItemsFilterByText(
    tagOptions,
    'name',
  );
  const tagsOptions = orderBy(filteredTags, 'name').map((tag) =>
    mapItemToOption(tag, 'name'),
  );

  return (
    <Popover
      trigger="click"
      offset={DEFAULT_DROPDOWN_OFFSET}
      placement="bottom-start"
      hiddenArrow
      className="min-w-[164px] p-tw-4"
      maxWidth="max-content"
      template={
        isLoading ? (
          <AnimationLoader className="relative" />
        ) : (
          <div className="flex flex-col gap-tw-4">
            <div className="flex items-center gap-tw-2">
              <SearchInput
                className="w-full"
                placeholder="Search by Tag Name"
                size="s"
                {...inputProps}
              />
            </div>
            <SplittedCheckList
              onChange={(options) => {
                onChange(options.map((o) => o.value));
              }}
              items={tagsOptions}
              value={value
                .map((tagId) =>
                  tagOptionsMap.has(tagId)
                    ? mapItemToOption(tagOptionsMap.get(tagId)!, 'name')
                    : null,
                )
                .filter(Boolean)}
            />
          </div>
        )
      }
    >
      {({ triggered }) => (
        <Button
          variant="secondary"
          size="s"
          iconName="bottom"
          iconPosition="right"
          iconClasses={cn(triggered && 'text-neutral-700')}
          className={cn(triggered && 'bg-neutral-200 text-neutral-800')}
        >
          <Icon iconName="tags" />
          Tags{' '}
          {value.length > 0 && (
            <span
              className={cn(
                'secondary-regular rounded bg-neutral-150 px-tw-1 text-neutral-650',
                triggered && 'bg-neutral-300',
              )}
            >
              {value.length}
            </span>
          )}
        </Button>
      )}
    </Popover>
  );
}
