import {
  ColDef,
  ColGroupDef,
  Column,
  ExcelExportParams,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { HeaderGroupComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import {
  AUTO_COLUM_DEF_MAX_WIDTH,
  AUTO_COLUM_DEF_MIN_WIDTH,
  DEFAULT_GROUP_BG_CLASS,
  getFakeColumnDefs,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import {
  findColumnByColDef,
  getUngroupedColumns,
  isKpiTableRowAsset,
  isKpiTableRowSegment,
  isKpiTableRowTotal,
  KpiTableGroupingType,
  KpiTableWidgetDataRow,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { isGroupColDef } from 'lib/ag-grid/utils';
import { useEffect, useMemo } from 'react';
import { cloneDeep, merge } from 'lodash-es';
import { UnknownRecord } from 'type-fest/source/internal';
import { cn } from '@/shared/lib/css/cn';
import { CELL_CLASS_NAMES } from '@/bundles/Shared/components/AgGrid/Table/classNames';
import { AUTO_GROUP_COLUMN_KEY } from '@/lib/ag-grid/constants';
import { WidgetViewMode } from '@/bundles/Shared/widgets/dashboard/widgets/model';

export const PATH_DELIMITER = '/';

export const ASSET_PATH_PREFIX = 'asset-';
export const SEGMENT_PATH_PREFIX = 'segment-';
export const LEGAL_ENTITY_PATH_PREFIX = 'legal_entity-';

export const ROW_HEIGHT = 34;
const EXTENDED_ROW_HEIGHT = 52;
const PINNED_ROW_HEIGHT = 42;

export const isDividerColumn = (col: Column) =>
  col.getColId().startsWith('divider-column-');

export const useAutoGroupColDef = ({
  groupingType,
  mode,
  ref,
  colDef,
}: {
  groupingType: KpiTableGroupingType;
  mode: WidgetViewMode;
  ref?: React.RefObject<AgGridReact>;
  colDef?: ColDef;
}) => {
  const autoGroupColDef = merge(cloneDeep(WIDGET_TABLE_AUTO_GROUP_COL_DEF), {
    sortable: mode !== 'pdf',
    minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
    maxWidth: AUTO_COLUM_DEF_MAX_WIDTH,
    headerName: groupingType === 'assets' ? 'Asset' : 'Segment',
    headerComponentParams: {
      subHeaderName: groupingType === 'assets' ? 'Entity' : 'Asset',
      enableExpand: groupingType === 'segments',
    },
    cellRendererParams: (params: ICellRendererParams) => {
      const { node } = params;
      const isTotalRow = isKpiTableRowTotal(node.data);
      const isSegmentRow = isKpiTableRowSegment(node.data);
      const isAssetRow = isKpiTableRowAsset(node.data);
      const hasChildren =
        node.childrenAfterGroup && node.childrenAfterGroup.length > 0;
      const resolveIcon = () => {
        if (isAssetRow && hasChildren) {
          return undefined;
        }
        if (isAssetRow) {
          return 'asset';
        }
        return 'segments';
      };
      return {
        ...(isSegmentRow || isAssetRow
          ? {
              subLabel: params.node.data.subLabel,
              icon: resolveIcon(),
            }
          : {}),
        classes: {
          wrapper: cn(
            CELL_CLASS_NAMES.AutoGroupCell.wrapper.basic,
            isTotalRow &&
              '!bg-neutral-850 !border-neutral-700 !text-neutral-000',
          ),
          inner: cn(
            CELL_CLASS_NAMES.AutoGroupCell.inner.basic,
            colDef?.colId === AUTO_GROUP_COLUMN_KEY && '!justify-start',
          ),
        },
      };
    },
    ...colDef,
  });
  useEffect(() => {
    ref?.current?.api?.refreshHeader();
  }, [groupingType]);

  return useMemo<ColDef>(() => autoGroupColDef, [groupingType, colDef]);
};

export const zeroAndNullToTheEndComparator: ColDef['comparator'] = (
  a,
  b,
  _?,
  __?,
  isDescending = false,
  // eslint-disable-next-line max-params
) => {
  if (b === 0 || b == null) {
    return isDescending ? 1 : -1;
  }
  if (a === 0 || a == null) {
    return isDescending ? -1 : 1;
  }
  return a - b;
};

export const getDataPath: GridOptions['getDataPath'] = (row) =>
  row.path?.split(PATH_DELIMITER) as string[];

export const getRowHeight: AgGridReactProps['getRowHeight'] = (params) => {
  const row = params.node.data as KpiTableWidgetDataRow;
  if (row?.subLabel) {
    return EXTENDED_ROW_HEIGHT;
  }
  if (params.node.isRowPinned()) {
    return PINNED_ROW_HEIGHT;
  }
  return ROW_HEIGHT;
};

export const getRowId: GridOptions['getRowId'] = ({ data: row }) => {
  return row.path;
};

export const addDividersToColumnGroups = (
  columnGroups: ColGroupDef[],
): ColGroupDef[] => {
  if (columnGroups.length === 1) {
    return columnGroups;
  }

  const dividersCount = columnGroups.length - 1;
  return Array.from({
    length: columnGroups.length + dividersCount,
  }).map((_, index) => {
    if (index % 2 === 1) {
      return {
        headerName: '',
        groupId: `divider-group-${index}`,
        headerGroupComponent: HeaderGroupComponent,
        headerGroupComponentParams: {
          classes: {
            wrapper: '!bg-neutral-100 !border-neutral-100',
          },
        },
        children: [
          {
            width: 8,
            maxWidth: 8,
            colId: `divider-column-${index}`,
            headerComponentParams: {
              classes: {
                wrapper: '!bg-neutral-100 !border-neutral-100',
              },
            },
            cellClass: (params) => {
              if (params.node.data && isKpiTableRowTotal(params.node.data)) {
                return '!bg-neutral-700';
              }
              return '!bg-neutral-100';
            },
            cellRenderer: () => <></>,
          },
        ],
      };
    }
    return columnGroups[Math.floor(index / 2)];
  });
};

export const buildWidgetTableColumnDefs = <
  T extends {
    label: string;
    key: number;
  },
>({
  columns,
  columnToColDefMapper,
  rows,
  agGridConfig,
}: {
  columns: T[];
  columnToColDefMapper: (c: T, cg?: ColGroupDef) => ColDef;
  rows: UnknownRecord[];
  agGridConfig: Partial<GridOptions>;
}): (ColDef | ColGroupDef)[] => {
  if (columns.length === 0 || rows.length === 0) {
    return getFakeColumnDefs(
      columns.map((c) => ({
        headerName: c.label,
        cellRendererParams: {
          classes: {
            wrapper: DEFAULT_GROUP_BG_CLASS,
          },
        },
      })),
      rows.length,
    );
  }
  const { columnDefs } = agGridConfig;
  if (columnDefs?.some(isGroupColDef)) {
    const colGroupDefs = columnDefs?.filter(isGroupColDef);
    const mappedColGroupDefs = colGroupDefs.map((cg: ColGroupDef) => {
      const children = cg.children.map((c: ColDef) => {
        const column = findColumnByColDef(columns, c);

        return {
          ...(column && columnToColDefMapper(column, cg)),
          ...c,
        };
      });
      return {
        ...cg,
        groupId: cg.groupId && `group-${cg.groupId?.toString()}`,
        children,
      };
    });

    return [
      ...getUngroupedColumns(colGroupDefs, columns).map((c) => ({
        ...columnToColDefMapper(c),
        ...columnDefs.find((cd: ColDef) => cd.colId === c.key.toString()),
      })),
      ...addDividersToColumnGroups(mappedColGroupDefs),
    ];
  }
  return [...columns.map(columnToColDefMapper)];
};

export const KPI_TABLE_WIDGET_EXPORT_PARAMS = {
  shouldRowBeSkipped: (rowParams) => {
    if (!rowParams.node.parent || rowParams.node.parent.level === -1) {
      return false;
    }
    return !rowParams.node.parent.expanded;
  },
} as const satisfies ExcelExportParams;
