/* eslint-disable check-file/no-index */
import { useResetFinancialSliceObjectsOnUnmount } from '@/bundles/REport/components/financials/hooks/useResetFinancialSliceObjectsOnUnmount';
import {
  selectReportTableConfig,
  selectSelectedObjects,
} from '@/bundles/REport/reducers/financialsSlice';
import { useFinancialsUrlSearchParamsObjectTypeAndIdArr } from '@/features/report/financials/selectedObjectIdsInSearchParams/lib/useFinancialsUrlSearchParamsObjectTypeAndIdArr';
import { cn } from '@/shared/lib/css/cn';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';
import { RouteComponentProps } from '@reach/router';
import { useGetTableViewParametersQuery } from 'bundles/REport/api/financialsApi';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { FC } from 'react';
import { AnimationLoader } from 'stories/index';
import { CardList } from './CardList';
import { SELECT_OBJECTS_HINT_JSX } from './dumbJSX';
import { FinancialsDashboard } from './FinancialsDashboard';
import { useReportFinancialsScreenReportTableConfig } from './hooks';

export const FinancialsPage = () => {
  const financialsSlice = useAppSelector((state) => state.financials);
  const { dashboardExpanded, asOfDate } = financialsSlice;
  const reportTableConfig = useAppSelector(selectReportTableConfig);
  const selectedObjects = useAppSelector(selectSelectedObjects);

  useResetAllModalsOnUnmount();
  useResetFinancialSliceObjectsOnUnmount();

  const financialsUrlSearchParamsObjectTypeAndIdArr =
    useFinancialsUrlSearchParamsObjectTypeAndIdArr();

  const { isLoading: isObjectsLoading } = useGetTableViewParametersQuery({
    reportTableConfigId: reportTableConfig.id,
    objectTypeAndIdArr: financialsUrlSearchParamsObjectTypeAndIdArr,
  });

  return (
    <ListNavLayout
      className={cn(
        dashboardExpanded && 'flex',
        'grid-cols-[320px_minmax(auto,1fr)] 2xl:grid-cols-[320px_minmax(auto,1fr)]',
      )}
    >
      <ListNavLayout.Navigation
        className={cn(
          'relative',
          dashboardExpanded && selectedObjects.length > 0 && 'hidden',
        )}
      >
        <ListNavLayout.ReportFinancialsNavigationHeaderGroup>
          <ListNavLayout.NavigationHeader
            subtitle="REport"
            title="Financials"
          />
          <div className="flex flex-col items-end gap-tw-2">
            {asOfDate && (
              <div className="flex flex-col items-end gap-tw-1.5">
                <p className="inline-regular text-neutral-500">As of Date:</p>
                <p className="inline-semibold text-neutral-900">
                  {formatUnixDate(asOfDate, 'MMMM DD, YYYY')}
                </p>
              </div>
            )}
          </div>
        </ListNavLayout.ReportFinancialsNavigationHeaderGroup>
        <CardList isLoading={isObjectsLoading} />
      </ListNavLayout.Navigation>

      {selectedObjects.length > 0 && (
        <FinancialsDashboard isObjectsLoading={isObjectsLoading} />
      )}
      {selectedObjects.length === 0 &&
        !isObjectsLoading &&
        SELECT_OBJECTS_HINT_JSX}
    </ListNavLayout>
  );
};

export const ReportFinancials: FC<RouteComponentProps> = () => {
  const { data: reportTableConfig } =
    useReportFinancialsScreenReportTableConfig();

  if (reportTableConfig === undefined) return <AnimationLoader />;

  return <FinancialsPage />;
};
