import { financialsSliceActions } from '@/bundles/REport/reducers/financialsSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useEffect } from 'react';

export const useResetFinancialSliceObjectsOnUnmount = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(financialsSliceActions.resetSelectedState());
    },
    [],
  );
};
