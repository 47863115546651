export const DASH_SIGN = '-';
export const joinWith = (arr: string[], delim: string): string =>
  arr.join(delim);

export const splitWith = (iterable: string, delim: string): string[] =>
  iterable.split(delim);

export const joinWithDash = (arr: string[]): string => joinWith(arr, DASH_SIGN);

export const splitWithDash = (iterable: string): string[] =>
  splitWith(iterable, DASH_SIGN);
