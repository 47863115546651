import { useHoverSaveArea } from '@/bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { CreateNewDashboardButton } from '@/bundles/Shared/components/LeftSidebar/menuItems/ReportPortfolioMenuItem';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery from '@/shared/lib/hooks/useMediaQuery';
import {
  allSubPathMatches,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch } from '@reach/router';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import SecondaryMenuItem from 'bundles/Shared/components/LeftSidebar/items/SecondaryMenuItem';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { LeftSidebarMenu } from 'bundles/Shared/shared/ui';
import { useRef } from 'react';
import { Icon } from 'stories';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';

const NAVIGATION_ITEM_NAME = 'Asset';

export function ReportDashboardsMenuItem() {
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const isMobile = useMediaQuery('(pointer:coarse)');
  const { triggered, ...handlers } = usePopoverTriggered();
  const { genericData } = useGenericData();
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea({
    popoverPlacement: 'start',
  });
  const isMatchWithDashboard = useMatch(ROUTES_ROOT.report.dashboards.fullPath);
  const publishedItems = genericData!.mainNavigation.objectDashboards.filter(
    (dashboard) => dashboard.status === 'published',
  );

  const operationalMobileMenu = publishedItems.map((item) => (
    <SecondaryMenuItem
      key={item.slug}
      label={item.label}
      path={generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
        pathParams: {
          dashboardId: item.slug,
        },
      })}
    />
  ));

  if (publishedItems.length === 0) return null;

  if (isMobile) {
    return (
      <>
        <div className="flex w-full items-center px-tw-2 py-tw-1">
          <div className="left-sidebar__second-menu-label inline-regular ml-tw-2">
            {NAVIGATION_ITEM_NAME}
          </div>
        </div>
        {operationalMobileMenu}
      </>
    );
  }

  return (
    <>
      <SecondaryMenuItem
        ref={menuItemRef}
        className={cn(
          triggered && 'left-sidebar__second-menu-item_hovered',
          hoverClasses,
        )}
        linkProps={{
          onMouseMove,
        }}
        label={NAVIGATION_ITEM_NAME}
        rightIcon={<Icon iconName="arrowRight" className="pr-tw-3" />}
        onLinkClick={(e) => {
          e.preventDefault();
        }}
        isActive={() => isMatchWithDashboard!}
        path=""
      />
      <MenuItemPopover
        {...handlers}
        reference={menuItemRef}
        offset={DEFAULT_DROPDOWN_OFFSET}
        template={
          <LeftSidebarMenu
            title="Dashboards"
            panel={<CreateNewDashboardButton />}
          >
            {publishedItems.map((dashboard) => (
              <LeftSidebarMenu.Item
                to={generateUrl(ROUTES_ROOT.report.dashboards.fullPath, {
                  pathParams: {
                    dashboardId: dashboard.slug,
                  },
                })}
                key={dashboard.id}
              >
                <DashboardIcon iconName="objectLevelDashboard" />
                {dashboard.label}
              </LeftSidebarMenu.Item>
            ))}
          </LeftSidebarMenu>
        }
        placement="right-start"
        hideOnExpandedAreaClick
        maxWidth="fit-content"
      />
    </>
  );
}
