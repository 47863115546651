import {
  DashboardWidgetSectionDto,
  useGetApiSettingsReportDashboardsQuery,
  useReportEagleEyeDashboardSettingsById,
} from 'bundles/Shared/entities/dashboard';
import { orderBy } from 'lodash-es';
import { useReportSettingsDashboardScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';

export const NO_BOARD_ID = 'board-none';

export const useReportSettingsDashboardById = (dashboardId: string) => {
  const { data, isLoading } = useGetApiSettingsReportDashboardsQuery();
  return {
    data: data?.find((d) => d.id === dashboardId || d.slug === dashboardId),
    isLoading,
  };
};

export const useGetApiSettingsReportDashboardsByIdWithScreenParams = () => {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  return useReportEagleEyeDashboardSettingsById({
    dashboardId,
  });
};

export const orderDashboardSections = <
  S extends Pick<DashboardWidgetSectionDto, 'position'>,
>(
  sections: S[],
) => {
  return orderBy(
    sections,
    [(section) => section.position.y, (section) => section.position.x],
    ['asc', 'asc'],
  );
};
