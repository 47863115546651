import { Button, Dropdown, DropdownItem, Icon } from '@/stories';

export const TemplateGroupHeaderDropdown = ({
  isLoading,
  displayHeader,
  onChange,
}: {
  isLoading: boolean;
  displayHeader: boolean | null;
  onChange: (displayHeader: boolean | null) => void;
}) => {
  return (
    <Dropdown
      disabled={isLoading}
      items={[
        <DropdownItem
          tooltipProps={{
            mainText: 'Depends on the default configuration',
          }}
          key="default"
          active={displayHeader == null}
          onClick={() => {
            onChange(null);
          }}
        >
          Default
        </DropdownItem>,
        <DropdownItem
          tooltipProps={{
            mainText: 'Modifies the default configuration',
            classes: {
              spanContainer: 'block',
            },
          }}
          key="show"
          active={displayHeader === true}
          onClick={() => {
            onChange(true);
          }}
        >
          Show
        </DropdownItem>,
        <DropdownItem
          onClick={() => {
            onChange(false);
          }}
          tooltipProps={{
            mainText: 'Modifies the default configuration',
            classes: {
              spanContainer: 'block',
            },
          }}
          key="hide"
          active={displayHeader === false}
        >
          Hide
        </DropdownItem>,
      ]}
    >
      <Button iconName="arrowBottom" size="xs" iconPosition="right">
        <Icon iconName="textHeader" />
        {displayHeader == null && 'Default'}
        {displayHeader && 'Show'}
        {displayHeader === false && 'Hide'}
      </Button>
    </Dropdown>
  );
};
