import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCustomerBridgeSyncs: build.query<
      GetApiCustomerBridgeSyncsApiResponse,
      GetApiCustomerBridgeSyncsApiArg
    >({
      query: (queryArg) => ({ url: `/api/customer_bridge/syncs`, params: queryArg }),
    }),
    postApiCustomerBridgeSyncs: build.mutation<
      PostApiCustomerBridgeSyncsApiResponse,
      PostApiCustomerBridgeSyncsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/customer_bridge/syncs`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as syncsGeneratedApi };
export type GetApiCustomerBridgeSyncsApiResponse =
  /** status 200 successful */ SyncDto[];
export type GetApiCustomerBridgeSyncsApiArg = {
  kind: string;
};
export type PostApiCustomerBridgeSyncsApiResponse = unknown;
export type PostApiCustomerBridgeSyncsApiArg = {
  body: {
    kind:
      | 'report_financial_category'
      | 'report_system_manual_variables'
      | 'reckoner_system_formulas'
      | 'recap_pages';
  };
};
export type SyncInitiatorDto = {
  id: string;
  fullName: string;
};
export type SyncDto = {
  id: string;
  memos: string[];
  status: 'in_progress' | 'completed' | 'failed';
  initiator: SyncInitiatorDto;
  createdAt: number;
  updatedAt: number;
};
