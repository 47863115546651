import type { _ObjectableType } from '@/bundles/REport/reducers/financialsSlice';
import type { ObjectTypeAndId } from '@/bundles/REport/reducers/model';
import { stringifyObjTypeAndId } from '@/bundles/REport/reducers/utils';
import { OBJECTABLE_TYPES } from '@/entities/report/financials/config';
import { useLocation } from '@reach/router';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';

export const useFinancialsUrlSearchParamsObjectTypeAndIdArr = () => {
  const { search: locationSearch } = useLocation();

  const financialsUrlSearchParamsObjectTypeAndIdArr = useMemo(() => {
    const searchParams = new URLSearchParams(locationSearch);
    const searchParamsEntries = [...searchParams.entries()];
    const objectTypeAndIdArr: ObjectTypeAndId[] = [];

    searchParamsEntries
      .filter(([key, value]) => {
        return (
          OBJECTABLE_TYPES.includes(key) && Number.isSafeInteger(Number(value))
        );
      })
      .forEach(([objectTypeFromSearchParam, idFromSearchParam]) => {
        objectTypeAndIdArr.push(
          stringifyObjTypeAndId({
            _type: objectTypeFromSearchParam as _ObjectableType,
            id: Number(idFromSearchParam),
          }),
        );
      });

    return uniq(objectTypeAndIdArr);
  }, []);

  return financialsUrlSearchParamsObjectTypeAndIdArr;
};
